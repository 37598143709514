import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";

export const CompoundGrowthCalculator = () => {
  const [initialValue, setInitialValue] = useState("");
  const [growthRate, setGrowthRate] = useState("");
  const [years, setYears] = useState("");
  const [result, setResult] = useState<number | null>(null);
  const { toast } = useToast();

  const calculateGrowth = () => {
    const initial = parseFloat(initialValue);
    const rate = parseFloat(growthRate) / 100;
    const time = parseFloat(years);

    if (isNaN(initial) || isNaN(rate) || isNaN(time)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    const finalValue = initial * Math.pow(1 + rate, time);
    setResult(finalValue);
  };

  return (
    <Card className="p-6">
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">Compound Growth Calculator</h3>
        <p className="text-gray-600">
          Project your business growth over time based on a compound annual growth rate (CAGR).
        </p>
      </div>

      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="initial-value">Initial Value ($)</Label>
          <Input
            id="initial-value"
            type="number"
            placeholder="100000"
            value={initialValue}
            onChange={(e) => setInitialValue(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="growth-rate">Annual Growth Rate (%)</Label>
          <Input
            id="growth-rate"
            type="number"
            placeholder="10"
            value={growthRate}
            onChange={(e) => setGrowthRate(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="years">Number of Years</Label>
          <Input
            id="years"
            type="number"
            placeholder="5"
            value={years}
            onChange={(e) => setYears(e.target.value)}
          />
        </div>

        <Button className="w-full" onClick={calculateGrowth}>
          Calculate Growth
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg">
            <p className="text-center text-lg font-semibold">
              Future Value:
              <span className="block text-2xl text-primary">
                ${result.toLocaleString(undefined, { maximumFractionDigits: 2 })}
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};