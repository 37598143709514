
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { TrendingUp, GraduationCap } from "lucide-react";

const AbundancePrinciplesTab = () => {
  return (
    <Card className="p-6">
      <h3 className="text-xl font-bold mb-4">Principles of Abundance</h3>
      <div className="space-y-6">
        <div>
          <h4 className="text-lg font-semibold mb-2">Cultivating Abundance</h4>
          <p className="text-gray-700 mb-3">
            Develop an abundance mindset through:
          </p>
          <ul className="list-disc pl-6 space-y-2 text-gray-700">
            <li>Focusing on opportunities rather than limitations</li>
            <li>Celebrating others' financial success</li>
            <li>Practicing generosity and giving</li>
            <li>Seeing money as a renewable resource</li>
          </ul>
        </div>

        <div>
          <h4 className="text-lg font-semibold mb-2">Value Creation</h4>
          <p className="text-gray-700 mb-3">
            Focus on creating value for others:
          </p>
          <ul className="list-disc pl-6 space-y-2 text-gray-700">
            <li>Identify ways to solve problems for others</li>
            <li>Develop valuable skills and knowledge</li>
            <li>Build meaningful relationships</li>
            <li>Share your expertise generously</li>
          </ul>
        </div>

        <div className="bg-green-50 p-4 rounded-lg">
          <h4 className="text-lg font-semibold mb-2">Growth Opportunities</h4>
          <p className="text-gray-700 mb-3">
            Explore these paths to increase your abundance:
          </p>
          <div className="grid gap-4 md:grid-cols-2">
            <Link to="/stock-market">
              <Button variant="outline" className="w-full">
                <TrendingUp className="w-4 h-4 mr-2" />
                Investment Strategies
              </Button>
            </Link>
            <Link to="/side-hustles">
              <Button variant="outline" className="w-full">
                <GraduationCap className="w-4 h-4 mr-2" />
                Side Hustle Ideas
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default AbundancePrinciplesTab;
