import { Card } from "@/components/ui/card";
import { Star } from "lucide-react";

interface Book {
  image: string;
  link: string;
  title: string;
  description: string;
  rating: number;
}

interface RecommendedBooksProps {
  books: Book[];
}

const RecommendedBooks = ({ books }: RecommendedBooksProps) => {
  if (books.length === 0) return null;

  return (
    <div className="mt-12">
      <h2 className="text-3xl font-bold mb-6 gradient-text">Recommended Books</h2>
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {books.map((book, index) => (
          <Card key={index} className="flex flex-col h-full hover-scale">
            <a href={book.link} target="_blank" rel="noopener noreferrer" className="p-6 flex flex-col h-full">
              <div className="mb-4 aspect-[3/4] relative">
                <img
                  src={book.image}
                  alt={book.title}
                  className="absolute inset-0 w-full h-full object-cover rounded-md"
                />
              </div>
              <h3 className="text-xl font-semibold mb-2">{book.title}</h3>
              <p className="text-gray-600 mb-4 flex-grow">{book.description}</p>
              <div className="flex items-center">
                {[...Array(Math.floor(book.rating))].map((_, i) => (
                  <Star key={i} className="w-4 h-4 fill-yellow-400 text-yellow-400" />
                ))}
                {book.rating % 1 !== 0 && (
                  <Star className="w-4 h-4 fill-yellow-400 text-yellow-400" style={{ clipPath: 'inset(0 50% 0 0)' }} />
                )}
              </div>
            </a>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default RecommendedBooks;