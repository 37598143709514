import { useState } from "react";
import { useIsMobile } from "@/hooks/use-mobile";
import { CalculatorTabs } from "./calculators/CalculatorTabs";
import { CalculatorContent } from "./calculators/CalculatorContent";
import { Card } from "@/components/ui/card";
import {
  Calculator, DollarSign, PiggyBank, TrendingUp, Users,
  Building, ShoppingCart, Clock, Scale, CreditCard,
  Package, Truck, BarChart
} from "lucide-react";

// Import all calculator components
import { BreakEvenCalculator } from "./calculators/BreakEvenCalculator";
import { ProfitMarginCalculator } from "./calculators/ProfitMarginCalculator";
import { StartupCostsCalculator } from "./calculators/StartupCostsCalculator";
import { CashFlowCalculator } from "./calculators/CashFlowCalculator";
import { LoanRepaymentCalculator } from "./calculators/LoanRepaymentCalculator";
import { RoiCalculator } from "./calculators/RoiCalculator";
import { FreelanceRateCalculator } from "./calculators/FreelanceRateCalculator";
import { SubscriptionRevenueCalculator } from "./calculators/SubscriptionRevenueCalculator";
import { MarkupCalculator } from "./calculators/MarkupCalculator";
import { CustomerLifetimeValueCalculator } from "./calculators/CustomerLifetimeValueCalculator";
import { CustomerAcquisitionCostCalculator } from "./calculators/CustomerAcquisitionCostCalculator";
import { AdSpendRoiCalculator } from "./calculators/AdSpendRoiCalculator";
import { InventoryTurnoverCalculator } from "./calculators/InventoryTurnoverCalculator";
import { EmployeeCostCalculator } from "./calculators/EmployeeCostCalculator";
import { BusinessTaxCalculator } from "./calculators/BusinessTaxCalculator";
import { SalesTaxCalculator } from "./calculators/SalesTaxCalculator";
import { CompoundGrowthCalculator } from "./calculators/CompoundGrowthCalculator";
import { BusinessValuationCalculator } from "./calculators/BusinessValuationCalculator";
import { ShippingCostCalculator } from "./calculators/ShippingCostCalculator";
import { ECommerceRevenueCalculator } from "./calculators/ECommerceRevenueCalculator";
import { TimeValueCalculator } from "./calculators/TimeValueCalculator";
import { FinancialProjectionsCalculator } from "./calculators/FinancialProjectionsCalculator";

const calculators = [
  { id: "break-even", label: "Break-Even", icon: Calculator, component: BreakEvenCalculator },
  { id: "profit-margin", label: "Profit Margin", icon: DollarSign, component: ProfitMarginCalculator },
  { id: "startup-costs", label: "Startup Costs", icon: Building, component: StartupCostsCalculator },
  { id: "cash-flow", label: "Cash Flow", icon: PiggyBank, component: CashFlowCalculator },
  { id: "loan-repayment", label: "Loan Repayment", icon: CreditCard, component: LoanRepaymentCalculator },
  { id: "roi", label: "ROI", icon: TrendingUp, component: RoiCalculator },
  { id: "freelance-rate", label: "Freelance Rate", icon: Clock, component: FreelanceRateCalculator },
  { id: "subscription", label: "Subscription Revenue", icon: Users, component: SubscriptionRevenueCalculator },
  { id: "markup", label: "Markup", icon: Scale, component: MarkupCalculator },
  { id: "clv", label: "Customer Lifetime Value", icon: Users, component: CustomerLifetimeValueCalculator },
  { id: "cac", label: "Customer Acquisition Cost", icon: Users, component: CustomerAcquisitionCostCalculator },
  { id: "ad-roi", label: "Ad Spend ROI", icon: BarChart, component: AdSpendRoiCalculator },
  { id: "inventory", label: "Inventory Turnover", icon: Package, component: InventoryTurnoverCalculator },
  { id: "employee-cost", label: "Employee Cost", icon: Users, component: EmployeeCostCalculator },
  { id: "business-tax", label: "Business Tax", icon: DollarSign, component: BusinessTaxCalculator },
  { id: "sales-tax", label: "Sales Tax", icon: ShoppingCart, component: SalesTaxCalculator },
  { id: "growth", label: "Compound Growth", icon: TrendingUp, component: CompoundGrowthCalculator },
  { id: "valuation", label: "Business Valuation", icon: Building, component: BusinessValuationCalculator },
  { id: "shipping", label: "Shipping Cost", icon: Truck, component: ShippingCostCalculator },
  { id: "ecommerce", label: "E-Commerce Revenue", icon: ShoppingCart, component: ECommerceRevenueCalculator },
  { id: "time-value", label: "Time Value", icon: Clock, component: TimeValueCalculator },
  { id: "projections", label: "Financial Projections", icon: BarChart, component: FinancialProjectionsCalculator },
];

export const BusinessCalculatorsSection = () => {
  const [activeCalculator, setActiveCalculator] = useState(calculators[0].id);
  const isMobile = useIsMobile();

  const handleCalculatorChange = (calculatorId: string) => {
    setActiveCalculator(calculatorId);
    console.log("Selected calculator:", calculatorId);
  };

  return (
    <section className="mt-8 bg-gray-50/50 p-6 rounded-lg">
      <h2 className="text-2xl font-semibold mb-6">Business Calculators</h2>
      <Card className="p-6">
        <div className="flex flex-col space-y-8">
          <div className="w-full overflow-y-auto rounded-lg border bg-background p-4">
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-2">
              {calculators.map((calc) => {
                const Icon = calc.icon;
                return (
                  <button
                    key={calc.id}
                    onClick={() => handleCalculatorChange(calc.id)}
                    className={`flex items-center gap-2 p-2 rounded-md transition-all text-sm
                      ${
                        activeCalculator === calc.id
                          ? "bg-primary text-primary-foreground"
                          : "hover:bg-accent hover:text-accent-foreground"
                      }
                    `}
                  >
                    <Icon className="h-4 w-4" />
                    <span className="truncate">{calc.label}</span>
                  </button>
                );
              })}
            </div>
          </div>
          <div className="w-full pt-6 border-t">
            <CalculatorContent
              calculators={calculators}
              activeCalculator={activeCalculator}
              isMobile={isMobile}
            />
          </div>
        </div>
      </Card>
    </section>
  );
};