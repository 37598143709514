import { StockMarketCalculators } from "./StockMarketCalculators";

export const CalculatorSection = () => {
  return (
    <section>
      <h2 className="text-2xl font-semibold mb-4">Stock Market Calculators</h2>
      <div className="grid gap-6">
        <StockMarketCalculators />
      </div>
    </section>
  );
};