import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { DollarSign } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";

export const RentalCalculator = () => {
  const [purchasePrice, setPurchasePrice] = useState("");
  const [monthlyRent, setMonthlyRent] = useState("");
  const [expenses, setExpenses] = useState({
    mortgage: "",
    propertyTax: "",
    insurance: "",
    utilities: "",
    maintenance: "",
    propertyManagement: "",
    vacancy: "",
    hoa: "",
  });
  const [result, setResult] = useState<{ cashFlow: number; roi: number } | null>(null);
  const { toast } = useToast();

  const calculateRentalMetrics = () => {
    const price = parseFloat(purchasePrice);
    const rent = parseFloat(monthlyRent);
    const totalExpenses = Object.values(expenses).reduce((acc, curr) => acc + (parseFloat(curr) || 0), 0);

    if (isNaN(price) || isNaN(rent) || isNaN(totalExpenses)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    const monthlyCashFlow = rent - totalExpenses;
    const annualCashFlow = monthlyCashFlow * 12;
    const roi = (annualCashFlow / price) * 100;

    setResult({ cashFlow: monthlyCashFlow, roi });
    console.log("Calculated rental metrics:", { monthlyCashFlow, roi, totalExpenses });
  };

  const handleExpenseChange = (key: keyof typeof expenses) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setExpenses(prev => ({ ...prev, [key]: e.target.value }));
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="purchase-price">Purchase Price</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="purchase-price"
              type="number"
              placeholder="300000"
              className="pl-10"
              value={purchasePrice}
              onChange={(e) => setPurchasePrice(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="monthly-rent">Expected Monthly Rent</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="monthly-rent"
              type="number"
              placeholder="2000"
              className="pl-10"
              value={monthlyRent}
              onChange={(e) => setMonthlyRent(e.target.value)}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {Object.entries({
            mortgage: "Monthly Mortgage Payment",
            propertyTax: "Property Tax",
            insurance: "Insurance",
            utilities: "Utilities",
            maintenance: "Maintenance",
            propertyManagement: "Property Management",
            vacancy: "Vacancy Reserve",
            hoa: "HOA Fees"
          }).map(([key, label]) => (
            <div key={key} className="space-y-2">
              <Label htmlFor={key}>{label}</Label>
              <div className="relative">
                <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
                <Input
                  id={key}
                  type="number"
                  placeholder="0"
                  className="pl-10"
                  value={expenses[key as keyof typeof expenses]}
                  onChange={handleExpenseChange(key as keyof typeof expenses)}
                />
              </div>
            </div>
          ))}
        </div>

        <Button 
          className="w-full"
          onClick={calculateRentalMetrics}
        >
          Calculate Rental Metrics
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg space-y-2">
            <p className="text-center text-lg">
              Monthly Cash Flow:
              <span className="block text-2xl font-semibold text-primary">
                ${result.cashFlow.toLocaleString(undefined, { maximumFractionDigits: 2 })}
              </span>
            </p>
            <p className="text-center text-lg">
              Return on Investment:
              <span className="block text-2xl font-semibold text-primary">
                {result.roi.toFixed(2)}%
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};