import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";

export const SharpeRatioCalculator = () => {
  const [returns, setReturns] = useState("");
  const [riskFreeRate, setRiskFreeRate] = useState("");
  const [standardDeviation, setStandardDeviation] = useState("");
  const [result, setResult] = useState<number | null>(null);
  const { toast } = useToast();

  const calculateSharpeRatio = () => {
    try {
      const portfolioReturn = parseFloat(returns);
      const riskFree = parseFloat(riskFreeRate);
      const stdDev = parseFloat(standardDeviation);

      if (isNaN(portfolioReturn) || isNaN(riskFree) || isNaN(stdDev)) {
        toast({
          title: "Invalid Input",
          description: "Please enter valid numbers",
          variant: "destructive",
        });
        return;
      }

      if (stdDev <= 0) {
        toast({
          title: "Invalid Standard Deviation",
          description: "Standard deviation must be greater than zero",
          variant: "destructive",
        });
        return;
      }

      const sharpeRatio = (portfolioReturn - riskFree) / stdDev;
      setResult(sharpeRatio);
      console.log("Calculated Sharpe Ratio:", sharpeRatio);

    } catch (error) {
      toast({
        title: "Calculation Error",
        description: "An error occurred during calculation",
        variant: "destructive",
      });
    }
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="returns">Portfolio Returns (%)</Label>
          <Input
            id="returns"
            type="number"
            placeholder="10"
            value={returns}
            onChange={(e) => setReturns(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="risk-free-rate">Risk-Free Rate (%)</Label>
          <Input
            id="risk-free-rate"
            type="number"
            placeholder="2"
            value={riskFreeRate}
            onChange={(e) => setRiskFreeRate(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="std-dev">Standard Deviation (%)</Label>
          <Input
            id="std-dev"
            type="number"
            placeholder="15"
            value={standardDeviation}
            onChange={(e) => setStandardDeviation(e.target.value)}
          />
        </div>

        <Button 
          className="w-full"
          onClick={calculateSharpeRatio}
        >
          Calculate Sharpe Ratio
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg">
            <p className="text-center text-lg font-semibold">
              Sharpe Ratio:
              <span className="block text-2xl text-primary">
                {result.toFixed(2)}
              </span>
              <span className="block text-sm text-gray-600 mt-2">
                {result > 1 ? "Good risk-adjusted returns" :
                 result > 0 ? "Positive but could be better" :
                 "Poor risk-adjusted returns"}
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};