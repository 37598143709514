import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";

export const MarkupCalculator = () => {
  const [cost, setCost] = useState("");
  const [desiredProfit, setDesiredProfit] = useState("");
  const [result, setResult] = useState<{ sellingPrice: number; markup: number } | null>(null);
  const { toast } = useToast();

  const calculateMarkup = () => {
    const itemCost = parseFloat(cost);
    const profitMargin = parseFloat(desiredProfit) / 100;

    if (isNaN(itemCost) || isNaN(profitMargin)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    const sellingPrice = itemCost / (1 - profitMargin);
    const markup = ((sellingPrice - itemCost) / itemCost) * 100;

    setResult({
      sellingPrice,
      markup
    });
  };

  return (
    <Card className="p-6">
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">Markup Calculator</h3>
        <p className="text-gray-600">
          Calculate the selling price and markup percentage needed to achieve your
          desired profit margin.
        </p>
      </div>

      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="cost">Cost per Item ($)</Label>
          <Input
            id="cost"
            type="number"
            placeholder="100"
            value={cost}
            onChange={(e) => setCost(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="desired-profit">Desired Profit Margin (%)</Label>
          <Input
            id="desired-profit"
            type="number"
            placeholder="30"
            value={desiredProfit}
            onChange={(e) => setDesiredProfit(e.target.value)}
          />
        </div>

        <Button 
          className="w-full"
          onClick={calculateMarkup}
        >
          Calculate Markup
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg space-y-2">
            <p className="text-center font-semibold">
              Recommended Selling Price:
              <span className="block text-2xl text-primary">
                ${result.sellingPrice.toFixed(2)}
              </span>
            </p>
            <p className="text-center font-semibold">
              Required Markup:
              <span className="block text-2xl text-primary">
                {result.markup.toFixed(1)}%
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};