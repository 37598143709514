import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from "recharts";
import { CalculatorProps } from "./types";

interface Asset {
  name: string;
  value: number;
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

export const PortfolioDiversificationCalculator = ({ onCalculate }: CalculatorProps) => {
  const [assets, setAssets] = useState<Asset[]>([
    { name: "Stocks", value: 0 },
    { name: "Bonds", value: 0 },
    { name: "Real Estate", value: 0 },
    { name: "Cash", value: 0 },
    { name: "Other", value: 0 },
  ]);

  const handleValueChange = (index: number, value: string) => {
    const newAssets = [...assets];
    newAssets[index].value = parseFloat(value) || 0;
    setAssets(newAssets);
    
    const total = newAssets.reduce((sum, asset) => sum + asset.value, 0);
    if (onCalculate) onCalculate(total);
  };

  const calculatePercentages = () => {
    const total = assets.reduce((sum, asset) => sum + asset.value, 0);
    return assets.map(asset => ({
      ...asset,
      percentage: total > 0 ? ((asset.value / total) * 100).toFixed(1) : "0",
    }));
  };

  const chartData = assets.filter(asset => asset.value > 0);

  return (
    <Card className="p-6">
      <div className="space-y-6">
        <div className="grid gap-4">
          {assets.map((asset, index) => (
            <div key={asset.name} className="space-y-2">
              <Label htmlFor={`asset-${index}`}>{asset.name} Value ($)</Label>
              <Input
                id={`asset-${index}`}
                type="number"
                min="0"
                value={asset.value || ""}
                onChange={(e) => handleValueChange(index, e.target.value)}
                placeholder={`Enter ${asset.name.toLowerCase()} value`}
              />
            </div>
          ))}
        </div>

        {chartData.length > 0 && (
          <div className="mt-6">
            <h3 className="text-lg font-semibold mb-4">Portfolio Allocation</h3>
            <div className="h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={chartData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    label={({ name, percentage }) => `${name}: ${percentage}%`}
                  >
                    {chartData.map((_, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
        )}

        <div className="space-y-2">
          <h3 className="text-lg font-semibold">Portfolio Breakdown</h3>
          {calculatePercentages().map((asset) => (
            <div key={asset.name} className="flex justify-between">
              <span>{asset.name}</span>
              <span>{asset.percentage}%</span>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};