import { Tabs, TabsContent } from "@/components/ui/tabs";
import { StockReturnCalculator } from "./StockReturnCalculator";
import { DividendYieldCalculator } from "./DividendYieldCalculator";
import { PeRatioCalculator } from "./PeRatioCalculator";
import { EpsCalculator } from "./EpsCalculator";
import { CagrCalculator } from "./CagrCalculator";
import { FutureValueCalculator } from "./FutureValueCalculator";
import { BetaCalculator } from "./BetaCalculator";
import { SharpeRatioCalculator } from "./SharpeRatioCalculator";
import { AlphaCalculator } from "./AlphaCalculator";
import { IntrinsicValueCalculator } from "./IntrinsicValueCalculator";
import { DividendReinvestmentCalculator } from "./DividendReinvestmentCalculator";
import { StockSplitCalculator } from "./StockSplitCalculator";
import { CapitalGainsTaxCalculator } from "./CapitalGainsTaxCalculator";
import { PositionSizingCalculator } from "./PositionSizingCalculator";
import { StopLossTakeProfitCalculator } from "./StopLossTakeProfitCalculator";
import { OptionsCalculator } from "./OptionsCalculator";
import { MarginCalculator } from "./MarginCalculator";
import { DdmCalculator } from "./DdmCalculator";
import { WaccCalculator } from "./WaccCalculator";
import { PaybackPeriodCalculator } from "./PaybackPeriodCalculator";
import { VolatilityCalculator } from "./VolatilityCalculator";
import { CalculatorDescription } from "./CalculatorDescription";
import { StockProfitCalculator } from "./StockProfitCalculator";
import { PortfolioDiversificationCalculator } from "./PortfolioDiversificationCalculator";
import { RiskRewardCalculator } from "./RiskRewardCalculator";

interface CalculatorContentProps {
  calculators: Array<{
    id: string;
    label: string;
    description?: string;
  }>;
  activeCalculator: string;
  isMobile: boolean;
}

const getCalculatorComponent = (id: string) => {
  const components: { [key: string]: React.ComponentType<any> } = {
    "stock-return": StockReturnCalculator,
    "dividend-yield": DividendYieldCalculator,
    "pe-ratio": PeRatioCalculator,
    "eps": EpsCalculator,
    "cagr": CagrCalculator,
    "future-value": FutureValueCalculator,
    "beta": BetaCalculator,
    "sharpe-ratio": SharpeRatioCalculator,
    "alpha": AlphaCalculator,
    "intrinsic-value": IntrinsicValueCalculator,
    "dividend-reinvestment": DividendReinvestmentCalculator,
    "stock-split": StockSplitCalculator,
    "capital-gains-tax": CapitalGainsTaxCalculator,
    "position-sizing": PositionSizingCalculator,
    "stop-loss": StopLossTakeProfitCalculator,
    "options-profit": OptionsCalculator,
    "margin": MarginCalculator,
    "ddm": DdmCalculator,
    "wacc": WaccCalculator,
    "payback-period": PaybackPeriodCalculator,
    "volatility": VolatilityCalculator,
    "stock-profit": StockProfitCalculator,
    "portfolio-diversification": PortfolioDiversificationCalculator,
    "risk-reward": RiskRewardCalculator,
  };

  return components[id] || null;
};

export const CalculatorContent = ({ 
  calculators, 
  activeCalculator,
  isMobile 
}: CalculatorContentProps) => {
  const activeCalc = calculators.find(calc => calc.id === activeCalculator);
  
  const renderCalculator = (id: string) => {
    const Component = getCalculatorComponent(id);
    if (!Component) {
      return (
        <div className="p-6 text-center text-gray-500">
          Calculator coming soon...
        </div>
      );
    }

    return (
      <div className="space-y-6">
        {activeCalc?.description && (
          <CalculatorDescription 
            title={`About ${activeCalc.label}`}
            description={activeCalc.description}
          />
        )}
        <Component />
      </div>
    );
  };

  if (isMobile) {
    return (
      <div className="mt-8">
        {calculators.map((calc) => (
          <div
            key={calc.id}
            className={activeCalculator === calc.id ? "block" : "hidden"}
          >
            {renderCalculator(calc.id)}
          </div>
        ))}
      </div>
    );
  }

  return (
    <Tabs value={activeCalculator} className="w-full">
      {calculators.map((calc) => (
        <TabsContent key={calc.id} value={calc.id} className="mt-8">
          {renderCalculator(calc.id)}
        </TabsContent>
      ))}
    </Tabs>
  );
};
