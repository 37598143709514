import ContentSection from "@/components/ContentSection";

export const MarketBasicsSection = () => {
  return (
    <ContentSection title="Understanding Stock Market Basics">
      <h3 className="text-xl font-semibold mb-4">What is the Stock Market?</h3>
      <p className="mb-4">
        The stock market is a collection of exchanges where shares of publicly traded companies are bought and sold. Here's what you need to know:
      </p>
      <ul className="list-disc pl-6 mb-6 space-y-3">
        <li>
          <strong>Stocks and Shares:</strong> When you buy a stock, you're purchasing partial ownership in a company, entitling you to a portion of their profits and assets.
        </li>
        <li>
          <strong>Stock Exchanges:</strong> Markets like the NYSE and NASDAQ where stocks are traded. They provide the infrastructure for buying and selling shares.
        </li>
        <li>
          <strong>Market Participants:</strong> Individual investors, institutional investors, market makers, and brokers all play different roles in the market.
        </li>
        <li>
          <strong>Market Hours:</strong> Regular trading hours are 9:30 AM to 4:00 PM Eastern Time, Monday through Friday, excluding market holidays.
        </li>
      </ul>

      <h3 className="text-xl font-semibold mb-4">Getting Started</h3>
      <ul className="list-disc pl-6 mb-6 space-y-3">
        <li>
          <strong>Open a Brokerage Account:</strong> Choose a reputable broker that offers educational resources, research tools, and competitive fees.
        </li>
        <li>
          <strong>Start with Index Funds:</strong> Consider beginning with broad market index funds for instant diversification and lower risk.
        </li>
        <li>
          <strong>Learn Key Terms:</strong> Familiarize yourself with important concepts like P/E ratios, market capitalization, and dividend yields.
        </li>
      </ul>
    </ContentSection>
  );
};