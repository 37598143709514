import PageLayout from "@/components/PageLayout";
import ContentSection from "@/components/ContentSection";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { SideHustleCalculatorsSection } from "@/components/side-hustles/SideHustleCalculatorsSection";
import { BriefcaseIcon, DollarSign, Megaphone, BarChart3 } from "lucide-react";

const SideHustles = () => {
  return (
    <PageLayout
      title="Profitable Side Hustle Ideas"
      description="Discover the best side hustles to earn extra income. Learn how to start and grow multiple income streams while maintaining your full-time job."
    >
      <div className="space-y-12">
        <div className="prose max-w-none mb-8">
          <p className="text-lg text-gray-700 leading-relaxed">
            Side hustles offer a flexible way to earn additional income while maintaining your primary job. 
            In today's digital age, there are countless opportunities to monetize your skills and interests. 
            This guide explores various side hustle options, from digital content creation to e-commerce, 
            helping you find the perfect opportunity to boost your income.
          </p>
        </div>

        <Tabs defaultValue="digital" className="w-full">
          <TabsList className="grid w-full grid-cols-2 lg:grid-cols-4">
            <TabsTrigger value="digital" className="flex items-center gap-2">
              <DollarSign className="w-4 h-4" />
              Digital Hustles
            </TabsTrigger>
            <TabsTrigger value="services" className="flex items-center gap-2">
              <BriefcaseIcon className="w-4 h-4" />
              Service-Based
            </TabsTrigger>
            <TabsTrigger value="marketing" className="flex items-center gap-2">
              <Megaphone className="w-4 h-4" />
              Marketing
            </TabsTrigger>
            <TabsTrigger value="market-research" className="flex items-center gap-2">
              <BarChart3 className="w-4 h-4" />
              Market Research
            </TabsTrigger>
          </TabsList>

          <TabsContent value="digital" className="mt-6">
            <ContentSection title="Digital Side Hustles">
              <div className="space-y-6">
                <div>
                  <h3 className="text-xl font-semibold mb-3">Content Creation</h3>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>Blogging and Article Writing
                      <p className="text-gray-600 mt-1">Start a blog in your area of expertise, monetize through ads, affiliates, and sponsored content.</p>
                    </li>
                    <li>YouTube Channel
                      <p className="text-gray-600 mt-1">Create video content, build an audience, and earn through ads, sponsorships, and merchandise.</p>
                    </li>
                    <li>Online Courses
                      <p className="text-gray-600 mt-1">Share your knowledge by creating and selling courses on platforms like Udemy or your own website.</p>
                    </li>
                  </ul>
                </div>
                
                <div>
                  <h3 className="text-xl font-semibold mb-3">Digital Products</h3>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>eBooks and Digital Downloads
                      <p className="text-gray-600 mt-1">Write and sell eBooks, templates, or printables in your area of expertise.</p>
                    </li>
                    <li>Stock Media
                      <p className="text-gray-600 mt-1">Sell photos, videos, or music on stock media platforms.</p>
                    </li>
                    <li>Apps and Software
                      <p className="text-gray-600 mt-1">Develop and sell mobile apps, web tools, or software solutions.</p>
                    </li>
                  </ul>
                </div>
              </div>
            </ContentSection>
          </TabsContent>

          <TabsContent value="services" className="mt-6">
            <ContentSection title="Service-Based Side Hustles">
              <div className="space-y-6">
                <div>
                  <h3 className="text-xl font-semibold mb-3">Professional Services</h3>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>Freelance Writing
                      <p className="text-gray-600 mt-1">Offer content writing, copywriting, or technical writing services to businesses.</p>
                    </li>
                    <li>Virtual Assistance
                      <p className="text-gray-600 mt-1">Provide administrative support, email management, and scheduling services remotely.</p>
                    </li>
                    <li>Consulting
                      <p className="text-gray-600 mt-1">Share your expertise in business, marketing, or your professional field.</p>
                    </li>
                  </ul>
                </div>
                
                <div>
                  <h3 className="text-xl font-semibold mb-3">Local Services</h3>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>Pet Services
                      <p className="text-gray-600 mt-1">Offer pet sitting, dog walking, or grooming services in your local area.</p>
                    </li>
                    <li>Home Services
                      <p className="text-gray-600 mt-1">Provide cleaning, landscaping, or handyman services to homeowners.</p>
                    </li>
                    <li>Personal Training
                      <p className="text-gray-600 mt-1">Offer fitness training, nutrition coaching, or wellness services.</p>
                    </li>
                  </ul>
                </div>
              </div>
            </ContentSection>
          </TabsContent>

          <TabsContent value="marketing" className="mt-6">
            <ContentSection title="Marketing and Sales Strategies">
              <div className="space-y-6">
                <div>
                  <h3 className="text-xl font-semibold mb-3">Digital Marketing</h3>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>Social Media Marketing
                      <p className="text-gray-600 mt-1">Build and manage social media presence for businesses, create content strategies.</p>
                    </li>
                    <li>Email Marketing
                      <p className="text-gray-600 mt-1">Design and execute email campaigns, build subscriber lists, analyze metrics.</p>
                    </li>
                    <li>SEO Services
                      <p className="text-gray-600 mt-1">Help businesses improve their search engine rankings and online visibility.</p>
                    </li>
                  </ul>
                </div>
                
                <div>
                  <h3 className="text-xl font-semibold mb-3">Sales Channels</h3>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>Affiliate Marketing
                      <p className="text-gray-600 mt-1">Promote products and earn commissions through affiliate partnerships.</p>
                    </li>
                    <li>Dropshipping
                      <p className="text-gray-600 mt-1">Set up an online store without holding inventory, focus on marketing and customer service.</p>
                    </li>
                    <li>Print on Demand
                      <p className="text-gray-600 mt-1">Design and sell custom products without upfront inventory investment.</p>
                    </li>
                  </ul>
                </div>
              </div>
            </ContentSection>
          </TabsContent>

          <TabsContent value="market-research" className="mt-6">
            <ContentSection title="Market Research and Analysis">
              <div className="space-y-6">
                <div>
                  <h3 className="text-xl font-semibold mb-3">Market Analysis</h3>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>Competitor Research
                      <p className="text-gray-600 mt-1">Analyze competition, identify market gaps and opportunities.</p>
                    </li>
                    <li>Target Audience Analysis
                      <p className="text-gray-600 mt-1">Define and understand your ideal customer demographics and behaviors.</p>
                    </li>
                    <li>Trend Analysis
                      <p className="text-gray-600 mt-1">Stay updated with industry trends and market developments.</p>
                    </li>
                  </ul>
                </div>
                
                <div>
                  <h3 className="text-xl font-semibold mb-3">Business Metrics</h3>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>Financial Projections
                      <p className="text-gray-600 mt-1">Estimate potential revenue, costs, and profitability for your business idea.</p>
                    </li>
                    <li>Growth Metrics
                      <p className="text-gray-600 mt-1">Track and analyze key performance indicators for business growth.</p>
                    </li>
                    <li>Market Size Calculation
                      <p className="text-gray-600 mt-1">Determine your total addressable market and growth potential.</p>
                    </li>
                  </ul>
                </div>
              </div>
            </ContentSection>
          </TabsContent>
        </Tabs>

        <SideHustleCalculatorsSection />
      </div>
    </PageLayout>
  );
};

export default SideHustles;