import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { DollarSign } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";

export const FutureValueCalculator = () => {
  const [initialInvestment, setInitialInvestment] = useState("");
  const [annualReturn, setAnnualReturn] = useState("");
  const [years, setYears] = useState("");
  const [result, setResult] = useState<number | null>(null);
  const { toast } = useToast();

  const calculateFutureValue = () => {
    const principal = parseFloat(initialInvestment);
    const rate = parseFloat(annualReturn) / 100;
    const time = parseFloat(years);

    if (isNaN(principal) || isNaN(rate) || isNaN(time)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    if (principal <= 0 || time <= 0) {
      toast({
        title: "Invalid Values",
        description: "Initial investment and time period must be greater than zero",
        variant: "destructive",
      });
      return;
    }

    const futureValue = principal * Math.pow(1 + rate, time);
    setResult(futureValue);
    console.log("Calculated future value:", futureValue);
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="initial-investment">Initial Investment</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="initial-investment"
              type="number"
              placeholder="10000"
              className="pl-10"
              value={initialInvestment}
              onChange={(e) => setInitialInvestment(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="annual-return">Expected Annual Return (%)</Label>
          <Input
            id="annual-return"
            type="number"
            placeholder="8"
            value={annualReturn}
            onChange={(e) => setAnnualReturn(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="years">Investment Period (Years)</Label>
          <Input
            id="years"
            type="number"
            placeholder="10"
            value={years}
            onChange={(e) => setYears(e.target.value)}
          />
        </div>

        <Button 
          className="w-full"
          onClick={calculateFutureValue}
        >
          Calculate Future Value
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg">
            <p className="text-center text-lg font-semibold">
              Future Value:
              <span className="block text-2xl text-primary">
                ${result.toLocaleString(undefined, { maximumFractionDigits: 2 })}
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};