import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useState } from "react";

// Page imports
import Index from "./pages/Index";
import MoneyMindset from "./pages/MoneyMindset";
import BasicFinance from "./pages/BasicFinance";
import StockMarket from "./pages/StockMarket";
import Housing from "./pages/Housing";
import Business from "./pages/Business";
import SideHustles from "./pages/SideHustles";
import BlogPage from "./pages/BlogPage";
import BlogPostPage from "./pages/BlogPostPage";
import Footer from "./components/Footer";

console.log('App component initializing...');

const App = () => {
  console.log('App component rendering...');
  
  const [queryClient] = useState(() => new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60 * 5, // 5 minutes
        retry: 1,
      },
    },
  }));

  // Check if the current path is /rss.xml
  if (window.location.pathname === '/rss.xml') {
    console.log('RSS feed requested, redirecting to Supabase function...');
    window.location.href = 'https://zoctzdgmvnjqtuuyszng.functions.supabase.co/rss-feed';
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <TooltipProvider>
        <div className="min-h-screen flex flex-col">
          <Toaster />
          <Sonner />
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Index />} />
              <Route path="/money-mindset" element={<MoneyMindset />} />
              <Route path="/basic-finance" element={<BasicFinance />} />
              <Route path="/stock-market" element={<StockMarket />} />
              <Route path="/housing" element={<Housing />} />
              <Route path="/business" element={<Business />} />
              <Route path="/side-hustles" element={<SideHustles />} />
              <Route path="/blog" element={<BlogPage />} />
              <Route path="/blog/:slug" element={<BlogPostPage />} />
              {/* Catch-all route */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
            <Footer />
          </BrowserRouter>
        </div>
      </TooltipProvider>
    </QueryClientProvider>
  );
};

export default App;