import ContentSection from "@/components/ContentSection";

export const InflationSection = () => {
  return (
    <ContentSection title="Understanding Inflation and Your Money">
      <h3 className="text-xl font-semibold mb-4">The Impact of Inflation on Your Wealth</h3>
      <p className="mb-6">
        Inflation erodes the purchasing power of your money over time, making it crucial to understand
        how different saving and investing strategies can help protect your wealth.
      </p>

      <div className="space-y-6">
        <div>
          <h4 className="text-lg font-semibold mb-3">Keeping Money in the Bank</h4>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <h5 className="font-semibold text-green-600 mb-2">Advantages</h5>
              <ul className="list-disc pl-5 space-y-2">
                <li>FDIC insurance protection</li>
                <li>Immediate access to funds</li>
                <li>No risk of market losses</li>
                <li>Predictable interest earnings</li>
              </ul>
            </div>
            <div>
              <h5 className="font-semibold text-red-600 mb-2">Disadvantages</h5>
              <ul className="list-disc pl-5 space-y-2">
                <li>Interest rates usually below inflation</li>
                <li>Loss of purchasing power over time</li>
                <li>Opportunity cost of potential market gains</li>
                <li>Real negative returns in high-inflation periods</li>
              </ul>
            </div>
          </div>
        </div>

        <div>
          <h4 className="text-lg font-semibold mb-3">Investing to Beat Inflation</h4>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <h5 className="font-semibold text-green-600 mb-2">Advantages</h5>
              <ul className="list-disc pl-5 space-y-2">
                <li>Potential for returns above inflation</li>
                <li>Long-term wealth preservation</li>
                <li>Dividend growth can outpace inflation</li>
                <li>Asset appreciation opportunities</li>
              </ul>
            </div>
            <div>
              <h5 className="font-semibold text-red-600 mb-2">Disadvantages</h5>
              <ul className="list-disc pl-5 space-y-2">
                <li>Market volatility risk</li>
                <li>Requires longer time horizon</li>
                <li>Need for investment knowledge</li>
                <li>May need professional guidance</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="bg-gray-50 p-6 rounded-lg">
          <h4 className="text-lg font-semibold mb-3">Inflation-Fighting Strategies</h4>
          <ul className="list-disc pl-5 space-y-3">
            <li>
              <strong>Diversified Investment Portfolio:</strong> Mix of stocks, bonds, and real assets
              to provide multiple inflation hedges
            </li>
            <li>
              <strong>TIPS (Treasury Inflation-Protected Securities):</strong> Government bonds that
              adjust with inflation
            </li>
            <li>
              <strong>Real Estate Investments:</strong> Property values and rents tend to rise with
              inflation
            </li>
            <li>
              <strong>Commodity Exposure:</strong> Natural resources often increase in price during
              inflationary periods
            </li>
          </ul>
        </div>
      </div>
    </ContentSection>
  );
};