import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { DollarSign, Calculator } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";

export const FireCalculator = () => {
  const [annualExpenses, setAnnualExpenses] = useState("");
  const [withdrawalRate, setWithdrawalRate] = useState("4");
  const [fireNumber, setFireNumber] = useState<number | null>(null);
  const { toast } = useToast();

  const calculateFireNumber = () => {
    const expenses = parseFloat(annualExpenses);
    const rate = parseFloat(withdrawalRate);

    if (isNaN(expenses) || isNaN(rate) || rate === 0) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    const result = (expenses * 100) / rate;
    setFireNumber(result);
    
    console.log("Calculated FIRE number:", result);
  };

  return (
    <Card className="wealth-card p-8 w-full max-w-md mx-auto">
      <div className="space-y-6">
        <div className="flex items-center space-x-2">
          <Calculator className="w-8 h-8 text-primary" />
          <h2 className="text-2xl font-bold gradient-text">FIRE Number Calculator</h2>
        </div>

        <div className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="annual-expenses" className="text-lg">Annual Expenses</Label>
            <div className="relative">
              <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
              <Input
                id="annual-expenses"
                type="number"
                placeholder="60000"
                className="pl-10 h-12 text-lg"
                value={annualExpenses}
                onChange={(e) => setAnnualExpenses(e.target.value)}
              />
            </div>
          </div>

          <div className="space-y-2">
            <Label htmlFor="withdrawal-rate" className="text-lg">Safe Withdrawal Rate (%)</Label>
            <Input
              id="withdrawal-rate"
              type="number"
              placeholder="4"
              className="h-12 text-lg"
              value={withdrawalRate}
              onChange={(e) => setWithdrawalRate(e.target.value)}
            />
          </div>

          <Button 
            className="w-full h-12 text-lg hover:scale-105 transition-transform"
            onClick={calculateFireNumber}
          >
            Calculate FIRE Number
          </Button>

          {fireNumber !== null && (
            <div className="mt-6 p-6 bg-gradient-to-r from-primary/10 to-secondary/10 rounded-lg animate-fade-in">
              <p className="text-center">
                <span className="block text-lg font-semibold mb-2">Your FIRE Number:</span>
                <span className="block text-4xl font-bold gradient-text">
                  ${fireNumber.toLocaleString()}
                </span>
              </p>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};