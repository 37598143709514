const BlogHeader = () => {
  return (
    <header className="text-center mb-12">
      <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-primary to-accent bg-clip-text text-transparent">
        WealthWise Financial Insights
      </h1>
      <p className="text-xl text-gray-600 max-w-2xl mx-auto">
        Expert analysis, practical tips, and strategic advice to help you make smarter financial decisions
      </p>
    </header>
  );
};

export default BlogHeader;