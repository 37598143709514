import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { DollarSign } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";

export const SavingsRateCalculator = () => {
  const [monthlyIncome, setMonthlyIncome] = useState("");
  const [monthlySavings, setMonthlySavings] = useState("");
  const [savingsRate, setSavingsRate] = useState<number | null>(null);
  const { toast } = useToast();

  const calculateSavingsRate = () => {
    const income = parseFloat(monthlyIncome);
    const savings = parseFloat(monthlySavings);

    if (isNaN(income) || isNaN(savings)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    if (income === 0) {
      toast({
        title: "Invalid Input",
        description: "Income cannot be zero",
        variant: "destructive",
      });
      return;
    }

    const rate = (savings / income) * 100;
    setSavingsRate(rate);
    console.log("Calculated savings rate:", rate);
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="monthly-income">Monthly Income (After Tax)</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="monthly-income"
              type="number"
              placeholder="5000"
              className="pl-10"
              value={monthlyIncome}
              onChange={(e) => setMonthlyIncome(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="monthly-savings">Monthly Savings</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="monthly-savings"
              type="number"
              placeholder="2000"
              className="pl-10"
              value={monthlySavings}
              onChange={(e) => setMonthlySavings(e.target.value)}
            />
          </div>
        </div>

        <Button 
          className="w-full"
          onClick={calculateSavingsRate}
        >
          Calculate Savings Rate
        </Button>

        {savingsRate !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg">
            <p className="text-center text-lg font-semibold">
              Your Savings Rate:
              <span className="block text-2xl text-primary">
                {savingsRate.toFixed(1)}%
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};