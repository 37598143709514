import ContentSection from "@/components/ContentSection";

export const InvestmentTypesSection = () => {
  return (
    <ContentSection title="Types of Investments">
      <div className="space-y-8">
        <div>
          <h3 className="text-xl font-semibold mb-4">Exchange-Traded Funds (ETFs)</h3>
          <p className="mb-4">
            ETFs are investment funds traded on stock exchanges, holding multiple assets like stocks, bonds, or commodities.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <h4 className="font-semibold text-green-600 mb-2">Advantages</h4>
              <ul className="list-disc pl-5 space-y-2">
                <li>Instant diversification across multiple assets</li>
                <li>Lower costs compared to mutual funds</li>
                <li>Trade like stocks with real-time pricing</li>
                <li>Tax efficiency due to fewer capital gains distributions</li>
              </ul>
            </div>
            <div>
              <h4 className="font-semibold text-red-600 mb-2">Disadvantages</h4>
              <ul className="list-disc pl-5 space-y-2">
                <li>Trading commissions can add up</li>
                <li>Some niche ETFs have low trading volume</li>
                <li>May not exactly track their underlying index</li>
                <li>Complex ETFs might carry additional risks</li>
              </ul>
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-xl font-semibold mb-4">Dividend Stocks</h3>
          <p className="mb-4">
            Dividend stocks are shares in companies that regularly distribute a portion of their earnings to shareholders.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <h4 className="font-semibold text-green-600 mb-2">Advantages</h4>
              <ul className="list-disc pl-5 space-y-2">
                <li>Regular income stream</li>
                <li>Often more stable than growth stocks</li>
                <li>Potential for dividend growth over time</li>
                <li>Companies tend to be well-established</li>
              </ul>
            </div>
            <div>
              <h4 className="font-semibold text-red-600 mb-2">Disadvantages</h4>
              <ul className="list-disc pl-5 space-y-2">
                <li>Lower potential for capital appreciation</li>
                <li>Dividends can be cut or suspended</li>
                <li>May underperform in bull markets</li>
                <li>Tax implications on regular dividend payments</li>
              </ul>
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-xl font-semibold mb-4">Bonds</h3>
          <p className="mb-4">
            Bonds are debt instruments issued by governments, municipalities, or corporations to raise capital.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <h4 className="font-semibold text-green-600 mb-2">Advantages</h4>
              <ul className="list-disc pl-5 space-y-2">
                <li>Fixed, predictable income</li>
                <li>Generally lower risk than stocks</li>
                <li>Can help preserve capital</li>
                <li>Good for portfolio diversification</li>
              </ul>
            </div>
            <div>
              <h4 className="font-semibold text-red-600 mb-2">Disadvantages</h4>
              <ul className="list-disc pl-5 space-y-2">
                <li>Lower potential returns than stocks</li>
                <li>Interest rate risk</li>
                <li>May not keep pace with inflation</li>
                <li>Credit risk if issuer defaults</li>
              </ul>
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-xl font-semibold mb-4">Real Estate Investment Trusts (REITs)</h3>
          <p className="mb-4">
            REITs are companies that own, operate, or finance income-producing real estate properties.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <h4 className="font-semibold text-green-600 mb-2">Advantages</h4>
              <ul className="list-disc pl-5 space-y-2">
                <li>High dividend yields</li>
                <li>Real estate exposure without direct ownership</li>
                <li>Professional management</li>
                <li>Portfolio diversification benefits</li>
              </ul>
            </div>
            <div>
              <h4 className="font-semibold text-red-600 mb-2">Disadvantages</h4>
              <ul className="list-disc pl-5 space-y-2">
                <li>Sensitive to interest rate changes</li>
                <li>Property-specific risks</li>
                <li>Complex tax implications</li>
                <li>May be affected by real estate market cycles</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </ContentSection>
  );
};