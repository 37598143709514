import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { DollarSign } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";

export const CagrCalculator = () => {
  const [initialValue, setInitialValue] = useState("");
  const [finalValue, setFinalValue] = useState("");
  const [years, setYears] = useState("");
  const [result, setResult] = useState<number | null>(null);
  const { toast } = useToast();

  const calculateCAGR = () => {
    const initial = parseFloat(initialValue);
    const final = parseFloat(finalValue);
    const period = parseFloat(years);

    if (isNaN(initial) || isNaN(final) || isNaN(period)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    if (initial <= 0 || period <= 0) {
      toast({
        title: "Invalid Values",
        description: "Initial value and time period must be greater than zero",
        variant: "destructive",
      });
      return;
    }

    const cagr = (Math.pow(final / initial, 1 / period) - 1) * 100;
    setResult(cagr);
    console.log("Calculated CAGR:", cagr);
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="initial-value">Initial Investment Value</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="initial-value"
              type="number"
              placeholder="10000"
              className="pl-10"
              value={initialValue}
              onChange={(e) => setInitialValue(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="final-value">Final Investment Value</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="final-value"
              type="number"
              placeholder="15000"
              className="pl-10"
              value={finalValue}
              onChange={(e) => setFinalValue(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="years">Investment Period (Years)</Label>
          <Input
            id="years"
            type="number"
            placeholder="5"
            value={years}
            onChange={(e) => setYears(e.target.value)}
          />
        </div>

        <Button 
          className="w-full"
          onClick={calculateCAGR}
        >
          Calculate CAGR
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg">
            <p className="text-center text-lg font-semibold">
              Compound Annual Growth Rate:
              <span className="block text-2xl text-primary">
                {result.toFixed(2)}%
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};