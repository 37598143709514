import { Link } from "react-router-dom";
import { DollarSign, TrendingUp, House, Briefcase, Wallet, Brain, ArrowRight, BookOpen, Users, FileText } from "lucide-react";
import { Card } from "@/components/ui/card";
import Navigation from "@/components/Navigation";
import { FireCalculator } from "@/components/FireCalculator";
import { Button } from "@/components/ui/button";

const Index = () => {
  const topics = [
    {
      title: "Money Mindset",
      description: "Develop the right mindset for financial success and freedom.",
      icon: Brain,
      path: "/money-mindset",
    },
    {
      title: "Basic Finance",
      description: "Learn fundamental money management skills and start your journey to financial freedom.",
      icon: DollarSign,
      path: "/basic-finance",
    },
    {
      title: "Stock Market",
      description: "Understand investing in stocks, ETFs, and building a diversified portfolio.",
      icon: TrendingUp,
      path: "/stock-market",
    },
    {
      title: "Housing",
      description: "Navigate real estate investments and optimize your mortgage strategy.",
      icon: House,
      path: "/housing",
    },
    {
      title: "Business",
      description: "Start and grow your own business with our comprehensive guides and tools.",
      icon: Briefcase,
      path: "/business",
    },
    {
      title: "Side Hustles",
      description: "Discover ways to earn extra income and turn your skills into profit.",
      icon: Wallet,
      path: "/side-hustles",
    },
  ];

  const quickStats = [
    {
      label: "Average FIRE Age",
      value: "47 years",
      description: "Compared to traditional retirement age of 65"
    },
    {
      label: "Recommended Savings Rate",
      value: "50-70%",
      description: "Of after-tax income for early retirement"
    },
    {
      label: "FIRE Number Multiple",
      value: "25-30x",
      description: "Annual expenses for financial independence"
    }
  ];

  const fireJourneySteps = [
    {
      stage: "Starting Out",
      milestone: "Build Emergency Fund",
      description: "3-6 months of expenses saved"
    },
    {
      stage: "Building Momentum",
      milestone: "50% Savings Rate",
      description: "Optimizing expenses and increasing income"
    },
    {
      stage: "Accelerating Wealth",
      milestone: "First $100K Invested",
      description: "Investment portfolio growth"
    },
    {
      stage: "Approaching Freedom",
      milestone: "Reached FIRE Number",
      description: "Financial independence achieved"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-white to-gray-50">
      <Navigation />
      
      <main className="container mx-auto px-4 py-12">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-8">
            <h1 className="text-5xl font-bold mb-4 gradient-text animate-fade-in">
              Your Journey to Financial Freedom Starts Here
            </h1>
            <p className="text-xl text-gray-600 mb-6 animate-fade-in">
              Join thousands achieving Financial Independence and Early Retirement (FIRE)
            </p>
          </div>

          {/* Quick Stats Section */}
          <div className="grid md:grid-cols-3 gap-6 mb-12">
            {quickStats.map((stat, index) => (
              <Card key={index} className="p-6 text-center hover-scale">
                <h3 className="text-lg font-semibold text-gray-600">{stat.label}</h3>
                <p className="text-3xl font-bold gradient-text my-2">{stat.value}</p>
                <p className="text-sm text-gray-500">{stat.description}</p>
              </Card>
            ))}
          </div>

          <div className="mb-12 wealth-card p-8 animate-fade-in">
            <h2 className="text-3xl font-bold mb-4 gradient-text">What is FIRE?</h2>
            <p className="text-gray-700 mb-4 text-lg">
              FIRE (Financial Independence, Retire Early) is a movement focused on aggressive saving and investing to achieve financial freedom and potentially retire earlier than traditional retirement age. The core principle is to save and invest a significant portion of your income while maintaining a sustainable lifestyle.
            </p>
            <p className="text-gray-700 mb-6 text-lg">
              Our comprehensive guides will help you navigate each aspect of your FIRE journey:
            </p>
            <ul className="grid md:grid-cols-2 gap-4 mb-6">
              {topics.map((topic) => (
                <li key={topic.path} className="flex items-start gap-2">
                  <topic.icon className="w-6 h-6 text-primary flex-shrink-0 mt-1" />
                  <div>
                    <strong className="text-primary">{topic.title}:</strong>
                    <span className="text-gray-700"> {topic.description}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          {/* FIRE Journey Timeline */}
          <div className="mb-12">
            <h2 className="text-3xl font-bold mb-6 text-center gradient-text">Your FIRE Journey</h2>
            <div className="grid md:grid-cols-4 gap-4">
              {fireJourneySteps.map((step, index) => (
                <Card key={index} className="p-6 relative hover-scale">
                  <div className="absolute top-0 right-0 w-8 h-8 bg-primary text-white rounded-full flex items-center justify-center -mt-2 -mr-2">
                    {index + 1}
                  </div>
                  <h3 className="text-lg font-semibold mb-2">{step.stage}</h3>
                  <p className="font-medium text-primary mb-2">{step.milestone}</p>
                  <p className="text-sm text-gray-600">{step.description}</p>
                </Card>
              ))}
            </div>
          </div>

          <div className="mb-16">
            <h2 className="text-3xl font-bold mb-6 text-center gradient-text">Calculate Your FIRE Number</h2>
            <FireCalculator />
          </div>

          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
            {topics.map((topic) => (
              <Link key={topic.path} to={topic.path}>
                <Card className="p-6 h-full hover-scale group">
                  <div className="flex flex-col h-full">
                    <topic.icon className="w-12 h-12 text-primary mb-4 group-hover:scale-110 transition-transform" />
                    <h2 className="text-xl font-semibold mb-2">{topic.title}</h2>
                    <p className="text-gray-600 mb-4 flex-grow">{topic.description}</p>
                    <Button className="w-full group">
                      Learn More
                      <ArrowRight className="w-4 h-4 ml-2 group-hover:translate-x-1 transition-transform" />
                    </Button>
                  </div>
                </Card>
              </Link>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Index;