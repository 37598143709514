import { Info } from "lucide-react";

interface CalculatorDescriptionProps {
  title: string;
  description: string;
}

export const CalculatorDescription = ({ title, description }: CalculatorDescriptionProps) => {
  return (
    <div className="mb-6 p-4 bg-accent/10 rounded-lg">
      <div className="flex items-start gap-3">
        <Info className="w-5 h-5 text-accent mt-1 flex-shrink-0" />
        <div>
          <h3 className="font-semibold mb-2">{title}</h3>
          <p className="text-sm text-gray-600">{description}</p>
        </div>
      </div>
    </div>
  );
};