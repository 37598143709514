import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { DollarSign } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";

export const CoastFireCalculator = () => {
  const [currentAge, setCurrentAge] = useState("");
  const [retirementAge, setRetirementAge] = useState("");
  const [currentSavings, setCurrentSavings] = useState("");
  const [annualExpenses, setAnnualExpenses] = useState("");
  const [expectedReturn, setExpectedReturn] = useState("7");
  const [result, setResult] = useState<{
    coastNumber: number;
    additionalSavingsNeeded: number;
  } | null>(null);
  const { toast } = useToast();

  const calculateCoastFire = () => {
    const age = parseFloat(currentAge);
    const retireAge = parseFloat(retirementAge);
    const savings = parseFloat(currentSavings);
    const expenses = parseFloat(annualExpenses);
    const return_rate = parseFloat(expectedReturn) / 100;

    if (isNaN(age) || isNaN(retireAge) || isNaN(savings) || isNaN(expenses) || isNaN(return_rate)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    const yearsToRetirement = retireAge - age;
    const targetAmount = expenses * 25; // Using 4% rule
    const coastNumber = targetAmount / Math.pow(1 + return_rate, yearsToRetirement);
    const additionalSavingsNeeded = Math.max(0, coastNumber - savings);

    setResult({
      coastNumber,
      additionalSavingsNeeded
    });

    console.log("Calculated Coast FIRE numbers:", { coastNumber, additionalSavingsNeeded });
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="current-age">Current Age</Label>
          <Input
            id="current-age"
            type="number"
            placeholder="30"
            value={currentAge}
            onChange={(e) => setCurrentAge(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="retirement-age">Target Retirement Age</Label>
          <Input
            id="retirement-age"
            type="number"
            placeholder="65"
            value={retirementAge}
            onChange={(e) => setRetirementAge(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="current-savings">Current Investment Portfolio</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="current-savings"
              type="number"
              placeholder="100000"
              className="pl-10"
              value={currentSavings}
              onChange={(e) => setCurrentSavings(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="annual-expenses">Target Annual Expenses in Retirement</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="annual-expenses"
              type="number"
              placeholder="40000"
              className="pl-10"
              value={annualExpenses}
              onChange={(e) => setAnnualExpenses(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="expected-return">Expected Annual Return (%)</Label>
          <Input
            id="expected-return"
            type="number"
            placeholder="7"
            value={expectedReturn}
            onChange={(e) => setExpectedReturn(e.target.value)}
          />
        </div>

        <Button 
          className="w-full"
          onClick={calculateCoastFire}
        >
          Calculate Coast FIRE Number
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg space-y-2">
            <p className="text-center">
              <span className="block text-lg font-semibold">Coast FIRE Number:</span>
              <span className="block text-2xl text-primary">
                ${result.coastNumber.toLocaleString(undefined, { maximumFractionDigits: 0 })}
              </span>
            </p>
            <p className="text-center">
              <span className="block text-lg font-semibold">Additional Savings Needed:</span>
              <span className="block text-2xl text-primary">
                ${result.additionalSavingsNeeded.toLocaleString(undefined, { maximumFractionDigits: 0 })}
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};