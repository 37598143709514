
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { ArrowRight } from "lucide-react";

const EmotionalIntelligenceTab = () => {
  return (
    <Card className="p-6">
      <h3 className="text-xl font-bold mb-4">Financial Emotional Intelligence</h3>
      <div className="space-y-6">
        <div>
          <h4 className="text-lg font-semibold mb-2">Managing Money Emotions</h4>
          <p className="text-gray-700 mb-3">
            Develop emotional awareness around money:
          </p>
          <ul className="list-disc pl-6 space-y-2 text-gray-700">
            <li>Recognize emotional spending triggers</li>
            <li>Practice mindful financial decision-making</li>
            <li>Build healthy money boundaries</li>
            <li>Develop stress management techniques</li>
          </ul>
        </div>

        <div>
          <h4 className="text-lg font-semibold mb-2">Emotional Resilience</h4>
          <p className="text-gray-700 mb-3">
            Build financial resilience through:
          </p>
          <ul className="list-disc pl-6 space-y-2 text-gray-700">
            <li>Learning from financial setbacks</li>
            <li>Developing a growth mindset</li>
            <li>Building support networks</li>
            <li>Creating emergency funds</li>
          </ul>
        </div>

        <div className="bg-purple-50 p-4 rounded-lg">
          <h4 className="text-lg font-semibold mb-2">Take Action</h4>
          <p className="text-gray-700 mb-3">Start your emotional intelligence journey:</p>
          <Link to="/basic-finance#emergency-fund">
            <Button className="w-full">
              Learn About Emergency Funds
              <ArrowRight className="w-4 h-4 ml-2" />
            </Button>
          </Link>
        </div>
      </div>
    </Card>
  );
};

export default EmotionalIntelligenceTab;
