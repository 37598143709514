import { Book } from "@/types/book";

export const getRecommendedBooks = (path: string): Book[] => {
  switch (path) {
    case "/money-mindset":
      return [
        {
          image: "https://m.media-amazon.com/images/I/51VDDi-+UdL._SY300_SX300_PQ10_.jpg",
          link: "https://amzn.to/3Wy9Mwm",
          title: "The High 5 Habit",
          description: "Transform your life with Mel Robbins' simple yet powerful morning routine.",
          rating: 4.5
        },
        {
          image: "https://m.media-amazon.com/images/I/51SBIsrDnCL._SY445_SX342_QL70_FMwebp_.jpg",
          link: "https://amzn.to/42tWHrB",
          title: "Never Finished",
          description: "David Goggins shares his transformative approach to achieving your full potential.",
          rating: 5
        },
        {
          image: "https://m.media-amazon.com/images/I/51TtPlXZIML._SY445_SX342_QL70_FMwebp_.jpg",
          link: "https://amzn.to/4gdiwz4",
          title: "Plan Your Year Like a Millionaire",
          description: "Strategic framework for planning and achieving financial goals.",
          rating: 4.5
        }
      ];
    case "/basic-finance":
      return [
        {
          image: "https://m.media-amazon.com/images/I/51v5VbrJtlL._SY445_SX342_PQ10_.jpg",
          link: "https://amzn.to/4jueftT",
          title: "The Infographic Guide to Personal Finance",
          description: "A visual guide to understanding personal finance concepts.",
          rating: 4.5
        },
        {
          image: "https://m.media-amazon.com/images/I/411DHhgV-HL._SY445_SX342_PQ10_.jpg",
          link: "https://amzn.to/40vXDt5",
          title: "How to Adult: Personal Finance",
          description: "Essential guide to managing money in the real world.",
          rating: 4.5
        },
        {
          image: "https://m.media-amazon.com/images/I/419Tm1NaD8L._SY445_SX342_PQ10_.jpg",
          link: "https://amzn.to/4axjXHi",
          title: "The Psychology of Money",
          description: "Timeless lessons on wealth, greed, and happiness.",
          rating: 5
        }
      ];
    case "/stock-market":
      return [
        {
          image: "https://m.media-amazon.com/images/I/41apexh1AhL._SY445_SX342_QL70_FMwebp_.jpg",
          link: "https://amzn.to/3PSVbYS",
          title: "The Intelligent Investor",
          description: "Benjamin Graham's timeless advice on value investing.",
          rating: 5
        },
        {
          image: "https://m.media-amazon.com/images/I/51j11vs7etL._SY445_SX342_QL70_FMwebp_.jpg",
          link: "https://amzn.to/3Csvtal",
          title: "The Little Book of Common Sense Investing",
          description: "The only way to guarantee your fair share of stock market returns.",
          rating: 4.5
        },
        {
          image: "https://m.media-amazon.com/images/I/51exitGKcxL._SY445_SX342_QL70_FMwebp_.jpg",
          link: "https://amzn.to/40OnSfO",
          title: "Invest Like Warren Buffett",
          description: "Learn powerful strategies for building wealth from the Oracle of Omaha.",
          rating: 4.5
        }
      ];
    case "/housing":
      return [
        {
          image: "https://m.media-amazon.com/images/I/51AHZGhzZEL._SY445_SX342_QL70_FMwebp_.jpg",
          link: "https://amzn.to/4aFLu9K",
          title: "Rich Dad Poor Dad",
          description: "Robert Kiyosaki's classic on real estate and financial education.",
          rating: 5
        },
        {
          image: "https://m.media-amazon.com/images/I/51t2A+iZHLL._SY300_SX300_PQ10_.jpg",
          link: "https://amzn.to/3WyFjOO",
          title: "The Book on Rental Property Investing",
          description: "Complete guide to successful rental property investing.",
          rating: 4.5
        },
        {
          image: "https://m.media-amazon.com/images/I/51I-PK6ZcJS._SY445_SX342_QL70_FMwebp_.jpg",
          link: "https://amzn.to/3EeAlAv",
          title: "The Book on Investing In Real Estate",
          description: "No-money-down strategies for real estate investing.",
          rating: 4.5
        }
      ];
    case "/business":
      return [
        {
          image: "https://m.media-amazon.com/images/I/51Y80DfRruL._SY445_SX342_QL70_FMwebp_.jpg",
          link: "https://amzn.to/42sQ7Se",
          title: "12 Months to $1 Million",
          description: "How to Pick a Winning Product, Build a Real Business, and Become a Seven-Figure Entrepreneur.",
          rating: 5
        },
        {
          image: "https://m.media-amazon.com/images/I/41GqbVAnxeL._SY445_SX342_QL70_FMwebp_.jpg",
          link: "https://amzn.to/40p9Pvu",
          title: "$100M Offers",
          description: "How to make offers so good people feel stupid saying no.",
          rating: 4.5
        },
        {
          image: "https://m.media-amazon.com/images/I/41stqWbysuL._SY445_SX342_QL70_FMwebp_.jpg",
          link: "https://amzn.to/40plcUo",
          title: "100M Leads",
          description: "How to Get Strangers to Want to Buy Your Stuff.",
          rating: 5
        }
      ];
    case "/side-hustles":
      return [
        {
          image: "https://m.media-amazon.com/images/I/415VwyS6r8L._SY445_SX342_QL70_FMwebp_.jpg",
          link: "https://amzn.to/40SLUWQ",
          title: "The Ultimate ChatGPT and Dall-E Side Hustle Bible",
          description: "Generate Passive Income with AI Prompts and Image Generation.",
          rating: 5
        },
        {
          image: "https://m.media-amazon.com/images/I/41-VxqrcoPL._SY445_SX342_QL70_FMwebp_.jpg",
          link: "https://amzn.to/42z9rh0",
          title: "100 Side Hustles",
          description: "Unexpected ideas for making extra money without quitting your day job.",
          rating: 4.5
        },
        {
          image: "https://m.media-amazon.com/images/I/51o50HLLvoL._SY445_SX342_QL70_FMwebp_.jpg",
          link: "https://amzn.to/3Cid4Nz",
          title: "Side Hustle",
          description: "Build a side business and make extra money - without quitting your day job.",
          rating: 5
        }
      ];
    default:
      return [];
  }
};
