import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";

export const CustomerAcquisitionCostCalculator = () => {
  const [marketingCosts, setMarketingCosts] = useState("");
  const [salesCosts, setSalesCosts] = useState("");
  const [newCustomers, setNewCustomers] = useState("");
  const [result, setResult] = useState<number | null>(null);
  const { toast } = useToast();

  const calculateCAC = () => {
    const marketing = parseFloat(marketingCosts);
    const sales = parseFloat(salesCosts);
    const customers = parseFloat(newCustomers);

    if (isNaN(marketing) || isNaN(sales) || isNaN(customers) || customers === 0) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    const cac = (marketing + sales) / customers;
    setResult(cac);
  };

  return (
    <Card className="p-6">
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">Customer Acquisition Cost (CAC) Calculator</h3>
        <p className="text-gray-600">
          Calculate how much it costs to acquire a new customer by factoring in marketing and sales expenses.
        </p>
      </div>

      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="marketing-costs">Total Marketing Costs ($)</Label>
          <Input
            id="marketing-costs"
            type="number"
            placeholder="5000"
            value={marketingCosts}
            onChange={(e) => setMarketingCosts(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="sales-costs">Total Sales Costs ($)</Label>
          <Input
            id="sales-costs"
            type="number"
            placeholder="3000"
            value={salesCosts}
            onChange={(e) => setSalesCosts(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="new-customers">Number of New Customers</Label>
          <Input
            id="new-customers"
            type="number"
            placeholder="100"
            value={newCustomers}
            onChange={(e) => setNewCustomers(e.target.value)}
          />
        </div>

        <Button className="w-full" onClick={calculateCAC}>
          Calculate CAC
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg">
            <p className="text-center text-lg font-semibold">
              Customer Acquisition Cost:
              <span className="block text-2xl text-primary">
                ${result.toFixed(2)}
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};