import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { CalculatorProps } from "./types";

export const MarginCalculator = ({ onCalculate }: CalculatorProps) => {
  const [accountValue, setAccountValue] = useState("");
  const [marginRequirement, setMarginRequirement] = useState("");
  const [leverage, setLeverage] = useState("");
  const [result, setResult] = useState<{
    buyingPower: number;
    maxPosition: number;
    maintenanceMargin: number;
  } | null>(null);

  const calculateMargin = () => {
    const value = parseFloat(accountValue);
    const requirement = parseFloat(marginRequirement) / 100;
    const leverageRatio = parseFloat(leverage);

    if (value && requirement && leverageRatio) {
      const buyingPower = value * leverageRatio;
      const maxPosition = buyingPower;
      const maintenanceMargin = maxPosition * requirement;

      setResult({
        buyingPower,
        maxPosition,
        maintenanceMargin,
      });
      onCalculate?.(buyingPower);
    }
  };

  return (
    <div className="space-y-4">
      <div className="grid gap-4">
        <div>
          <Label htmlFor="accountValue">Account Value ($)</Label>
          <Input
            id="accountValue"
            type="number"
            value={accountValue}
            onChange={(e) => setAccountValue(e.target.value)}
            placeholder="Enter account value"
          />
        </div>
        <div>
          <Label htmlFor="marginRequirement">Margin Requirement (%)</Label>
          <Input
            id="marginRequirement"
            type="number"
            value={marginRequirement}
            onChange={(e) => setMarginRequirement(e.target.value)}
            placeholder="Enter margin requirement"
          />
        </div>
        <div>
          <Label htmlFor="leverage">Leverage Ratio</Label>
          <Input
            id="leverage"
            type="number"
            value={leverage}
            onChange={(e) => setLeverage(e.target.value)}
            placeholder="Enter leverage ratio (e.g., 2 for 2:1)"
          />
        </div>
      </div>
      <Button onClick={calculateMargin} className="w-full">
        Calculate Margin
      </Button>
      {result && (
        <div className="mt-4 p-4 bg-accent/10 rounded-lg space-y-2">
          <p className="font-semibold">
            Buying Power: ${result.buyingPower.toFixed(2)}
          </p>
          <p className="font-semibold">
            Maximum Position Size: ${result.maxPosition.toFixed(2)}
          </p>
          <p className="font-semibold">
            Maintenance Margin: ${result.maintenanceMargin.toFixed(2)}
          </p>
        </div>
      )}
    </div>
  );
};