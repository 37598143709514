// This file is automatically generated. Do not edit it directly.
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://zoctzdgmvnjqtuuyszng.supabase.co";
const SUPABASE_PUBLISHABLE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InpvY3R6ZGdtdm5qcXR1dXlzem5nIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzgxMTU4NTcsImV4cCI6MjA1MzY5MTg1N30.kFH_B70T59UTvdDUaJmn15OJK-5lXzSZHbQcQGClRS0";

// Import the supabase client like this:
// import { supabase } from "@/integrations/supabase/client";

export const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_PUBLISHABLE_KEY);