import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";

export const RoiCalculator = () => {
  const [initialInvestment, setInitialInvestment] = useState("");
  const [finalValue, setFinalValue] = useState("");
  const [timeframe, setTimeframe] = useState("");
  const [result, setResult] = useState<{ roi: number; annualized: number } | null>(null);
  const { toast } = useToast();

  const calculateROI = () => {
    const investment = parseFloat(initialInvestment);
    const value = parseFloat(finalValue);
    const years = parseFloat(timeframe);

    if (isNaN(investment) || isNaN(value) || isNaN(years)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    const roi = ((value - investment) / investment) * 100;
    const annualizedROI = (Math.pow((value / investment), 1/years) - 1) * 100;

    setResult({
      roi: roi,
      annualized: annualizedROI
    });
    console.log("Calculated ROI:", { roi, annualizedROI });
  };

  return (
    <Card className="p-6">
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">ROI Calculator</h3>
        <p className="text-gray-600">
          Calculate the return on investment (ROI) and annualized ROI for your business investments.
        </p>
      </div>

      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="initial-investment">Initial Investment ($)</Label>
          <Input
            id="initial-investment"
            type="number"
            placeholder="10000"
            value={initialInvestment}
            onChange={(e) => setInitialInvestment(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="final-value">Final Value ($)</Label>
          <Input
            id="final-value"
            type="number"
            placeholder="15000"
            value={finalValue}
            onChange={(e) => setFinalValue(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="timeframe">Time Period (Years)</Label>
          <Input
            id="timeframe"
            type="number"
            placeholder="2"
            value={timeframe}
            onChange={(e) => setTimeframe(e.target.value)}
          />
        </div>

        <Button 
          className="w-full"
          onClick={calculateROI}
        >
          Calculate ROI
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg space-y-2">
            <p className="text-center font-semibold">
              Total ROI:
              <span className={`block text-2xl ${result.roi >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                {result.roi.toFixed(2)}%
              </span>
            </p>
            <p className="text-center font-semibold">
              Annualized ROI:
              <span className={`block text-2xl ${result.annualized >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                {result.annualized.toFixed(2)}%
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};