import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { CalculatorProps } from "./types";

export const OptionsCalculator = ({ onCalculate }: CalculatorProps) => {
  const [optionPrice, setOptionPrice] = useState("");
  const [contracts, setContracts] = useState("");
  const [exitPrice, setExitPrice] = useState("");
  const [result, setResult] = useState<{
    profit: number;
    percentageReturn: number;
  } | null>(null);

  const calculateProfit = () => {
    const price = parseFloat(optionPrice);
    const numContracts = parseFloat(contracts);
    const exit = parseFloat(exitPrice);

    if (price && numContracts && exit) {
      const contractMultiplier = 100; // Standard options contract = 100 shares
      const initialInvestment = price * numContracts * contractMultiplier;
      const finalValue = exit * numContracts * contractMultiplier;
      const profit = finalValue - initialInvestment;
      const percentageReturn = (profit / initialInvestment) * 100;

      setResult({ profit, percentageReturn });
      onCalculate?.(profit);
    }
  };

  return (
    <div className="space-y-4">
      <div className="grid gap-4">
        <div>
          <Label htmlFor="optionPrice">Option Price ($)</Label>
          <Input
            id="optionPrice"
            type="number"
            value={optionPrice}
            onChange={(e) => setOptionPrice(e.target.value)}
            placeholder="Enter option price"
          />
        </div>
        <div>
          <Label htmlFor="contracts">Number of Contracts</Label>
          <Input
            id="contracts"
            type="number"
            value={contracts}
            onChange={(e) => setContracts(e.target.value)}
            placeholder="Enter number of contracts"
          />
        </div>
        <div>
          <Label htmlFor="exitPrice">Exit Price ($)</Label>
          <Input
            id="exitPrice"
            type="number"
            value={exitPrice}
            onChange={(e) => setExitPrice(e.target.value)}
            placeholder="Enter exit price"
          />
        </div>
      </div>
      <Button onClick={calculateProfit} className="w-full">
        Calculate Options Profit/Loss
      </Button>
      {result && (
        <div className="mt-4 p-4 bg-accent/10 rounded-lg space-y-2">
          <p className="font-semibold">
            Profit/Loss: ${result.profit.toFixed(2)}
          </p>
          <p className="font-semibold">
            Return: {result.percentageReturn.toFixed(2)}%
          </p>
        </div>
      )}
    </div>
  );
};