import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";

export const BreakEvenCalculator = () => {
  const [fixedCosts, setFixedCosts] = useState("");
  const [variableCosts, setVariableCosts] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [result, setResult] = useState<number | null>(null);
  const { toast } = useToast();

  const calculateBreakEven = () => {
    const fixed = parseFloat(fixedCosts);
    const variable = parseFloat(variableCosts);
    const price = parseFloat(sellingPrice);

    if (isNaN(fixed) || isNaN(variable) || isNaN(price)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    if (price <= variable) {
      toast({
        title: "Invalid Input",
        description: "Selling price must be greater than variable costs",
        variant: "destructive",
      });
      return;
    }

    const breakEvenUnits = fixed / (price - variable);
    setResult(breakEvenUnits);
    console.log("Calculated break-even point:", breakEvenUnits);
  };

  return (
    <Card className="p-6">
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">Break-Even Calculator</h3>
        <p className="text-gray-600">
          Calculate how many units you need to sell to cover all costs and start making a profit.
        </p>
      </div>

      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="fixed-costs">Fixed Costs ($)</Label>
          <Input
            id="fixed-costs"
            type="number"
            placeholder="10000"
            value={fixedCosts}
            onChange={(e) => setFixedCosts(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="variable-costs">Variable Cost per Unit ($)</Label>
          <Input
            id="variable-costs"
            type="number"
            placeholder="5"
            value={variableCosts}
            onChange={(e) => setVariableCosts(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="selling-price">Selling Price per Unit ($)</Label>
          <Input
            id="selling-price"
            type="number"
            placeholder="10"
            value={sellingPrice}
            onChange={(e) => setSellingPrice(e.target.value)}
          />
        </div>

        <Button 
          className="w-full"
          onClick={calculateBreakEven}
        >
          Calculate Break-Even Point
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg">
            <p className="text-center text-lg font-semibold">
              Break-Even Point:
              <span className="block text-2xl text-primary">
                {Math.ceil(result).toLocaleString()} units
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};