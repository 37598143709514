import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { CalculatorProps } from "./types";

export const RiskRewardCalculator = ({ onCalculate }: CalculatorProps) => {
  const [entryPrice, setEntryPrice] = useState("");
  const [targetPrice, setTargetPrice] = useState("");
  const [stopLoss, setStopLoss] = useState("");
  const [result, setResult] = useState<number | null>(null);

  const calculateRatio = () => {
    const entry = parseFloat(entryPrice);
    const target = parseFloat(targetPrice);
    const stop = parseFloat(stopLoss);

    if (!isNaN(entry) && !isNaN(target) && !isNaN(stop) && entry !== stop) {
      const potentialReward = Math.abs(target - entry);
      const potentialRisk = Math.abs(entry - stop);
      const ratio = potentialReward / potentialRisk;
      setResult(ratio);
      if (onCalculate) onCalculate(ratio);
    }
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="entryPrice">Entry Price ($)</Label>
          <Input
            id="entryPrice"
            type="number"
            min="0"
            step="0.01"
            value={entryPrice}
            onChange={(e) => setEntryPrice(e.target.value)}
            placeholder="Enter entry price"
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="targetPrice">Target Price ($)</Label>
          <Input
            id="targetPrice"
            type="number"
            min="0"
            step="0.01"
            value={targetPrice}
            onChange={(e) => setTargetPrice(e.target.value)}
            placeholder="Enter target price"
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="stopLoss">Stop Loss Price ($)</Label>
          <Input
            id="stopLoss"
            type="number"
            min="0"
            step="0.01"
            value={stopLoss}
            onChange={(e) => setStopLoss(e.target.value)}
            placeholder="Enter stop loss price"
          />
        </div>

        <Button onClick={calculateRatio} className="w-full">
          Calculate Risk/Reward Ratio
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-muted rounded-lg">
            <p className="text-center font-semibold">
              Risk/Reward Ratio: {result.toFixed(2)}:1
            </p>
            <p className="text-sm text-center text-muted-foreground mt-2">
              {result >= 2 
                ? "✅ Good potential risk/reward ratio (2:1 or better)"
                : "⚠️ Consider if the risk/reward ratio meets your strategy requirements"}
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};