import ContentSection from "@/components/ContentSection";

export const DiversificationSection = () => {
  return (
    <ContentSection title="Diversification Strategies">
      <p className="mb-4">
        Protect and grow your wealth through smart diversification:
      </p>
      <ul className="list-disc pl-6 mb-6">
        <li className="mb-2">Asset Class Diversification</li>
        <li className="mb-2">Geographic Diversification</li>
        <li className="mb-2">Sector Diversification</li>
        <li>Investment Vehicle Diversification</li>
      </ul>
    </ContentSection>
  );
};