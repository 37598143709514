import { Link, useLocation } from "react-router-dom";
import { Home, Brain, DollarSign, TrendingUp, House, Briefcase, Wallet, Menu, BookOpen, Mail } from "lucide-react";
import { useState } from "react";
import { MailingListModal } from "./MailingListModal";

const Navigation = () => {
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMailingListOpen, setIsMailingListOpen] = useState(false);
  
  const isActive = (path: string) => location.pathname === path;
  
  const navigationItems = [
    { path: "/money-mindset", icon: Brain, label: "Money Mindset" },
    { path: "/basic-finance", icon: DollarSign, label: "Basic Finance" },
    { path: "/stock-market", icon: TrendingUp, label: "Stock Market" },
    { path: "/housing", icon: House, label: "Housing" },
    { path: "/business", icon: Briefcase, label: "Business" },
    { path: "/side-hustles", icon: Wallet, label: "Side Hustles" },
    { path: "/blog", icon: BookOpen, label: "Blog" },
  ];
  
  return (
    <nav className="bg-white shadow-lg border-b sticky top-0 z-50">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          {/* Logo and Brand */}
          <Link 
            to="/" 
            className="flex items-center gap-2 text-primary hover:text-primary/90 transition-colors font-semibold whitespace-nowrap"
          >
            <Home className="h-5 w-5" />
            <span className="text-lg">Centsable Money</span>
          </Link>
          
          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-1 flex-grow justify-start ml-8">
            {navigationItems.map((item) => {
              const Icon = item.icon;
              return (
                <Link
                  key={item.path}
                  to={item.path}
                  className={`flex items-center gap-1.5 px-3 py-1.5 rounded-md text-sm transition-all duration-200 whitespace-nowrap ${
                    isActive(item.path)
                      ? "text-primary bg-primary/10 font-medium"
                      : "text-gray-600 hover:text-primary hover:bg-gray-50"
                  }`}
                >
                  <Icon className="h-4 w-4" />
                  <span>{item.label}</span>
                </Link>
              );
            })}
            <button
              onClick={() => setIsMailingListOpen(true)}
              className="flex items-center gap-1.5 px-3 py-1.5 rounded-md text-sm bg-primary text-white hover:bg-primary/90 transition-colors ml-2 whitespace-nowrap"
            >
              <Mail className="h-4 w-4" />
              <span>Subscribe</span>
            </button>
          </div>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden p-2 rounded-md hover:bg-gray-100"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            aria-label="Toggle menu"
          >
            <Menu className="h-6 w-6 text-gray-600" />
          </button>
        </div>

        {/* Mobile Navigation */}
        {isMobileMenuOpen && (
          <div className="md:hidden py-4 space-y-2 animate-fadeIn border-t">
            {navigationItems.map((item) => {
              const Icon = item.icon;
              return (
                <Link
                  key={item.path}
                  to={item.path}
                  className={`flex items-center gap-2 p-3 rounded-md ${
                    isActive(item.path)
                      ? "text-primary bg-primary/10 font-medium"
                      : "text-gray-600 hover:text-primary hover:bg-gray-50"
                  }`}
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  <Icon className="h-5 w-5" />
                  <span>{item.label}</span>
                </Link>
              );
            })}
            <button
              onClick={() => {
                setIsMailingListOpen(true);
                setIsMobileMenuOpen(false);
              }}
              className="flex items-center gap-2 p-3 w-full text-left text-white bg-primary hover:bg-primary/90 rounded-md transition-colors"
            >
              <Mail className="h-5 w-5" />
              <span>Subscribe to Newsletter</span>
            </button>
          </div>
        )}
      </div>

      <MailingListModal
        isOpen={isMailingListOpen}
        onClose={() => setIsMailingListOpen(false)}
      />
    </nav>
  );
};

export default Navigation;