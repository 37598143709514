import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";

export const LoanRepaymentCalculator = () => {
  const [loanAmount, setLoanAmount] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [loanTerm, setLoanTerm] = useState("");
  const [result, setResult] = useState<{ monthly: number; total: number } | null>(null);
  const { toast } = useToast();

  const calculateLoanRepayment = () => {
    const principal = parseFloat(loanAmount);
    const rate = parseFloat(interestRate) / 100 / 12; // Monthly interest rate
    const months = parseFloat(loanTerm) * 12; // Convert years to months

    if (isNaN(principal) || isNaN(rate) || isNaN(months)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    // Monthly payment formula: P * (r * (1 + r)^n) / ((1 + r)^n - 1)
    const monthlyPayment = principal * (rate * Math.pow(1 + rate, months)) / (Math.pow(1 + rate, months) - 1);
    const totalPayment = monthlyPayment * months;

    setResult({
      monthly: monthlyPayment,
      total: totalPayment
    });
    console.log("Calculated loan repayment:", { monthly: monthlyPayment, total: totalPayment });
  };

  return (
    <Card className="p-6">
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">Loan Repayment Calculator</h3>
        <p className="text-gray-600">
          Calculate your monthly loan payments and total interest based on loan amount,
          interest rate, and term length.
        </p>
      </div>

      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="loan-amount">Loan Amount ($)</Label>
          <Input
            id="loan-amount"
            type="number"
            placeholder="50000"
            value={loanAmount}
            onChange={(e) => setLoanAmount(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="interest-rate">Annual Interest Rate (%)</Label>
          <Input
            id="interest-rate"
            type="number"
            placeholder="5"
            value={interestRate}
            onChange={(e) => setInterestRate(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="loan-term">Loan Term (Years)</Label>
          <Input
            id="loan-term"
            type="number"
            placeholder="5"
            value={loanTerm}
            onChange={(e) => setLoanTerm(e.target.value)}
          />
        </div>

        <Button 
          className="w-full"
          onClick={calculateLoanRepayment}
        >
          Calculate Loan Payments
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg space-y-2">
            <p className="text-center font-semibold">
              Monthly Payment:
              <span className="block text-2xl text-primary">
                ${result.monthly.toFixed(2)}
              </span>
            </p>
            <p className="text-center font-semibold">
              Total Payment:
              <span className="block text-2xl text-primary">
                ${result.total.toFixed(2)}
              </span>
            </p>
            <p className="text-center text-sm text-gray-600">
              Total Interest: ${(result.total - parseFloat(loanAmount)).toFixed(2)}
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};