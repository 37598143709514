import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Textarea } from "@/components/ui/textarea";

export const VolatilityCalculator = () => {
  const [prices, setPrices] = useState("");
  const [result, setResult] = useState<number | null>(null);

  const calculateVolatility = () => {
    const priceList = prices
      .split("\n")
      .map(p => parseFloat(p.trim()))
      .filter(p => !isNaN(p));

    if (priceList.length < 2) {
      console.error("Need at least 2 prices to calculate volatility");
      return;
    }

    // Calculate returns
    const returns = [];
    for (let i = 1; i < priceList.length; i++) {
      returns.push(Math.log(priceList[i] / priceList[i - 1]));
    }

    // Calculate standard deviation
    const mean = returns.reduce((a, b) => a + b) / returns.length;
    const variance = returns.reduce((a, b) => a + Math.pow(b - mean, 2), 0) / (returns.length - 1);
    const stdDev = Math.sqrt(variance);
    
    // Annualize volatility (assuming daily prices)
    const annualizedVol = stdDev * Math.sqrt(252) * 100;
    setResult(annualizedVol);
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium mb-1">
            Historical Prices (one per line)
          </label>
          <Textarea
            value={prices}
            onChange={(e) => setPrices(e.target.value)}
            placeholder="Enter historical prices, one per line"
            rows={6}
          />
        </div>
        <Button onClick={calculateVolatility} className="w-full">
          Calculate Volatility
        </Button>
        {result !== null && (
          <div className="mt-4 p-4 bg-muted rounded-lg">
            <p className="text-sm font-medium">Annual Volatility:</p>
            <p className="text-2xl font-bold">{result.toFixed(2)}%</p>
          </div>
        )}
      </div>
    </Card>
  );
};