import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";

export const AlphaCalculator = () => {
  const [actualReturn, setActualReturn] = useState("");
  const [expectedReturn, setExpectedReturn] = useState("");
  const [result, setResult] = useState<number | null>(null);
  const { toast } = useToast();

  const calculateAlpha = () => {
    try {
      const actual = parseFloat(actualReturn);
      const expected = parseFloat(expectedReturn);

      if (isNaN(actual) || isNaN(expected)) {
        toast({
          title: "Invalid Input",
          description: "Please enter valid numbers",
          variant: "destructive",
        });
        return;
      }

      const alpha = actual - expected;
      setResult(alpha);
      console.log("Calculated Alpha:", alpha);

    } catch (error) {
      toast({
        title: "Calculation Error",
        description: "An error occurred during calculation",
        variant: "destructive",
      });
    }
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="actual-return">Actual Return (%)</Label>
          <Input
            id="actual-return"
            type="number"
            placeholder="12"
            value={actualReturn}
            onChange={(e) => setActualReturn(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="expected-return">Expected Return (%)</Label>
          <Input
            id="expected-return"
            type="number"
            placeholder="10"
            value={expectedReturn}
            onChange={(e) => setExpectedReturn(e.target.value)}
          />
        </div>

        <Button 
          className="w-full"
          onClick={calculateAlpha}
        >
          Calculate Alpha
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg">
            <p className="text-center text-lg font-semibold">
              Alpha:
              <span className="block text-2xl text-primary">
                {result.toFixed(2)}%
              </span>
              <span className="block text-sm text-gray-600 mt-2">
                {result > 0 ? "Outperforming the benchmark" :
                 result < 0 ? "Underperforming the benchmark" :
                 "Performing as expected"}
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};