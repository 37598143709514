import { 
  Calculator, 
  DollarSign, 
  TrendingUp, 
  Percent,
  ChartBar,
  BarChart,
  Activity,
  LineChart,
  PieChart,
  Target,
  TrendingDown,
  Scale,
  ArrowUpDown,
  Divide,
  Timer,
  Wallet,
  Banknote,
  BarChart2,
  ArrowRightLeft,
  Sigma,
  CircleDollarSign,
  Landmark,
  Clock,
  BarChart3,
  GitCompare,
  BadgeDollarSign,
  Coins,
  ArrowDownUp,
  Gauge
} from "lucide-react";
import { CalculatorInfo } from "./types";

export const calculatorConfigs: CalculatorInfo[] = [
  { 
    id: "stock-return", 
    label: "Stock Return", 
    icon: TrendingUp,
    description: "Calculate total return including capital gains and dividends"
  },
  { 
    id: "dividend-yield", 
    label: "Dividend Yield", 
    icon: Percent,
    description: "Calculate dividend yield based on annual dividends and stock price"
  },
  { 
    id: "pe-ratio", 
    label: "P/E Ratio", 
    icon: Calculator,
    description: "Calculate Price-to-Earnings ratio for stock valuation"
  },
  { 
    id: "eps", 
    label: "EPS", 
    icon: DollarSign,
    description: "Calculate Earnings Per Share"
  },
  { 
    id: "cagr", 
    label: "CAGR", 
    icon: ChartBar,
    description: "Calculate Compound Annual Growth Rate"
  },
  { 
    id: "future-value", 
    label: "Future Value", 
    icon: BarChart,
    description: "Project future investment value"
  },
  { 
    id: "stock-profit", 
    label: "Stock Profit", 
    icon: LineChart,
    description: "Calculate profit/loss from stock trades including total return and percentage gain/loss"
  },
  { 
    id: "portfolio-diversification", 
    label: "Portfolio Diversification", 
    icon: PieChart,
    description: "Analyze and visualize your portfolio's asset allocation across different investment types"
  },
  { 
    id: "risk-reward", 
    label: "Risk-Reward Ratio", 
    icon: Target,
    description: "Calculate and evaluate the risk-reward ratio for potential trades"
  },
  { 
    id: "beta", 
    label: "Beta", 
    icon: TrendingDown,
    description: "Calculate stock's volatility vs market"
  },
  { 
    id: "sharpe-ratio", 
    label: "Sharpe Ratio", 
    icon: Scale,
    description: "Calculate risk-adjusted returns"
  },
  { 
    id: "alpha", 
    label: "Alpha", 
    icon: ArrowUpDown,
    description: "Calculate excess returns vs benchmark"
  },
  { 
    id: "intrinsic-value", 
    label: "Intrinsic Value", 
    icon: Divide,
    description: "Calculate stock's fair value"
  },
  { 
    id: "dividend-reinvestment", 
    label: "Dividend Reinvestment", 
    icon: Timer,
    description: "Project growth with reinvested dividends"
  },
  { 
    id: "stock-split", 
    label: "Stock Split", 
    icon: Wallet,
    description: "Calculate post-split shares and price"
  },
  { 
    id: "capital-gains-tax", 
    label: "Capital Gains Tax", 
    icon: Banknote,
    description: "Estimate taxes on stock profits"
  },
  { 
    id: "position-sizing", 
    label: "Position Sizing", 
    icon: BarChart2,
    description: "Calculate position size based on risk"
  },
  { 
    id: "stop-loss", 
    label: "Stop Loss/Take Profit", 
    icon: ArrowRightLeft,
    description: "Calculate stop loss and take profit levels"
  },
  { 
    id: "options-profit", 
    label: "Options Profit", 
    icon: Sigma,
    description: "Calculate options trade profit/loss"
  },
  { 
    id: "margin", 
    label: "Margin", 
    icon: CircleDollarSign,
    description: "Calculate margin requirements"
  },
  { 
    id: "ddm", 
    label: "DDM", 
    icon: Calculator,
    description: "Calculate stock value using Dividend Discount Model"
  },
  { 
    id: "wacc", 
    label: "WACC", 
    icon: Percent,
    description: "Calculate Weighted Average Cost of Capital"
  },
  { 
    id: "payback-period", 
    label: "Payback Period", 
    icon: Timer,
    description: "Calculate investment recovery time"
  },
  { 
    id: "volatility", 
    label: "Volatility", 
    icon: Activity,
    description: "Calculate stock price volatility"
  }
];
