import ContentSection from "@/components/ContentSection";

export const InvestmentStrategiesSection = () => {
  return (
    <ContentSection title="Investment Strategies">
      <h3 className="text-xl font-semibold mb-4">Core Investment Approaches</h3>
      <div className="space-y-6">
        <div>
          <h4 className="text-lg font-semibold mb-2">1. Value Investing</h4>
          <p className="mb-3">
            Look for undervalued companies trading below their intrinsic value:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Analyze financial statements and ratios</li>
            <li>Look for companies with strong fundamentals</li>
            <li>Focus on long-term potential over short-term gains</li>
            <li>Consider margin of safety in valuations</li>
          </ul>
        </div>

        <div>
          <h4 className="text-lg font-semibold mb-2">2. Growth Investing</h4>
          <p className="mb-3">
            Focus on companies with high growth potential:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Look for companies in expanding markets</li>
            <li>Evaluate revenue and earnings growth rates</li>
            <li>Consider competitive advantages</li>
            <li>Monitor industry trends and innovation</li>
          </ul>
        </div>

        <div>
          <h4 className="text-lg font-semibold mb-2">3. Dividend Investing</h4>
          <p className="mb-3">
            Build income through dividend-paying stocks:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Focus on dividend yield and growth history</li>
            <li>Look for sustainable payout ratios</li>
            <li>Consider dividend aristocrats</li>
            <li>Reinvest dividends for compound growth</li>
          </ul>
        </div>
      </div>
    </ContentSection>
  );
};