import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { CalculatorProps } from "./types";

export const StopLossTakeProfitCalculator = ({ onCalculate }: CalculatorProps) => {
  const [entryPrice, setEntryPrice] = useState("");
  const [riskPercentage, setRiskPercentage] = useState("");
  const [rewardRatio, setRewardRatio] = useState("");
  const [result, setResult] = useState<{
    stopLoss: number;
    takeProfit: number;
  } | null>(null);

  const calculateLevels = () => {
    const entry = parseFloat(entryPrice);
    const risk = parseFloat(riskPercentage) / 100;
    const reward = parseFloat(rewardRatio);

    if (entry && risk && reward) {
      const stopLoss = entry * (1 - risk);
      const riskAmount = entry - stopLoss;
      const takeProfit = entry + (riskAmount * reward);
      
      setResult({ stopLoss, takeProfit });
      onCalculate?.(takeProfit - entry);
    }
  };

  return (
    <div className="space-y-4">
      <div className="grid gap-4">
        <div>
          <Label htmlFor="entryPrice">Entry Price ($)</Label>
          <Input
            id="entryPrice"
            type="number"
            value={entryPrice}
            onChange={(e) => setEntryPrice(e.target.value)}
            placeholder="Enter entry price"
          />
        </div>
        <div>
          <Label htmlFor="riskPercentage">Risk Percentage (%)</Label>
          <Input
            id="riskPercentage"
            type="number"
            value={riskPercentage}
            onChange={(e) => setRiskPercentage(e.target.value)}
            placeholder="Enter risk percentage"
          />
        </div>
        <div>
          <Label htmlFor="rewardRatio">Risk/Reward Ratio</Label>
          <Input
            id="rewardRatio"
            type="number"
            value={rewardRatio}
            onChange={(e) => setRewardRatio(e.target.value)}
            placeholder="Enter risk/reward ratio"
          />
        </div>
      </div>
      <Button onClick={calculateLevels} className="w-full">
        Calculate Levels
      </Button>
      {result && (
        <div className="mt-4 p-4 bg-accent/10 rounded-lg space-y-2">
          <p className="font-semibold">
            Stop Loss: ${result.stopLoss.toFixed(2)}
          </p>
          <p className="font-semibold">
            Take Profit: ${result.takeProfit.toFixed(2)}
          </p>
        </div>
      )}
    </div>
  );
};