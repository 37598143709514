import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { RssIcon } from "lucide-react";

export const RssFeedSubscription = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  const handleSubscribe = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const { error } = await supabase
        .from('rss_subscriptions')
        .insert([{ email }]);

      if (error) throw error;

      toast({
        title: "Successfully subscribed!",
        description: "You'll receive updates when new content is published.",
      });
      setEmail("");
    } catch (error) {
      console.error('Error subscribing to RSS feed:', error);
      toast({
        title: "Subscription failed",
        description: "Please try again later.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full max-w-md mx-auto p-6 bg-white/80 backdrop-blur-sm rounded-xl shadow-lg">
      <div className="flex items-center gap-2 mb-4">
        <RssIcon className="w-5 h-5 text-orange-500" />
        <h3 className="text-lg font-semibold">Subscribe to Our RSS Feed</h3>
      </div>
      <p className="text-sm text-gray-600 mb-4">
        Stay updated with our latest financial insights and tips.
      </p>
      <form onSubmit={handleSubscribe} className="space-y-4">
        <Input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="w-full"
        />
        <Button 
          type="submit" 
          className="w-full bg-gradient-to-r from-orange-500 to-pink-500 hover:from-orange-600 hover:to-pink-600 text-white"
          disabled={isLoading}
        >
          {isLoading ? "Subscribing..." : "Subscribe"}
        </Button>
      </form>
      <div className="mt-4 text-center">
        <a
          href="/rss.xml"
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm text-gray-600 hover:text-orange-500 inline-flex items-center gap-1"
        >
          <RssIcon className="w-4 h-4" />
          RSS Feed
        </a>
      </div>
    </div>
  );
};