import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { DollarSign } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";

export const ProfitPotentialCalculator = () => {
  const [revenue, setRevenue] = useState("");
  const [expenses, setExpenses] = useState("");
  const [taxRate, setTaxRate] = useState("");
  const [result, setResult] = useState<{
    weeklyProfit: number;
    monthlyProfit: number;
    yearlyProfit: number;
    taxAmount: number;
  } | null>(null);
  const { toast } = useToast();

  const calculateProfit = () => {
    const monthlyRevenue = parseFloat(revenue);
    const monthlyExpenses = parseFloat(expenses);
    const tax = parseFloat(taxRate) / 100;

    if (isNaN(monthlyRevenue) || isNaN(monthlyExpenses) || isNaN(tax)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    const monthlyProfit = monthlyRevenue - monthlyExpenses;
    const yearlyProfit = monthlyProfit * 12;
    const weeklyProfit = monthlyProfit / 4;
    const taxAmount = yearlyProfit * tax;

    setResult({ weeklyProfit, monthlyProfit, yearlyProfit, taxAmount });
    console.log("Calculated profit potential:", { weeklyProfit, monthlyProfit, yearlyProfit, taxAmount });
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="revenue">Monthly Revenue</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="revenue"
              type="number"
              placeholder="5000"
              className="pl-10"
              value={revenue}
              onChange={(e) => setRevenue(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="expenses">Monthly Expenses</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="expenses"
              type="number"
              placeholder="2000"
              className="pl-10"
              value={expenses}
              onChange={(e) => setExpenses(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="tax">Tax Rate (%)</Label>
          <Input
            id="tax"
            type="number"
            placeholder="25"
            value={taxRate}
            onChange={(e) => setTaxRate(e.target.value)}
          />
        </div>

        <Button 
          className="w-full"
          onClick={calculateProfit}
        >
          Calculate Profit
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg space-y-2">
            <p className="text-center">
              <span className="font-semibold">Weekly Profit:</span>
              <span className="block text-xl text-primary">
                ${result.weeklyProfit.toLocaleString()}
              </span>
            </p>
            <p className="text-center">
              <span className="font-semibold">Monthly Profit:</span>
              <span className="block text-xl text-primary">
                ${result.monthlyProfit.toLocaleString()}
              </span>
            </p>
            <p className="text-center">
              <span className="font-semibold">Yearly Profit:</span>
              <span className="block text-xl text-primary">
                ${result.yearlyProfit.toLocaleString()}
              </span>
            </p>
            <p className="text-center">
              <span className="font-semibold">Estimated Tax:</span>
              <span className="block text-xl text-primary">
                ${result.taxAmount.toLocaleString()}
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};