import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";

export const ShippingCostCalculator = () => {
  const [weight, setWeight] = useState("");
  const [distance, setDistance] = useState("");
  const [baseRate, setBaseRate] = useState("");
  const [result, setResult] = useState<number | null>(null);
  const { toast } = useToast();

  const calculateShipping = () => {
    const weightLbs = parseFloat(weight);
    const distanceMiles = parseFloat(distance);
    const rate = parseFloat(baseRate);

    if (isNaN(weightLbs) || isNaN(distanceMiles) || isNaN(rate)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    const cost = (weightLbs * rate) + (distanceMiles * 0.1);
    setResult(cost);
  };

  return (
    <Card className="p-6">
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">Shipping Cost Calculator</h3>
        <p className="text-gray-600">
          Estimate shipping costs based on weight, distance, and base rate.
        </p>
      </div>

      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="weight">Package Weight (lbs)</Label>
          <Input
            id="weight"
            type="number"
            placeholder="5"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="distance">Shipping Distance (miles)</Label>
          <Input
            id="distance"
            type="number"
            placeholder="500"
            value={distance}
            onChange={(e) => setDistance(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="base-rate">Base Rate per lb ($)</Label>
          <Input
            id="base-rate"
            type="number"
            placeholder="2"
            value={baseRate}
            onChange={(e) => setBaseRate(e.target.value)}
          />
        </div>

        <Button className="w-full" onClick={calculateShipping}>
          Calculate Shipping Cost
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg">
            <p className="text-center text-lg font-semibold">
              Estimated Shipping Cost:
              <span className="block text-2xl text-primary">
                ${result.toFixed(2)}
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};