import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { DollarSign } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";

export const StockReturnCalculator = () => {
  const [initialPrice, setInitialPrice] = useState("");
  const [finalPrice, setFinalPrice] = useState("");
  const [dividends, setDividends] = useState("");
  const [result, setResult] = useState<number | null>(null);
  const { toast } = useToast();

  const calculateStockReturn = () => {
    const initial = parseFloat(initialPrice);
    const final = parseFloat(finalPrice);
    const totalDividends = parseFloat(dividends) || 0;

    if (isNaN(initial) || isNaN(final)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers for prices",
        variant: "destructive",
      });
      return;
    }

    const capitalGain = final - initial;
    const totalReturn = capitalGain + totalDividends;
    const returnPercentage = (totalReturn / initial) * 100;

    setResult(returnPercentage);
    console.log("Calculated stock return:", returnPercentage);
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="initial-price">Initial Stock Price</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="initial-price"
              type="number"
              placeholder="100"
              className="pl-10"
              value={initialPrice}
              onChange={(e) => setInitialPrice(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="final-price">Final Stock Price</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="final-price"
              type="number"
              placeholder="150"
              className="pl-10"
              value={finalPrice}
              onChange={(e) => setFinalPrice(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="dividends">Total Dividends Received</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="dividends"
              type="number"
              placeholder="5"
              className="pl-10"
              value={dividends}
              onChange={(e) => setDividends(e.target.value)}
            />
          </div>
        </div>

        <Button 
          className="w-full"
          onClick={calculateStockReturn}
        >
          Calculate Total Return
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg">
            <p className="text-center text-lg font-semibold">
              Total Return:
              <span className="block text-2xl text-primary">
                {result.toFixed(2)}%
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};