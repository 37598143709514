import { Tabs, TabsContent } from "@/components/ui/tabs";

interface CalculatorContentProps {
  calculators: Array<{
    id: string;
    component: React.ComponentType;
  }>;
  activeCalculator: string;
  isMobile: boolean;
}

export const CalculatorContent = ({ 
  calculators, 
  activeCalculator,
  isMobile 
}: CalculatorContentProps) => {
  if (isMobile) {
    return (
      <div className="mt-6">
        {calculators.map((calc) => (
          <div
            key={calc.id}
            className={activeCalculator === calc.id ? "block" : "hidden"}
          >
            <calc.component />
          </div>
        ))}
      </div>
    );
  }

  return (
    <Tabs value={activeCalculator} className="w-full">
      {calculators.map((calc) => (
        <TabsContent key={calc.id} value={calc.id} className="mt-6">
          <calc.component />
        </TabsContent>
      ))}
    </Tabs>
  );
};