import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";

export const TimeValueCalculator = () => {
  const [annualIncome, setAnnualIncome] = useState("");
  const [workingHours, setWorkingHours] = useState("");
  const [result, setResult] = useState<{ hourly: number; daily: number } | null>(null);
  const { toast } = useToast();

  const calculateTimeValue = () => {
    const income = parseFloat(annualIncome);
    const hours = parseFloat(workingHours);

    if (isNaN(income) || isNaN(hours) || hours === 0) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    const hourlyRate = income / (hours * 52); // 52 weeks in a year
    const dailyRate = hourlyRate * 8; // Assuming 8-hour workday
    setResult({ hourly: hourlyRate, daily: dailyRate });
  };

  return (
    <Card className="p-6">
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">Time Value Calculator</h3>
        <p className="text-gray-600">
          Calculate the value of your time to help prioritize tasks and projects.
        </p>
      </div>

      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="annual-income">Annual Income ($)</Label>
          <Input
            id="annual-income"
            type="number"
            placeholder="50000"
            value={annualIncome}
            onChange={(e) => setAnnualIncome(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="working-hours">Weekly Working Hours</Label>
          <Input
            id="working-hours"
            type="number"
            placeholder="40"
            value={workingHours}
            onChange={(e) => setWorkingHours(e.target.value)}
          />
        </div>

        <Button className="w-full" onClick={calculateTimeValue}>
          Calculate Time Value
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg space-y-2">
            <p className="text-center text-lg font-semibold">
              Hourly Rate:
              <span className="block text-xl text-primary">
                ${result.hourly.toFixed(2)}
              </span>
            </p>
            <p className="text-center text-lg font-semibold">
              Daily Rate (8 hours):
              <span className="block text-2xl text-primary">
                ${result.daily.toFixed(2)}
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};