import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";

export const BetaCalculator = () => {
  const [stockReturns, setStockReturns] = useState("");
  const [marketReturns, setMarketReturns] = useState("");
  const [result, setResult] = useState<number | null>(null);
  const { toast } = useToast();

  const calculateBeta = () => {
    try {
      const stockReturnsList = stockReturns.split(",").map(r => parseFloat(r.trim()));
      const marketReturnsList = marketReturns.split(",").map(r => parseFloat(r.trim()));

      if (stockReturnsList.length !== marketReturnsList.length) {
        toast({
          title: "Invalid Input",
          description: "Stock and market returns must have the same number of data points",
          variant: "destructive",
        });
        return;
      }

      // Calculate covariance
      const stockMean = stockReturnsList.reduce((a, b) => a + b, 0) / stockReturnsList.length;
      const marketMean = marketReturnsList.reduce((a, b) => a + b, 0) / marketReturnsList.length;
      
      let covariance = 0;
      let marketVariance = 0;
      
      for (let i = 0; i < stockReturnsList.length; i++) {
        covariance += (stockReturnsList[i] - stockMean) * (marketReturnsList[i] - marketMean);
        marketVariance += Math.pow(marketReturnsList[i] - marketMean, 2);
      }
      
      covariance /= stockReturnsList.length;
      marketVariance /= marketReturnsList.length;
      
      const beta = covariance / marketVariance;
      setResult(beta);
      console.log("Calculated beta:", beta);

    } catch (error) {
      toast({
        title: "Calculation Error",
        description: "Please enter valid return values separated by commas",
        variant: "destructive",
      });
    }
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="stock-returns">Stock Returns (%, comma-separated)</Label>
          <Input
            id="stock-returns"
            placeholder="2.5, -1.8, 3.2, -0.5, ..."
            value={stockReturns}
            onChange={(e) => setStockReturns(e.target.value)}
          />
          <p className="text-sm text-gray-500">Enter historical stock returns separated by commas</p>
        </div>

        <div className="space-y-2">
          <Label htmlFor="market-returns">Market Returns (%, comma-separated)</Label>
          <Input
            id="market-returns"
            placeholder="1.8, -1.2, 2.5, -0.3, ..."
            value={marketReturns}
            onChange={(e) => setMarketReturns(e.target.value)}
          />
          <p className="text-sm text-gray-500">Enter corresponding market returns separated by commas</p>
        </div>

        <Button 
          className="w-full"
          onClick={calculateBeta}
        >
          Calculate Beta
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg">
            <p className="text-center text-lg font-semibold">
              Beta Value:
              <span className="block text-2xl text-primary">
                {result.toFixed(2)}
              </span>
              <span className="block text-sm text-gray-600 mt-2">
                {result > 1 ? "More volatile than the market" :
                 result < 1 ? "Less volatile than the market" :
                 "Same volatility as the market"}
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};