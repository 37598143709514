import { useParams, Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { ArrowLeft, Calendar, Clock, Tag, BookOpen, Share2 } from 'lucide-react';
import Navigation from "@/components/Navigation";
import { supabase } from "@/integrations/supabase/client";
import { BlogPost, BlogCategory, BlogTag } from '@/types/blog';
import { Card } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { Separator } from "@/components/ui/separator";

const BlogPostPage = () => {
  const { slug } = useParams();
  console.log('BlogPostPage rendering for slug:', slug);

  const { data: post, isLoading, error } = useQuery({
    queryKey: ['blog-post', slug],
    queryFn: async () => {
      console.log('Fetching blog post for slug:', slug);
      if (!slug) throw new Error('No slug provided');

      const { data, error } = await supabase
        .from('blog_posts')
        .select('*')
        .eq('slug', slug)
        .maybeSingle();

      if (error) throw error;
      if (!data) throw new Error('Post not found');

      console.log('Raw post data:', data);

      // Transform the category to match BlogCategory type
      const categoryData = data.category as any;
      const transformedCategory: BlogCategory = {
        id: categoryData?.id || 'default',
        title: categoryData?.title || 'Uncategorized',
        slug: categoryData?.slug || 'uncategorized'
      };

      // Transform tags to match BlogTag type
      const transformedTags: BlogTag[] = Array.isArray(data.tags) 
        ? data.tags.map((tag: any) => ({
            id: tag.id || `tag-${Math.random()}`,
            title: tag.title || '',
            slug: tag.slug || ''
          }))
        : [];

      // Transform the data to match BlogPost type
      const transformedPost: BlogPost = {
        id: data.id,
        headline: data.headline,
        metaDescription: data.meta_description || '',
        slug: data.slug,
        category: transformedCategory,
        tags: transformedTags,
        readingTime: data.reading_time || 5,
        publishedAt: data.published_at || data.created_at,
        image: data.image || '/placeholder.svg',
        html: data.html || ''
      };

      console.log('Transformed post:', transformedPost);
      return transformedPost;
    },
    enabled: !!slug
  });

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-white to-gray-50">
        <Navigation />
        <main className="container mx-auto px-4 py-12">
          <Card className="max-w-4xl mx-auto p-8 animate-pulse">
            <div className="h-8 bg-gray-200 rounded w-3/4 mb-4"></div>
            <div className="h-4 bg-gray-100 rounded w-1/2 mb-8"></div>
            <div className="space-y-4">
              <div className="h-4 bg-gray-100 rounded w-full"></div>
              <div className="h-4 bg-gray-100 rounded w-full"></div>
              <div className="h-4 bg-gray-100 rounded w-3/4"></div>
            </div>
          </Card>
        </main>
      </div>
    );
  }

  if (error || !post) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-white to-gray-50">
        <Navigation />
        <main className="container mx-auto px-4 py-12">
          <Card className="max-w-4xl mx-auto p-8 text-center">
            <h1 className="text-2xl font-bold text-red-500 mb-4">
              {error ? 'Error loading blog post' : 'Blog post not found'}
            </h1>
            <Link to="/blog" className="text-primary hover:underline inline-flex items-center gap-2">
              <ArrowLeft className="w-4 h-4" />
              Back to Blog
            </Link>
          </Card>
        </main>
      </div>
    );
  }

  // Function to process HTML content and add classes to elements
  const processHtml = (html: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    
    // Add classes to headings
    doc.querySelectorAll('h1, h2, h3, h4, h5, h6').forEach(heading => {
      heading.classList.add('scroll-m-20', 'font-bold', 'tracking-tight', 'mt-10', 'mb-4');
      if (heading.tagName === 'H1') heading.classList.add('text-4xl');
      if (heading.tagName === 'H2') heading.classList.add('text-3xl');
      if (heading.tagName === 'H3') heading.classList.add('text-2xl');
      if (heading.tagName === 'H4') heading.classList.add('text-xl');
    });

    // Add classes to paragraphs
    doc.querySelectorAll('p').forEach(p => {
      p.classList.add('leading-7', 'mb-6', '[&:not(:first-child)]:mt-6');
    });

    // Add classes to lists
    doc.querySelectorAll('ul, ol').forEach(list => {
      list.classList.add('my-6', 'ml-6', 'list-disc', '[&>li]:mt-2');
    });

    // Add classes to blockquotes
    doc.querySelectorAll('blockquote').forEach(quote => {
      quote.classList.add('mt-6', 'border-l-2', 'border-primary', 'pl-6', 'italic');
    });

    // Add classes to links
    doc.querySelectorAll('a').forEach(link => {
      link.classList.add('text-primary', 'hover:underline');
    });

    return doc.body.innerHTML;
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-white to-gray-50">
      <Navigation />
      
      <main className="container mx-auto px-4 py-12">
        <article className="max-w-4xl mx-auto">
          <div className="mb-8 flex items-center justify-between">
            <Link 
              to="/blog" 
              className="inline-flex items-center gap-2 text-primary hover:underline transition-colors"
            >
              <ArrowLeft className="w-4 h-4" />
              Back to Blog
            </Link>
            
            <button 
              onClick={() => navigator.share({ 
                title: post.headline,
                url: window.location.href 
              })}
              className="inline-flex items-center gap-2 text-gray-500 hover:text-primary transition-colors"
            >
              <Share2 className="w-4 h-4" />
              Share
            </button>
          </div>
          
          {post.image && (
            <div className="relative h-[400px] mb-8 rounded-xl overflow-hidden shadow-lg">
              <img
                src={post.image}
                alt={post.headline}
                className="w-full h-full object-cover"
              />
            </div>
          )}

          <Card className="bg-white/80 backdrop-blur-sm rounded-xl shadow-lg p-8 mb-8">
            <h1 className="text-4xl font-bold mb-4 animate-fade-in">
              {post.headline}
            </h1>
            
            <div className="flex flex-wrap gap-4 text-sm text-gray-500 mb-6">
              {post.publishedAt && (
                <span className="flex items-center gap-1 bg-gray-50 px-3 py-1 rounded-full">
                  <Calendar className="w-4 h-4" />
                  {format(new Date(post.publishedAt), 'MMM d, yyyy')}
                </span>
              )}
              {post.readingTime && (
                <span className="flex items-center gap-1 bg-gray-50 px-3 py-1 rounded-full">
                  <Clock className="w-4 h-4" />
                  {post.readingTime} min read
                </span>
              )}
              {post.category && (
                <span className="flex items-center gap-1 bg-primary/10 text-primary px-3 py-1 rounded-full">
                  <BookOpen className="w-4 h-4" />
                  {post.category.title}
                </span>
              )}
            </div>

            {post.metaDescription && (
              <>
                <p className="text-lg text-gray-600 mb-6 leading-relaxed border-l-4 border-primary/20 pl-4">
                  {post.metaDescription}
                </p>
                <Separator className="my-8" />
              </>
            )}

            <div 
              className={cn(
                "prose prose-lg max-w-none",
                "prose-headings:font-semibold prose-headings:text-gray-800",
                "prose-p:text-gray-600 prose-p:leading-relaxed",
                "prose-a:text-primary prose-a:no-underline hover:prose-a:underline",
                "prose-strong:text-gray-800",
                "prose-ul:list-disc prose-ul:pl-6",
                "prose-ol:list-decimal prose-ol:pl-6",
                "prose-blockquote:border-l-4 prose-blockquote:border-primary/20 prose-blockquote:pl-4 prose-blockquote:italic",
                "prose-img:rounded-lg prose-img:shadow-md",
                "prose-code:text-primary prose-code:bg-primary/5 prose-code:rounded prose-code:px-1",
                "animate-fade-in"
              )}
              dangerouslySetInnerHTML={{ __html: processHtml(post.html) }}
            />

            {post.tags && post.tags.length > 0 && (
              <div className="flex flex-wrap gap-2 mt-8 pt-8 border-t">
                {post.tags.map((tag) => (
                  <span
                    key={tag.id}
                    className="flex items-center gap-1 text-sm text-gray-500 bg-gray-50 px-3 py-1 rounded-full hover:bg-gray-100 transition-colors"
                  >
                    <Tag className="w-4 h-4" />
                    {tag.title}
                  </span>
                ))}
              </div>
            )}
          </Card>
        </article>
      </main>
    </div>
  );
};

export default BlogPostPage;