import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { SideGigIncomeCalculator } from "./calculators/SideGigIncomeCalculator";
import { CreatorEarningsCalculator } from "./calculators/CreatorEarningsCalculator";
import { ProfitPotentialCalculator } from "./calculators/ProfitPotentialCalculator";
import { RetailPricingCalculator } from "./calculators/RetailPricingCalculator";
import { ContentCreatorCalculator } from "./calculators/ContentCreatorCalculator";

export const SideHustleCalculatorsSection = () => {
  return (
    <section className="mt-8">
      <h2 className="text-2xl font-semibold mb-6">Side Hustle Calculators</h2>
      <Tabs defaultValue="side-gig" className="w-full">
        <TabsList className="grid w-full grid-cols-2 lg:grid-cols-5">
          <TabsTrigger value="side-gig">Side Gig Income</TabsTrigger>
          <TabsTrigger value="creator">Creator Earnings</TabsTrigger>
          <TabsTrigger value="profit">Profit Potential</TabsTrigger>
          <TabsTrigger value="retail">Retail Pricing</TabsTrigger>
          <TabsTrigger value="content">Content Creator</TabsTrigger>
        </TabsList>
        
        <TabsContent value="side-gig" className="mt-6">
          <div>
            <h3 className="text-xl font-semibold mb-3">Side Gig Income Calculator</h3>
            <p className="text-gray-600 mb-4">
              Estimate your potential earnings from side jobs by factoring in hours worked, 
              hourly rates, and expenses. Perfect for evaluating the profitability of your side hustle.
            </p>
            <SideGigIncomeCalculator />
          </div>
        </TabsContent>

        <TabsContent value="creator" className="mt-6">
          <div>
            <h3 className="text-xl font-semibold mb-3">Creator Earnings Calculator</h3>
            <p className="text-gray-600 mb-4">
              Calculate potential earnings based on your social media following, engagement rates, 
              and conversion percentages. Ideal for influencers and content creators.
            </p>
            <CreatorEarningsCalculator />
          </div>
        </TabsContent>

        <TabsContent value="profit" className="mt-6">
          <div>
            <h3 className="text-xl font-semibold mb-3">Profit Potential Calculator</h3>
            <p className="text-gray-600 mb-4">
              Project your weekly, monthly, and yearly earnings while considering expenses and taxes. 
              Get insights into increasing profitability.
            </p>
            <ProfitPotentialCalculator />
          </div>
        </TabsContent>

        <TabsContent value="retail" className="mt-6">
          <div>
            <h3 className="text-xl font-semibold mb-3">Retail Pricing Calculator</h3>
            <p className="text-gray-600 mb-4">
              Determine optimal retail prices using cost-plus pricing methods. Essential for 
              product-based businesses to maintain healthy profit margins.
            </p>
            <RetailPricingCalculator />
          </div>
        </TabsContent>

        <TabsContent value="content" className="mt-6">
          <div>
            <h3 className="text-xl font-semibold mb-3">Content Creator Calculator</h3>
            <p className="text-gray-600 mb-4">
              Estimate potential earnings from platforms like YouTube and TikTok based on views, 
              engagement rates, and subscriber count.
            </p>
            <ContentCreatorCalculator />
          </div>
        </TabsContent>
      </Tabs>
    </section>
  );
};