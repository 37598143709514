
import PageLayout from "@/components/PageLayout";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Brain, Target, FileLineChart, Lightbulb } from "lucide-react";
import UnderstandingSection from "@/components/money-mindset/UnderstandingSection";
import MindsetTransformationTab from "@/components/money-mindset/MindsetTransformationTab";
import AbundancePrinciplesTab from "@/components/money-mindset/AbundancePrinciplesTab";
import EmotionalIntelligenceTab from "@/components/money-mindset/EmotionalIntelligenceTab";
import WealthPsychologyTab from "@/components/money-mindset/WealthPsychologyTab";

const MoneyMindset = () => {
  return (
    <PageLayout
      title="Money Mindset"
      description="Develop the right mindset for financial success and freedom"
    >
      <div className="space-y-12">
        <UnderstandingSection />

        <Tabs defaultValue="mindset-transformation" className="w-full">
          <TabsList className="grid w-full grid-cols-2 lg:grid-cols-4 gap-2 bg-muted/50 p-1 rounded-lg">
            <TabsTrigger value="mindset-transformation" className="flex items-center gap-2">
              <Brain className="w-4 h-4" />
              <span>Mindset Transformation</span>
            </TabsTrigger>
            <TabsTrigger value="abundance-principles" className="flex items-center gap-2">
              <Target className="w-4 h-4" />
              <span>Abundance Principles</span>
            </TabsTrigger>
            <TabsTrigger value="emotional-intelligence" className="flex items-center gap-2">
              <FileLineChart className="w-4 h-4" />
              <span>Emotional Intelligence</span>
            </TabsTrigger>
            <TabsTrigger value="wealth-psychology" className="flex items-center gap-2">
              <Lightbulb className="w-4 h-4" />
              <span>Wealth Psychology</span>
            </TabsTrigger>
          </TabsList>

          <TabsContent value="mindset-transformation" className="mt-6">
            <MindsetTransformationTab />
          </TabsContent>

          <TabsContent value="abundance-principles" className="mt-6">
            <AbundancePrinciplesTab />
          </TabsContent>

          <TabsContent value="emotional-intelligence" className="mt-6">
            <EmotionalIntelligenceTab />
          </TabsContent>

          <TabsContent value="wealth-psychology" className="mt-6">
            <WealthPsychologyTab />
          </TabsContent>
        </Tabs>
      </div>
    </PageLayout>
  );
};

export default MoneyMindset;
