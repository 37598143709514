import PageLayout from "@/components/PageLayout";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { MarketBasicsSection } from "@/components/stock-market/MarketBasicsSection";
import { InvestmentStrategiesSection } from "@/components/stock-market/InvestmentStrategiesSection";
import { MarketAnalysisSection } from "@/components/stock-market/MarketAnalysisSection";
import { InvestmentTypesSection } from "@/components/stock-market/InvestmentTypesSection";
import { CryptocurrencySection } from "@/components/stock-market/CryptocurrencySection";
import { CalculatorSection } from "@/components/stock-market/CalculatorSection";

const StockMarket = () => {
  return (
    <PageLayout
      title="Complete Stock Market Investment Guide | Beginner to Advanced"
      description="Learn stock market investing from the ground up. Master investment strategies, understand market analysis, and build a successful portfolio."
    >
      <div className="space-y-12">
        <div className="prose max-w-none mb-8">
          <p className="text-lg text-gray-700 leading-relaxed">
            The stock market represents one of the most powerful wealth-building tools available to investors. 
            Whether you're just starting out or looking to refine your investment strategy, understanding how 
            markets work and developing a solid investment approach is crucial. This comprehensive guide will 
            walk you through everything from basic concepts to advanced trading strategies, helping you make 
            informed investment decisions.
          </p>
        </div>

        <Tabs defaultValue="basics" className="w-full">
          <TabsList className="grid w-full grid-cols-2 lg:grid-cols-5">
            <TabsTrigger value="basics">Market Basics</TabsTrigger>
            <TabsTrigger value="strategies">Investment Strategies</TabsTrigger>
            <TabsTrigger value="analysis">Market Analysis</TabsTrigger>
            <TabsTrigger value="investment-types">Investment Types</TabsTrigger>
            <TabsTrigger value="cryptocurrency">Cryptocurrency</TabsTrigger>
          </TabsList>

          <TabsContent value="basics" className="mt-6">
            <MarketBasicsSection />
          </TabsContent>

          <TabsContent value="strategies" className="mt-6">
            <InvestmentStrategiesSection />
          </TabsContent>

          <TabsContent value="analysis" className="mt-6">
            <MarketAnalysisSection />
          </TabsContent>

          <TabsContent value="investment-types" className="mt-6">
            <InvestmentTypesSection />
          </TabsContent>

          <TabsContent value="cryptocurrency" className="mt-6">
            <CryptocurrencySection />
          </TabsContent>
        </Tabs>

        <CalculatorSection />
      </div>
    </PageLayout>
  );
};

export default StockMarket;