import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { DollarSign } from "lucide-react";

interface LiabilityInputsProps {
  liabilities: Record<string, string>;
  onLiabilityChange: (key: string, value: string) => void;
}

export const LiabilityInputs = ({ liabilities, onLiabilityChange }: LiabilityInputsProps) => {
  const liabilityFields = {
    mortgage: "Mortgage",
    carLoans: "Car Loans",
    studentLoans: "Student Loans",
    creditCards: "Credit Cards",
    otherDebts: "Other Debts"
  };

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold">Liabilities</h3>
      {Object.entries(liabilityFields).map(([key, label]) => (
        <div key={key} className="space-y-2">
          <Label htmlFor={`liability-${key}`}>{label}</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id={`liability-${key}`}
              type="number"
              placeholder="0"
              className="pl-10"
              value={liabilities[key]}
              onChange={(e) => onLiabilityChange(key, e.target.value)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};