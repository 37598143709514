import { Card } from "@/components/ui/card";

interface ContentSectionProps {
  title: string;
  children: React.ReactNode;
}

const ContentSection = ({ title, children }: ContentSectionProps) => {
  return (
    <section>
      <h2 className="text-2xl font-semibold mb-6">{title}</h2>
      <Card className="p-6">
        <div className="prose max-w-none">
          {children}
        </div>
      </Card>
    </section>
  );
};

export default ContentSection;