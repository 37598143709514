import ContentSection from "@/components/ContentSection";

export const BudgetingSection = () => {
  return (
    <ContentSection title="Budgeting Fundamentals">
      <h4 className="text-lg font-semibold mb-3">Building Strong Financial Foundations</h4>
      <p className="mb-4">
        Creating and maintaining a budget is the cornerstone of financial success. Follow these key principles:
      </p>
      <ul className="list-disc pl-6 mb-6">
        <li className="mb-2">Track all income and expenses</li>
        <li className="mb-2">Categorize spending into needs, wants, and savings</li>
        <li className="mb-2">Follow the 50/30/20 rule: 50% needs, 30% wants, 20% savings</li>
        <li>Regularly review and adjust your budget</li>
      </ul>
    </ContentSection>
  );
};