import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { DollarSign } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";

export const CompoundInterestCalculator = () => {
  const [principal, setPrincipal] = useState("");
  const [monthlyContribution, setMonthlyContribution] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [years, setYears] = useState("");
  const [result, setResult] = useState<number | null>(null);
  const { toast } = useToast();

  const calculateCompoundInterest = () => {
    const p = parseFloat(principal);
    const pmt = parseFloat(monthlyContribution);
    const r = parseFloat(interestRate) / 100;
    const t = parseFloat(years);

    if (isNaN(p) || isNaN(pmt) || isNaN(r) || isNaN(t)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    const monthlyRate = r / 12;
    const months = t * 12;
    let total = p;

    for (let i = 0; i < months; i++) {
      total = (total + pmt) * (1 + monthlyRate);
    }

    setResult(total);
    console.log("Calculated compound interest result:", total);
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="principal">Initial Investment</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="principal"
              type="number"
              placeholder="10000"
              className="pl-10"
              value={principal}
              onChange={(e) => setPrincipal(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="monthly">Monthly Contribution</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="monthly"
              type="number"
              placeholder="500"
              className="pl-10"
              value={monthlyContribution}
              onChange={(e) => setMonthlyContribution(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="interest">Annual Interest Rate (%)</Label>
          <Input
            id="interest"
            type="number"
            placeholder="7"
            value={interestRate}
            onChange={(e) => setInterestRate(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="years">Investment Period (Years)</Label>
          <Input
            id="years"
            type="number"
            placeholder="30"
            value={years}
            onChange={(e) => setYears(e.target.value)}
          />
        </div>

        <Button 
          className="w-full"
          onClick={calculateCompoundInterest}
        >
          Calculate Future Value
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg">
            <p className="text-center text-lg font-semibold">
              Future Value:
              <span className="block text-2xl text-primary">
                ${result.toLocaleString(undefined, { maximumFractionDigits: 2 })}
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};