import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { DollarSign } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";

export const IntrinsicValueCalculator = () => {
  const [cashFlow, setCashFlow] = useState("");
  const [growthRate, setGrowthRate] = useState("");
  const [discountRate, setDiscountRate] = useState("");
  const [years, setYears] = useState("");
  const [result, setResult] = useState<number | null>(null);
  const { toast } = useToast();

  const calculateIntrinsicValue = () => {
    const cf = parseFloat(cashFlow);
    const g = parseFloat(growthRate) / 100;
    const r = parseFloat(discountRate) / 100;
    const n = parseFloat(years);

    if (isNaN(cf) || isNaN(g) || isNaN(r) || isNaN(n)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    let value = 0;
    for (let i = 1; i <= n; i++) {
      value += (cf * Math.pow(1 + g, i)) / Math.pow(1 + r, i);
    }

    setResult(value);
    console.log("Calculated intrinsic value:", value);
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="cash-flow">Annual Free Cash Flow</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="cash-flow"
              type="number"
              placeholder="1000"
              className="pl-10"
              value={cashFlow}
              onChange={(e) => setCashFlow(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="growth-rate">Expected Growth Rate (%)</Label>
          <Input
            id="growth-rate"
            type="number"
            placeholder="5"
            value={growthRate}
            onChange={(e) => setGrowthRate(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="discount-rate">Discount Rate (%)</Label>
          <Input
            id="discount-rate"
            type="number"
            placeholder="10"
            value={discountRate}
            onChange={(e) => setDiscountRate(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="projection-years">Projection Years</Label>
          <Input
            id="projection-years"
            type="number"
            placeholder="5"
            value={years}
            onChange={(e) => setYears(e.target.value)}
          />
        </div>

        <Button 
          className="w-full"
          onClick={calculateIntrinsicValue}
        >
          Calculate Intrinsic Value
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg">
            <p className="text-center text-lg font-semibold">
              Intrinsic Value:
              <span className="block text-2xl text-primary">
                ${result.toLocaleString(undefined, { maximumFractionDigits: 2 })}
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};