interface NetWorthDisplayProps {
  netWorth: number | null;
}

export const NetWorthDisplay = ({ netWorth }: NetWorthDisplayProps) => {
  if (netWorth === null) return null;

  return (
    <div className="mt-4 p-4 bg-accent/10 rounded-lg">
      <p className="text-center text-lg font-semibold">
        Your Net Worth:
        <span className={`block text-2xl ${netWorth >= 0 ? 'text-green-600' : 'text-red-600'}`}>
          ${netWorth.toLocaleString(undefined, { maximumFractionDigits: 2 })}
        </span>
      </p>
    </div>
  );
};