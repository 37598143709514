import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { DollarSign } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";

export const PeRatioCalculator = () => {
  const [stockPrice, setStockPrice] = useState("");
  const [earningsPerShare, setEarningsPerShare] = useState("");
  const [result, setResult] = useState<number | null>(null);
  const { toast } = useToast();

  const calculatePeRatio = () => {
    const price = parseFloat(stockPrice);
    const eps = parseFloat(earningsPerShare);

    if (isNaN(price) || isNaN(eps)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    if (eps === 0) {
      toast({
        title: "Invalid EPS",
        description: "Earnings per share cannot be zero",
        variant: "destructive",
      });
      return;
    }

    const peRatio = price / eps;
    setResult(peRatio);
    console.log("Calculated P/E ratio:", peRatio);
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="stock-price">Current Stock Price</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="stock-price"
              type="number"
              placeholder="100"
              className="pl-10"
              value={stockPrice}
              onChange={(e) => setStockPrice(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="eps">Earnings Per Share (EPS)</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="eps"
              type="number"
              placeholder="5"
              className="pl-10"
              value={earningsPerShare}
              onChange={(e) => setEarningsPerShare(e.target.value)}
            />
          </div>
        </div>

        <Button 
          className="w-full"
          onClick={calculatePeRatio}
        >
          Calculate P/E Ratio
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg">
            <p className="text-center text-lg font-semibold">
              P/E Ratio:
              <span className="block text-2xl text-primary">
                {result.toFixed(2)}
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};