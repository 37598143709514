import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { SavingsRateCalculator } from "@/components/SavingsRateCalculator";
import { SavingsGoalCalculator } from "@/components/SavingsGoalCalculator";
import { CompoundInterestCalculator } from "@/components/CompoundInterestCalculator";
import { DebtPayoffCalculator } from "@/components/DebtPayoffCalculator";
import { PersonalWealthCalculator } from "@/components/PersonalWealthCalculator";
import { FireCalculator } from "@/components/FireCalculator";
import { CoastFireCalculator } from "@/components/CoastFireCalculator";
import { HealthcareCostCalculator } from "@/components/HealthcareCostCalculator";
import { MortgageCalculator } from "@/components/MortgageCalculator";
import { 
  Calculator, 
  PiggyBank, 
  TrendingUp, 
  DollarSign,
  HeartPulse,
  Home,
  Flame,
  Sunrise
} from "lucide-react";

export const CalculatorsSection = () => {
  return (
    <section className="space-y-6">
      <h2 className="text-2xl font-semibold mb-6">Financial Calculators</h2>
      
      <Tabs defaultValue="savings" className="w-full">
        <TabsList className="grid w-full grid-cols-2 lg:grid-cols-4 gap-4">
          <TabsTrigger value="savings" className="flex items-center gap-2">
            <PiggyBank className="w-4 h-4" />
            Savings
          </TabsTrigger>
          <TabsTrigger value="investments" className="flex items-center gap-2">
            <TrendingUp className="w-4 h-4" />
            Investments
          </TabsTrigger>
          <TabsTrigger value="retirement" className="flex items-center gap-2">
            <Sunrise className="w-4 h-4" />
            Retirement
          </TabsTrigger>
          <TabsTrigger value="loans" className="flex items-center gap-2">
            <DollarSign className="w-4 h-4" />
            Loans & Debt
          </TabsTrigger>
        </TabsList>
        
        <TabsContent value="savings" className="mt-6 space-y-6">
          <div>
            <h3 className="text-xl font-semibold mb-3">Savings Calculators</h3>
            <p className="text-gray-600 mb-4">
              Track your savings progress and plan for your financial goals.
            </p>
            <div className="grid gap-6 md:grid-cols-2">
              <div>
                <h4 className="font-medium mb-2">Savings Rate Calculator</h4>
                <SavingsRateCalculator />
              </div>
              <div>
                <h4 className="font-medium mb-2">Savings Goal Calculator</h4>
                <SavingsGoalCalculator />
              </div>
            </div>
          </div>
        </TabsContent>

        <TabsContent value="investments" className="mt-6 space-y-6">
          <div>
            <h3 className="text-xl font-semibold mb-3">Investment Calculators</h3>
            <p className="text-gray-600 mb-4">
              Plan your investment strategy and track potential returns.
            </p>
            <div className="grid gap-6 md:grid-cols-2">
              <div>
                <h4 className="font-medium mb-2">Compound Interest Calculator</h4>
                <CompoundInterestCalculator />
              </div>
              <div>
                <h4 className="font-medium mb-2">Net Worth Calculator</h4>
                <PersonalWealthCalculator />
              </div>
            </div>
          </div>
        </TabsContent>

        <TabsContent value="retirement" className="mt-6 space-y-6">
          <div>
            <h3 className="text-xl font-semibold mb-3">Retirement Planning</h3>
            <p className="text-gray-600 mb-4">
              Calculate your retirement needs and plan your financial independence.
            </p>
            <div className="grid gap-6 md:grid-cols-2">
              <div>
                <h4 className="font-medium mb-2">FIRE Calculator</h4>
                <FireCalculator />
              </div>
              <div>
                <h4 className="font-medium mb-2">Coast FIRE Calculator</h4>
                <CoastFireCalculator />
              </div>
              <div>
                <h4 className="font-medium mb-2">Healthcare Cost Calculator</h4>
                <HealthcareCostCalculator />
              </div>
            </div>
          </div>
        </TabsContent>

        <TabsContent value="loans" className="mt-6 space-y-6">
          <div>
            <h3 className="text-xl font-semibold mb-3">Loans & Debt Management</h3>
            <p className="text-gray-600 mb-4">
              Plan your debt repayment strategy and calculate loan payments.
            </p>
            <div className="grid gap-6 md:grid-cols-2">
              <div>
                <h4 className="font-medium mb-2">Debt Payoff Calculator</h4>
                <DebtPayoffCalculator />
              </div>
              <div>
                <h4 className="font-medium mb-2">Mortgage Calculator</h4>
                <MortgageCalculator />
              </div>
            </div>
          </div>
        </TabsContent>
      </Tabs>
    </section>
  );
};