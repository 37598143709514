import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";

export const ProfitMarginCalculator = () => {
  const [revenue, setRevenue] = useState("");
  const [costs, setCosts] = useState("");
  const [result, setResult] = useState<{ gross: number; net: number } | null>(null);
  const { toast } = useToast();

  const calculateProfitMargin = () => {
    const totalRevenue = parseFloat(revenue);
    const totalCosts = parseFloat(costs);

    if (isNaN(totalRevenue) || isNaN(totalCosts)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    const grossProfit = totalRevenue - totalCosts;
    const grossMargin = (grossProfit / totalRevenue) * 100;
    const netMargin = (grossProfit / totalRevenue) * 100;

    setResult({ gross: grossMargin, net: netMargin });
    console.log("Calculated profit margins:", { gross: grossMargin, net: netMargin });
  };

  return (
    <Card className="p-6">
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">Profit Margin Calculator</h3>
        <p className="text-gray-600">
          Calculate your gross and net profit margins to understand your business profitability.
        </p>
      </div>

      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="revenue">Total Revenue ($)</Label>
          <Input
            id="revenue"
            type="number"
            placeholder="100000"
            value={revenue}
            onChange={(e) => setRevenue(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="costs">Total Costs ($)</Label>
          <Input
            id="costs"
            type="number"
            placeholder="75000"
            value={costs}
            onChange={(e) => setCosts(e.target.value)}
          />
        </div>

        <Button 
          className="w-full"
          onClick={calculateProfitMargin}
        >
          Calculate Profit Margins
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg space-y-2">
            <p className="text-center font-semibold">
              Gross Profit Margin:
              <span className="block text-2xl text-primary">
                {result.gross.toFixed(2)}%
              </span>
            </p>
            <p className="text-center font-semibold">
              Net Profit Margin:
              <span className="block text-2xl text-primary">
                {result.net.toFixed(2)}%
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};