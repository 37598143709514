import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { DollarSign, Clock } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";

export const SideGigIncomeCalculator = () => {
  const [hoursPerWeek, setHoursPerWeek] = useState("");
  const [hourlyRate, setHourlyRate] = useState("");
  const [monthlyExpenses, setMonthlyExpenses] = useState("");
  const [result, setResult] = useState<{
    monthlyIncome: number;
    yearlyIncome: number;
    netIncome: number;
  } | null>(null);
  const { toast } = useToast();

  const calculateIncome = () => {
    const hours = parseFloat(hoursPerWeek);
    const rate = parseFloat(hourlyRate);
    const expenses = parseFloat(monthlyExpenses);

    if (isNaN(hours) || isNaN(rate) || isNaN(expenses)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    const monthlyIncome = hours * rate * 4; // Assuming 4 weeks per month
    const yearlyIncome = monthlyIncome * 12;
    const netIncome = monthlyIncome - expenses;

    setResult({ monthlyIncome, yearlyIncome, netIncome });
    console.log("Calculated side gig income:", { monthlyIncome, yearlyIncome, netIncome });
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="hours">Hours Per Week</Label>
          <div className="relative">
            <Clock className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="hours"
              type="number"
              placeholder="10"
              className="pl-10"
              value={hoursPerWeek}
              onChange={(e) => setHoursPerWeek(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="rate">Hourly Rate</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="rate"
              type="number"
              placeholder="25"
              className="pl-10"
              value={hourlyRate}
              onChange={(e) => setHourlyRate(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="expenses">Monthly Expenses</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="expenses"
              type="number"
              placeholder="100"
              className="pl-10"
              value={monthlyExpenses}
              onChange={(e) => setMonthlyExpenses(e.target.value)}
            />
          </div>
        </div>

        <Button 
          className="w-full"
          onClick={calculateIncome}
        >
          Calculate Income
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg space-y-2">
            <p className="text-center">
              <span className="font-semibold">Monthly Income:</span>
              <span className="block text-xl text-primary">
                ${result.monthlyIncome.toLocaleString()}
              </span>
            </p>
            <p className="text-center">
              <span className="font-semibold">Yearly Income:</span>
              <span className="block text-xl text-primary">
                ${result.yearlyIncome.toLocaleString()}
              </span>
            </p>
            <p className="text-center">
              <span className="font-semibold">Net Monthly Income:</span>
              <span className="block text-xl text-primary">
                ${result.netIncome.toLocaleString()}
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};