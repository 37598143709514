import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";

export const FinancialProjectionsCalculator = () => {
  const [initialRevenue, setInitialRevenue] = useState("");
  const [growthRate, setGrowthRate] = useState("");
  const [expenses, setExpenses] = useState("");
  const [years, setYears] = useState("");
  const [result, setResult] = useState<Array<{
    year: number;
    revenue: number;
    expenses: number;
    profit: number;
  }> | null>(null);
  const { toast } = useToast();

  const calculateProjections = () => {
    const revenue = parseFloat(initialRevenue);
    const growth = parseFloat(growthRate) / 100;
    const annualExpenses = parseFloat(expenses);
    const projectionYears = parseInt(years);

    if (isNaN(revenue) || isNaN(growth) || isNaN(annualExpenses) || isNaN(projectionYears)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    const projections = [];
    let currentRevenue = revenue;

    for (let year = 1; year <= projectionYears; year++) {
      const yearlyExpenses = annualExpenses * (1 + (growth / 2)); // Expenses grow at half the rate of revenue
      const profit = currentRevenue - yearlyExpenses;
      
      projections.push({
        year,
        revenue: currentRevenue,
        expenses: yearlyExpenses,
        profit,
      });

      currentRevenue *= (1 + growth);
    }

    setResult(projections);
  };

  return (
    <Card className="p-6">
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">Financial Projections Calculator</h3>
        <p className="text-gray-600">
          Create financial projections for your business plan, including revenue, expenses, and profit.
        </p>
      </div>

      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="initial-revenue">Initial Annual Revenue ($)</Label>
          <Input
            id="initial-revenue"
            type="number"
            placeholder="100000"
            value={initialRevenue}
            onChange={(e) => setInitialRevenue(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="growth-rate">Annual Growth Rate (%)</Label>
          <Input
            id="growth-rate"
            type="number"
            placeholder="10"
            value={growthRate}
            onChange={(e) => setGrowthRate(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="expenses">Initial Annual Expenses ($)</Label>
          <Input
            id="expenses"
            type="number"
            placeholder="70000"
            value={expenses}
            onChange={(e) => setExpenses(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="years">Projection Years</Label>
          <Input
            id="years"
            type="number"
            placeholder="5"
            value={years}
            onChange={(e) => setYears(e.target.value)}
          />
        </div>

        <Button className="w-full" onClick={calculateProjections}>
          Calculate Projections
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg">
            <h4 className="text-lg font-semibold mb-4 text-center">Financial Projections</h4>
            <div className="space-y-4">
              {result.map((year) => (
                <div key={year.year} className="p-3 bg-white rounded-lg shadow-sm">
                  <h5 className="font-medium mb-2">Year {year.year}</h5>
                  <div className="grid grid-cols-2 gap-2 text-sm">
                    <p>Revenue:</p>
                    <p className="text-right">${year.revenue.toLocaleString()}</p>
                    <p>Expenses:</p>
                    <p className="text-right">${year.expenses.toLocaleString()}</p>
                    <p className="font-medium">Profit:</p>
                    <p className="text-right font-medium">${year.profit.toLocaleString()}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};