import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { DollarSign } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";

export const CapitalGainsTaxCalculator = () => {
  const [purchasePrice, setPurchasePrice] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [taxRate, setTaxRate] = useState("");
  const [result, setResult] = useState<{ gain: number; tax: number } | null>(null);
  const { toast } = useToast();

  const calculateCapitalGainsTax = () => {
    const purchase = parseFloat(purchasePrice);
    const sale = parseFloat(salePrice);
    const rate = parseFloat(taxRate) / 100;

    if (isNaN(purchase) || isNaN(sale) || isNaN(rate)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    const gain = sale - purchase;
    const tax = gain * rate;

    setResult({ gain, tax });
    console.log("Calculated capital gains tax:", { gain, tax });
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="purchase-price">Purchase Price</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="purchase-price"
              type="number"
              placeholder="1000"
              className="pl-10"
              value={purchasePrice}
              onChange={(e) => setPurchasePrice(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="sale-price">Sale Price</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="sale-price"
              type="number"
              placeholder="1500"
              className="pl-10"
              value={salePrice}
              onChange={(e) => setSalePrice(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="tax-rate">Tax Rate (%)</Label>
          <Input
            id="tax-rate"
            type="number"
            placeholder="15"
            value={taxRate}
            onChange={(e) => setTaxRate(e.target.value)}
          />
        </div>

        <Button 
          className="w-full"
          onClick={calculateCapitalGainsTax}
        >
          Calculate Tax
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg">
            <p className="text-center text-lg font-semibold">
              Results:
              <span className="block text-xl text-primary mt-2">
                Capital Gain: ${result.gain.toLocaleString(undefined, { maximumFractionDigits: 2 })}
              </span>
              <span className="block text-xl text-primary">
                Tax Owed: ${result.tax.toLocaleString(undefined, { maximumFractionDigits: 2 })}
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};