import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { DollarSign } from "lucide-react";

interface AssetInputsProps {
  assets: Record<string, string>;
  onAssetChange: (key: string, value: string) => void;
}

export const AssetInputs = ({ assets, onAssetChange }: AssetInputsProps) => {
  const assetFields = {
    cash: "Cash & Savings",
    investments: "Investments",
    realEstate: "Real Estate",
    vehicles: "Vehicles",
    otherAssets: "Other Assets"
  };

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold">Assets</h3>
      {Object.entries(assetFields).map(([key, label]) => (
        <div key={key} className="space-y-2">
          <Label htmlFor={`asset-${key}`}>{label}</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id={`asset-${key}`}
              type="number"
              placeholder="0"
              className="pl-10"
              value={assets[key]}
              onChange={(e) => onAssetChange(key, e.target.value)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};