import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { DollarSign } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";

export const HealthcareCostCalculator = () => {
  const [currentAge, setCurrentAge] = useState("");
  const [retirementAge, setRetirementAge] = useState("");
  const [monthlyPremium, setMonthlyPremium] = useState("");
  const [annualDeductible, setAnnualDeductible] = useState("");
  const [inflationRate, setInflationRate] = useState("5");
  const [result, setResult] = useState<{
    totalCost: number;
    monthlyNeeded: number;
    yearlyNeeded: number;
  } | null>(null);
  const { toast } = useToast();

  const calculateHealthcareCosts = () => {
    const age = parseFloat(currentAge);
    const retireAge = parseFloat(retirementAge);
    const premium = parseFloat(monthlyPremium);
    const deductible = parseFloat(annualDeductible);
    const inflation = parseFloat(inflationRate) / 100;

    if (isNaN(age) || isNaN(retireAge) || isNaN(premium) || isNaN(deductible) || isNaN(inflation)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    const yearsToMedicare = Math.max(0, 65 - retireAge);
    let totalCost = 0;
    
    for (let year = 0; year < yearsToMedicare; year++) {
      const yearlyPremium = premium * 12 * Math.pow(1 + inflation, year);
      const yearlyDeductible = deductible * Math.pow(1 + inflation, year);
      totalCost += yearlyPremium + yearlyDeductible;
    }

    const monthlyNeeded = totalCost / (yearsToMedicare * 12);
    const yearlyNeeded = totalCost / yearsToMedicare;

    setResult({
      totalCost,
      monthlyNeeded,
      yearlyNeeded
    });

    console.log("Calculated healthcare costs:", { totalCost, monthlyNeeded, yearlyNeeded });
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="current-age">Current Age</Label>
          <Input
            id="current-age"
            type="number"
            placeholder="45"
            value={currentAge}
            onChange={(e) => setCurrentAge(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="retirement-age">Planned Retirement Age</Label>
          <Input
            id="retirement-age"
            type="number"
            placeholder="55"
            value={retirementAge}
            onChange={(e) => setRetirementAge(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="monthly-premium">Expected Monthly Premium</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="monthly-premium"
              type="number"
              placeholder="800"
              className="pl-10"
              value={monthlyPremium}
              onChange={(e) => setMonthlyPremium(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="annual-deductible">Annual Deductible</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="annual-deductible"
              type="number"
              placeholder="2000"
              className="pl-10"
              value={annualDeductible}
              onChange={(e) => setAnnualDeductible(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="inflation-rate">Healthcare Inflation Rate (%)</Label>
          <Input
            id="inflation-rate"
            type="number"
            placeholder="5"
            value={inflationRate}
            onChange={(e) => setInflationRate(e.target.value)}
          />
        </div>

        <Button 
          className="w-full"
          onClick={calculateHealthcareCosts}
        >
          Calculate Healthcare Costs
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg space-y-2">
            <p className="text-center">
              <span className="block text-lg font-semibold">Total Cost Until Medicare:</span>
              <span className="block text-2xl text-primary">
                ${result.totalCost.toLocaleString(undefined, { maximumFractionDigits: 0 })}
              </span>
            </p>
            <p className="text-center">
              <span className="block text-lg font-semibold">Monthly Savings Needed:</span>
              <span className="block text-2xl text-primary">
                ${result.monthlyNeeded.toLocaleString(undefined, { maximumFractionDigits: 0 })}
              </span>
            </p>
            <p className="text-center">
              <span className="block text-lg font-semibold">Yearly Savings Needed:</span>
              <span className="block text-2xl text-primary">
                ${result.yearlyNeeded.toLocaleString(undefined, { maximumFractionDigits: 0 })}
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};