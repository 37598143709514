import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";

export const SubscriptionRevenueCalculator = () => {
  const [subscribers, setSubscribers] = useState("");
  const [monthlyPrice, setMonthlyPrice] = useState("");
  const [churnRate, setChurnRate] = useState("");
  const [growthRate, setGrowthRate] = useState("");
  const [result, setResult] = useState<{ monthly: number; annual: number; retention: number } | null>(null);
  const { toast } = useToast();

  const calculateRevenue = () => {
    const initialSubscribers = parseFloat(subscribers);
    const price = parseFloat(monthlyPrice);
    const churn = parseFloat(churnRate) / 100;
    const growth = parseFloat(growthRate) / 100;

    if (isNaN(initialSubscribers) || isNaN(price) || isNaN(churn) || isNaN(growth)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    const monthlyRevenue = initialSubscribers * price;
    const annualRevenue = monthlyRevenue * 12;
    const retentionRate = (1 - churn) * 100;

    setResult({
      monthly: monthlyRevenue,
      annual: annualRevenue,
      retention: retentionRate
    });
  };

  return (
    <Card className="p-6">
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">Subscription Revenue Calculator</h3>
        <p className="text-gray-600">
          Calculate potential revenue for your subscription-based business based on
          number of subscribers, pricing, and churn rate.
        </p>
      </div>

      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="subscribers">Initial Number of Subscribers</Label>
          <Input
            id="subscribers"
            type="number"
            placeholder="1000"
            value={subscribers}
            onChange={(e) => setSubscribers(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="monthly-price">Monthly Subscription Price ($)</Label>
          <Input
            id="monthly-price"
            type="number"
            placeholder="9.99"
            value={monthlyPrice}
            onChange={(e) => setMonthlyPrice(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="churn-rate">Monthly Churn Rate (%)</Label>
          <Input
            id="churn-rate"
            type="number"
            placeholder="5"
            value={churnRate}
            onChange={(e) => setChurnRate(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="growth-rate">Monthly Growth Rate (%)</Label>
          <Input
            id="growth-rate"
            type="number"
            placeholder="10"
            value={growthRate}
            onChange={(e) => setGrowthRate(e.target.value)}
          />
        </div>

        <Button 
          className="w-full"
          onClick={calculateRevenue}
        >
          Calculate Revenue
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg space-y-2">
            <p className="text-center font-semibold">
              Monthly Revenue:
              <span className="block text-2xl text-primary">
                ${result.monthly.toFixed(2)}
              </span>
            </p>
            <p className="text-center font-semibold">
              Annual Revenue:
              <span className="block text-2xl text-primary">
                ${result.annual.toFixed(2)}
              </span>
            </p>
            <p className="text-center font-semibold">
              Customer Retention Rate:
              <span className="block text-2xl text-primary">
                {result.retention.toFixed(1)}%
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};