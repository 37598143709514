import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";

export const ECommerceRevenueCalculator = () => {
  const [monthlyVisitors, setMonthlyVisitors] = useState("");
  const [conversionRate, setConversionRate] = useState("");
  const [averageOrderValue, setAverageOrderValue] = useState("");
  const [result, setResult] = useState<number | null>(null);
  const { toast } = useToast();

  const calculateRevenue = () => {
    const visitors = parseFloat(monthlyVisitors);
    const conversion = parseFloat(conversionRate) / 100;
    const orderValue = parseFloat(averageOrderValue);

    if (isNaN(visitors) || isNaN(conversion) || isNaN(orderValue)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    const monthlyRevenue = visitors * conversion * orderValue;
    setResult(monthlyRevenue);
  };

  return (
    <Card className="p-6">
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">E-Commerce Revenue Calculator</h3>
        <p className="text-gray-600">
          Project your online store's revenue based on traffic, conversion rate, and average order value.
        </p>
      </div>

      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="monthly-visitors">Monthly Visitors</Label>
          <Input
            id="monthly-visitors"
            type="number"
            placeholder="10000"
            value={monthlyVisitors}
            onChange={(e) => setMonthlyVisitors(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="conversion-rate">Conversion Rate (%)</Label>
          <Input
            id="conversion-rate"
            type="number"
            placeholder="2"
            value={conversionRate}
            onChange={(e) => setConversionRate(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="average-order">Average Order Value ($)</Label>
          <Input
            id="average-order"
            type="number"
            placeholder="50"
            value={averageOrderValue}
            onChange={(e) => setAverageOrderValue(e.target.value)}
          />
        </div>

        <Button className="w-full" onClick={calculateRevenue}>
          Calculate Monthly Revenue
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg">
            <p className="text-center text-lg font-semibold">
              Estimated Monthly Revenue:
              <span className="block text-2xl text-primary">
                ${result.toLocaleString()}
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};