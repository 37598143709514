import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { DollarSign } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";

export const ContentCreatorCalculator = () => {
  const [monthlyViews, setMonthlyViews] = useState("");
  const [subscriberCount, setSubscriberCount] = useState("");
  const [engagementRate, setEngagementRate] = useState("");
  const [platform, setPlatform] = useState("youtube");
  const [result, setResult] = useState<{
    monthlyEarnings: number;
    yearlyEarnings: number;
    engagedViewers: number;
  } | null>(null);
  const { toast } = useToast();

  const calculateEarnings = () => {
    const views = parseFloat(monthlyViews);
    const subscribers = parseFloat(subscriberCount);
    const engagement = parseFloat(engagementRate) / 100;

    if (isNaN(views) || isNaN(subscribers) || isNaN(engagement)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    let monthlyEarnings = 0;
    const engagedViewers = Math.floor(views * engagement);

    // Rough estimates based on platform averages
    if (platform === "youtube") {
      monthlyEarnings = (views / 1000) * 2; // $2 per 1000 views
    } else if (platform === "tiktok") {
      monthlyEarnings = (views / 1000) * 0.5; // $0.50 per 1000 views
    }

    // Add bonus for high engagement
    if (engagement > 0.1) { // If engagement rate > 10%
      monthlyEarnings *= 1.2; // 20% bonus
    }

    const yearlyEarnings = monthlyEarnings * 12;

    setResult({ monthlyEarnings, yearlyEarnings, engagedViewers });
    console.log("Calculated content creator earnings:", { monthlyEarnings, yearlyEarnings, engagedViewers });
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="platform">Platform</Label>
          <select
            id="platform"
            className="w-full p-2 border rounded-md"
            value={platform}
            onChange={(e) => setPlatform(e.target.value)}
          >
            <option value="youtube">YouTube</option>
            <option value="tiktok">TikTok</option>
          </select>
        </div>

        <div className="space-y-2">
          <Label htmlFor="views">Monthly Views</Label>
          <Input
            id="views"
            type="number"
            placeholder="100000"
            value={monthlyViews}
            onChange={(e) => setMonthlyViews(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="subscribers">Subscriber Count</Label>
          <Input
            id="subscribers"
            type="number"
            placeholder="10000"
            value={subscriberCount}
            onChange={(e) => setSubscriberCount(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="engagement">Engagement Rate (%)</Label>
          <Input
            id="engagement"
            type="number"
            placeholder="5"
            value={engagementRate}
            onChange={(e) => setEngagementRate(e.target.value)}
          />
        </div>

        <Button 
          className="w-full"
          onClick={calculateEarnings}
        >
          Calculate Potential Earnings
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg space-y-2">
            <p className="text-center">
              <span className="font-semibold">Monthly Earnings Estimate:</span>
              <span className="block text-xl text-primary">
                ${result.monthlyEarnings.toFixed(2)}
              </span>
            </p>
            <p className="text-center">
              <span className="font-semibold">Yearly Earnings Estimate:</span>
              <span className="block text-xl text-primary">
                ${result.yearlyEarnings.toFixed(2)}
              </span>
            </p>
            <p className="text-center">
              <span className="font-semibold">Engaged Viewers:</span>
              <span className="block text-xl text-primary">
                {result.engagedViewers.toLocaleString()}
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};