import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";

export const DdmCalculator = () => {
  const [dividend, setDividend] = useState("");
  const [growthRate, setGrowthRate] = useState("");
  const [requiredReturn, setRequiredReturn] = useState("");
  const [result, setResult] = useState<number | null>(null);

  const calculateDDM = () => {
    const d = parseFloat(dividend);
    const g = parseFloat(growthRate) / 100;
    const r = parseFloat(requiredReturn) / 100;

    if (r <= g) {
      console.error("Required return must be greater than growth rate");
      return;
    }

    const stockValue = d * (1 + g) / (r - g);
    setResult(stockValue);
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium mb-1">
            Next Year's Dividend ($)
          </label>
          <Input
            type="number"
            value={dividend}
            onChange={(e) => setDividend(e.target.value)}
            placeholder="Enter expected dividend"
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">
            Growth Rate (%)
          </label>
          <Input
            type="number"
            value={growthRate}
            onChange={(e) => setGrowthRate(e.target.value)}
            placeholder="Enter growth rate"
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">
            Required Return (%)
          </label>
          <Input
            type="number"
            value={requiredReturn}
            onChange={(e) => setRequiredReturn(e.target.value)}
            placeholder="Enter required return"
          />
        </div>
        <Button onClick={calculateDDM} className="w-full">
          Calculate Stock Value
        </Button>
        {result !== null && (
          <div className="mt-4 p-4 bg-muted rounded-lg">
            <p className="text-sm font-medium">Estimated Stock Value:</p>
            <p className="text-2xl font-bold">${result.toFixed(2)}</p>
          </div>
        )}
      </div>
    </Card>
  );
};