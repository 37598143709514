import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";

export const CreatorEarningsCalculator = () => {
  const [followers, setFollowers] = useState("");
  const [engagementRate, setEngagementRate] = useState("");
  const [conversionRate, setConversionRate] = useState("");
  const [averageValue, setAverageValue] = useState("");
  const [result, setResult] = useState<{
    potentialCustomers: number;
    monthlyRevenue: number;
    yearlyRevenue: number;
  } | null>(null);
  const { toast } = useToast();

  const calculateEarnings = () => {
    const followersNum = parseFloat(followers);
    const engagement = parseFloat(engagementRate) / 100;
    const conversion = parseFloat(conversionRate) / 100;
    const value = parseFloat(averageValue);

    if (isNaN(followersNum) || isNaN(engagement) || isNaN(conversion) || isNaN(value)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    const potentialCustomers = Math.floor(followersNum * engagement * conversion);
    const monthlyRevenue = potentialCustomers * value;
    const yearlyRevenue = monthlyRevenue * 12;

    setResult({ potentialCustomers, monthlyRevenue, yearlyRevenue });
    console.log("Calculated creator earnings:", { potentialCustomers, monthlyRevenue, yearlyRevenue });
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="followers">Total Followers</Label>
          <Input
            id="followers"
            type="number"
            placeholder="10000"
            value={followers}
            onChange={(e) => setFollowers(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="engagement">Engagement Rate (%)</Label>
          <Input
            id="engagement"
            type="number"
            placeholder="5"
            value={engagementRate}
            onChange={(e) => setEngagementRate(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="conversion">Conversion Rate (%)</Label>
          <Input
            id="conversion"
            type="number"
            placeholder="2"
            value={conversionRate}
            onChange={(e) => setConversionRate(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="value">Average Sale Value ($)</Label>
          <Input
            id="value"
            type="number"
            placeholder="50"
            value={averageValue}
            onChange={(e) => setAverageValue(e.target.value)}
          />
        </div>

        <Button 
          className="w-full"
          onClick={calculateEarnings}
        >
          Calculate Potential Earnings
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg space-y-2">
            <p className="text-center">
              <span className="font-semibold">Potential Monthly Customers:</span>
              <span className="block text-xl text-primary">
                {result.potentialCustomers.toLocaleString()}
              </span>
            </p>
            <p className="text-center">
              <span className="font-semibold">Monthly Revenue:</span>
              <span className="block text-xl text-primary">
                ${result.monthlyRevenue.toLocaleString()}
              </span>
            </p>
            <p className="text-center">
              <span className="font-semibold">Yearly Revenue:</span>
              <span className="block text-xl text-primary">
                ${result.yearlyRevenue.toLocaleString()}
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};