
import BlogGrid from "@/components/blog/BlogGrid";
import BlogHeader from "@/components/blog/BlogHeader";
import { RssFeedSubscription } from "@/components/RssFeedSubscription";
import { supabase } from "@/integrations/supabase/client";
import { BlogPost, BlogCategory, BlogTag } from "@/types/blog";
import Navigation from "@/components/Navigation";
import { useQuery } from "@tanstack/react-query";

const BlogPage = () => {
  console.log('BlogPage rendering');

  const { data: posts, isLoading } = useQuery({
    queryKey: ['blog-posts'],
    queryFn: async () => {
      console.log('Fetching blog posts...');
      const { data, error } = await supabase
        .from('blog_posts')
        .select('*')
        .eq('published', true)
        .order('published_at', { ascending: false })
        .limit(20); // Add a reasonable limit

      if (error) {
        console.error('Error fetching blog posts:', error);
        throw error;
      }

      console.log('Raw blog posts:', data);
      
      // Transform the data to match BlogPost interface
      const transformedPosts: BlogPost[] = data.map(post => {
        try {
          // Parse category from JSON
          const categoryData = post.category as any;
          const transformedCategory: BlogCategory = categoryData ? {
            id: categoryData.id || '',
            title: categoryData.title || '',
            slug: categoryData.slug || ''
          } : {
            id: '',
            title: '',
            slug: ''
          };

          // Parse tags from JSON
          const tagsData = post.tags as any[];
          const transformedTags: BlogTag[] = Array.isArray(tagsData) 
            ? tagsData.map(tag => ({
                id: tag.id || '',
                title: tag.title || '',
                slug: tag.slug || ''
              }))
            : [];

          return {
            id: post.id,
            headline: post.headline,
            metaDescription: post.meta_description || '',
            category: transformedCategory,
            tags: transformedTags,
            readingTime: post.reading_time || 0,
            publishedAt: post.published_at || new Date().toISOString(),
            image: post.image || '',
            slug: post.slug,
            html: post.html
          };
        } catch (error) {
          console.error('Error transforming post:', post, error);
          throw error;
        }
      });

      console.log('Transformed blog posts:', transformedPosts);
      return transformedPosts;
    },
    refetchOnMount: true, // Add this to ensure we get fresh data when component mounts
    staleTime: 1000 * 60 * 5 // Consider data stale after 5 minutes
  });

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-gray-50">
      <Navigation />
      <div className="container mx-auto px-4 py-8">
        <BlogHeader />
        <BlogGrid posts={posts || []} isLoading={isLoading} />
        <div className="mt-16">
          <RssFeedSubscription />
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
