
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { BookOpen, PiggyBank } from "lucide-react";

const MindsetTransformationTab = () => {
  return (
    <Card className="p-6">
      <h3 className="text-xl font-bold mb-4">Transforming Your Money Mindset</h3>
      <div className="space-y-6">
        <div>
          <h4 className="text-lg font-semibold mb-2">Daily Mindset Practices</h4>
          <p className="text-gray-700 mb-3">
            Cultivate habits that reinforce a positive money mindset:
          </p>
          <ul className="list-disc pl-6 space-y-2 text-gray-700">
            <li>Morning gratitude for current financial blessings</li>
            <li>Visualization of financial goals and success</li>
            <li>Positive money affirmations</li>
            <li>Financial education and learning</li>
          </ul>
        </div>

        <div>
          <h4 className="text-lg font-semibold mb-2">Overcoming Limiting Beliefs</h4>
          <p className="text-gray-700 mb-3">
            Identify and transform negative money beliefs:
          </p>
          <ul className="list-disc pl-6 space-y-2 text-gray-700">
            <li>Challenge inherited money beliefs</li>
            <li>Reframe past financial experiences</li>
            <li>Create new empowering money stories</li>
            <li>Practice self-compassion with money</li>
          </ul>
        </div>

        <div className="bg-blue-50 p-4 rounded-lg">
          <h4 className="text-lg font-semibold mb-2">Next Steps</h4>
          <p className="text-gray-700 mb-3">
            Ready to transform your money mindset? Here are your next steps:
          </p>
          <div className="grid gap-4 md:grid-cols-2">
            <Link to="/basic-finance">
              <Button variant="outline" className="w-full">
                <BookOpen className="w-4 h-4 mr-2" />
                Learn Financial Basics
              </Button>
            </Link>
            <Link to="/calculators">
              <Button variant="outline" className="w-full">
                <PiggyBank className="w-4 h-4 mr-2" />
                Try Financial Calculators
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default MindsetTransformationTab;
