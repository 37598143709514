import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";

export const FreelanceRateCalculator = () => {
  const [annualIncome, setAnnualIncome] = useState("");
  const [workingHours, setWorkingHours] = useState("");
  const [expenses, setExpenses] = useState("");
  const [result, setResult] = useState<{ hourly: number; daily: number } | null>(null);
  const { toast } = useToast();

  const calculateRate = () => {
    const targetIncome = parseFloat(annualIncome);
    const hours = parseFloat(workingHours);
    const annualExpenses = parseFloat(expenses);

    if (isNaN(targetIncome) || isNaN(hours) || isNaN(annualExpenses)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    const totalRequired = targetIncome + annualExpenses;
    const hourlyRate = totalRequired / (hours * 52); // 52 weeks in a year
    const dailyRate = hourlyRate * 8; // Assuming 8-hour workday

    setResult({
      hourly: hourlyRate,
      daily: dailyRate
    });
  };

  return (
    <Card className="p-6">
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">Freelance Rate Calculator</h3>
        <p className="text-gray-600">
          Calculate your ideal hourly and daily rates based on your target annual income,
          working hours, and expenses.
        </p>
      </div>

      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="annual-income">Target Annual Income ($)</Label>
          <Input
            id="annual-income"
            type="number"
            placeholder="60000"
            value={annualIncome}
            onChange={(e) => setAnnualIncome(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="working-hours">Weekly Working Hours</Label>
          <Input
            id="working-hours"
            type="number"
            placeholder="40"
            value={workingHours}
            onChange={(e) => setWorkingHours(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="expenses">Annual Business Expenses ($)</Label>
          <Input
            id="expenses"
            type="number"
            placeholder="10000"
            value={expenses}
            onChange={(e) => setExpenses(e.target.value)}
          />
        </div>

        <Button 
          className="w-full"
          onClick={calculateRate}
        >
          Calculate Rates
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg space-y-2">
            <p className="text-center font-semibold">
              Recommended Hourly Rate:
              <span className="block text-2xl text-primary">
                ${result.hourly.toFixed(2)}
              </span>
            </p>
            <p className="text-center font-semibold">
              Recommended Daily Rate:
              <span className="block text-2xl text-primary">
                ${result.daily.toFixed(2)}
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};