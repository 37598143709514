import ContentSection from "@/components/ContentSection";

export const PersonalGrowthSection = () => {
  return (
    <ContentSection title="Personal Growth Investment">
      <p className="mb-4">
        Invest in yourself to increase your earning potential:
      </p>
      <ul className="list-disc pl-6 mb-6">
        <li className="mb-2">Focus on High-Growth Industries</li>
        <li className="mb-2">Health and Longevity Investment</li>
        <li>Personal Brand Development</li>
      </ul>
    </ContentSection>
  );
};