import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";

export const BusinessValuationCalculator = () => {
  const [annualRevenue, setAnnualRevenue] = useState("");
  const [annualProfit, setAnnualProfit] = useState("");
  const [industryMultiple, setIndustryMultiple] = useState("");
  const [result, setResult] = useState<{ revenue: number; profit: number } | null>(null);
  const { toast } = useToast();

  const calculateValuation = () => {
    const revenue = parseFloat(annualRevenue);
    const profit = parseFloat(annualProfit);
    const multiple = parseFloat(industryMultiple);

    if (isNaN(revenue) || isNaN(profit) || isNaN(multiple)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    const revenueValuation = revenue * multiple;
    const profitValuation = profit * multiple;
    setResult({ revenue: revenueValuation, profit: profitValuation });
  };

  return (
    <Card className="p-6">
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">Business Valuation Calculator</h3>
        <p className="text-gray-600">
          Estimate your business value based on revenue, profit, and industry multiples.
        </p>
      </div>

      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="annual-revenue">Annual Revenue ($)</Label>
          <Input
            id="annual-revenue"
            type="number"
            placeholder="1000000"
            value={annualRevenue}
            onChange={(e) => setAnnualRevenue(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="annual-profit">Annual Profit ($)</Label>
          <Input
            id="annual-profit"
            type="number"
            placeholder="200000"
            value={annualProfit}
            onChange={(e) => setAnnualProfit(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="industry-multiple">Industry Multiple</Label>
          <Input
            id="industry-multiple"
            type="number"
            placeholder="3"
            value={industryMultiple}
            onChange={(e) => setIndustryMultiple(e.target.value)}
          />
        </div>

        <Button className="w-full" onClick={calculateValuation}>
          Calculate Valuation
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg space-y-2">
            <p className="text-center text-lg font-semibold">
              Revenue-Based Valuation:
              <span className="block text-xl text-primary">
                ${result.revenue.toLocaleString()}
              </span>
            </p>
            <p className="text-center text-lg font-semibold">
              Profit-Based Valuation:
              <span className="block text-2xl text-primary">
                ${result.profit.toLocaleString()}
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};