import PageLayout from "@/components/PageLayout";
import ContentSection from "@/components/ContentSection";
import { BusinessGuideSection } from "@/components/business/BusinessGuideSection";
import { BusinessCalculatorsSection } from "@/components/business/BusinessCalculatorsSection";

const Business = () => {
  return (
    <PageLayout
      title="Complete Business Startup Guide | Entrepreneurship 101"
      description="Learn how to start and grow a successful business with our comprehensive guide. From planning to execution, master the essentials of entrepreneurship."
    >
      <div className="space-y-12">
        <div className="prose max-w-none mb-8">
          <p className="text-lg text-gray-700 leading-relaxed">
            Starting and growing a successful business requires careful planning, strategic thinking, and 
            effective execution. Whether you're launching your first startup or expanding an existing 
            venture, understanding the fundamentals of business management is crucial. This guide provides 
            you with essential knowledge and practical steps to build a thriving business.
          </p>
        </div>

        <BusinessGuideSection />
        <BusinessCalculatorsSection />
      </div>
    </PageLayout>
  );
};

export default Business;