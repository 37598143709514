import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { DollarSign } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";

export const DebtPayoffCalculator = () => {
  const [debtAmount, setDebtAmount] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [monthlyPayment, setMonthlyPayment] = useState("");
  const [result, setResult] = useState<{ months: number; totalInterest: number } | null>(null);
  const { toast } = useToast();

  const calculatePayoff = () => {
    const principal = parseFloat(debtAmount);
    const rate = parseFloat(interestRate) / 100 / 12; // Monthly rate
    const payment = parseFloat(monthlyPayment);

    if (isNaN(principal) || isNaN(rate) || isNaN(payment)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    let balance = principal;
    let months = 0;
    let totalInterest = 0;

    while (balance > 0 && months < 1200) { // Cap at 100 years
      const interest = balance * rate;
      totalInterest += interest;
      balance = balance + interest - payment;
      months++;
    }

    setResult({ months, totalInterest });
    console.log("Calculated debt payoff:", { months, totalInterest });
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="debt-amount">Total Debt Amount</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="debt-amount"
              type="number"
              placeholder="10000"
              className="pl-10"
              value={debtAmount}
              onChange={(e) => setDebtAmount(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="interest-rate">Annual Interest Rate (%)</Label>
          <Input
            id="interest-rate"
            type="number"
            placeholder="5"
            value={interestRate}
            onChange={(e) => setInterestRate(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="monthly-payment">Monthly Payment</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="monthly-payment"
              type="number"
              placeholder="500"
              className="pl-10"
              value={monthlyPayment}
              onChange={(e) => setMonthlyPayment(e.target.value)}
            />
          </div>
        </div>

        <Button 
          className="w-full"
          onClick={calculatePayoff}
        >
          Calculate Payoff Time
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg">
            <p className="text-center text-lg font-semibold">
              Time to Pay Off:
              <span className="block text-2xl text-primary">
                {Math.floor(result.months / 12)} years and {result.months % 12} months
              </span>
            </p>
            <p className="text-center text-sm text-gray-600 mt-2">
              Total Interest Paid: ${result.totalInterest.toLocaleString(undefined, { maximumFractionDigits: 2 })}
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};