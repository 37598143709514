import PageLayout from "@/components/PageLayout";
import ContentSection from "@/components/ContentSection";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { BudgetingSection } from "@/components/basic-finance/BudgetingSection";
import { EmergencyFundSection } from "@/components/basic-finance/EmergencyFundSection";
import { PersonalGrowthSection } from "@/components/basic-finance/PersonalGrowthSection";
import { DiversificationSection } from "@/components/basic-finance/DiversificationSection";
import { InflationSection } from "@/components/basic-finance/InflationSection";
import { CalculatorsSection } from "@/components/basic-finance/CalculatorsSection";

const BasicFinance = () => {
  return (
    <PageLayout
      title="Essential Basic Finance Guide | Personal Finance 101"
      description="Master the fundamentals of personal finance with our comprehensive guide. Learn budgeting, saving, and investing basics for financial success."
    >
      <div className="space-y-12">
        <div className="prose max-w-none mb-8">
          <p className="text-lg text-gray-700 leading-relaxed">
            Personal finance is the foundation of financial well-being. Understanding how to manage your money, 
            save effectively, and invest wisely can make the difference between financial stress and financial 
            freedom. This guide covers essential concepts from budgeting basics to advanced investment strategies, 
            helping you build a strong financial future.
          </p>
        </div>

        <Tabs defaultValue="fundamentals" className="w-full">
          <TabsList className="grid w-full grid-cols-2 lg:grid-cols-5">
            <TabsTrigger value="fundamentals">Fundamentals</TabsTrigger>
            <TabsTrigger value="emergency-fund">Emergency Fund</TabsTrigger>
            <TabsTrigger value="growth">Personal Growth</TabsTrigger>
            <TabsTrigger value="strategies">Strategies</TabsTrigger>
            <TabsTrigger value="inflation">Inflation</TabsTrigger>
          </TabsList>

          <TabsContent value="fundamentals" className="mt-6">
            <BudgetingSection />
          </TabsContent>

          <TabsContent value="emergency-fund" className="mt-6">
            <EmergencyFundSection />
          </TabsContent>

          <TabsContent value="growth" className="mt-6">
            <PersonalGrowthSection />
          </TabsContent>

          <TabsContent value="strategies" className="mt-6">
            <DiversificationSection />
          </TabsContent>

          <TabsContent value="inflation" className="mt-6">
            <InflationSection />
          </TabsContent>
        </Tabs>

        <CalculatorsSection />
      </div>
    </PageLayout>
  );
};

export default BasicFinance;