import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { AssetInputs } from "./personal-wealth/AssetInputs";
import { LiabilityInputs } from "./personal-wealth/LiabilityInputs";
import { NetWorthDisplay } from "./personal-wealth/NetWorthDisplay";

export const PersonalWealthCalculator = () => {
  const [assets, setAssets] = useState({
    cash: "",
    investments: "",
    realEstate: "",
    vehicles: "",
    otherAssets: ""
  });

  const [liabilities, setLiabilities] = useState({
    mortgage: "",
    carLoans: "",
    studentLoans: "",
    creditCards: "",
    otherDebts: ""
  });

  const [netWorth, setNetWorth] = useState<number | null>(null);
  const { toast } = useToast();

  const handleAssetChange = (key: string, value: string) => {
    setAssets(prev => ({ ...prev, [key]: value }));
  };

  const handleLiabilityChange = (key: string, value: string) => {
    setLiabilities(prev => ({ ...prev, [key]: value }));
  };

  const calculateNetWorth = () => {
    const totalAssets = Object.values(assets).reduce(
      (sum, value) => sum + (parseFloat(value) || 0),
      0
    );

    const totalLiabilities = Object.values(liabilities).reduce(
      (sum, value) => sum + (parseFloat(value) || 0),
      0
    );

    const calculatedNetWorth = totalAssets - totalLiabilities;
    setNetWorth(calculatedNetWorth);
    console.log("Calculated net worth:", { totalAssets, totalLiabilities, calculatedNetWorth });
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <AssetInputs assets={assets} onAssetChange={handleAssetChange} />
          <LiabilityInputs liabilities={liabilities} onLiabilityChange={handleLiabilityChange} />
        </div>

        <Button 
          className="w-full mt-6"
          onClick={calculateNetWorth}
        >
          Calculate Net Worth
        </Button>

        <NetWorthDisplay netWorth={netWorth} />
      </div>
    </Card>
  );
};