const Footer = () => {
  return (
    <footer className="mt-auto py-6 bg-gray-50 border-t">
      <div className="container mx-auto px-4 text-center text-sm text-gray-600">
        <p className="mb-2">
          The information provided on this website is for informational purposes only and does not constitute financial advice.
        </p>
        <p>
          We may receive compensation through affiliate links.
        </p>
      </div>
    </footer>
  );
};

export default Footer;