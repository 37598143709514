import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";

export const EmployeeCostCalculator = () => {
  const [salary, setSalary] = useState("");
  const [benefits, setBenefits] = useState("");
  const [taxes, setTaxes] = useState("");
  const [result, setResult] = useState<number | null>(null);
  const { toast } = useToast();

  const calculateTotalCost = () => {
    const baseSalary = parseFloat(salary);
    const benefitsCost = parseFloat(benefits);
    const taxesCost = parseFloat(taxes);

    if (isNaN(baseSalary) || isNaN(benefitsCost) || isNaN(taxesCost)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    const totalCost = baseSalary + benefitsCost + taxesCost;
    setResult(totalCost);
  };

  return (
    <Card className="p-6">
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">Employee Cost Calculator</h3>
        <p className="text-gray-600">
          Calculate the total cost of hiring an employee, including salary, benefits, and taxes.
        </p>
      </div>

      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="salary">Annual Base Salary ($)</Label>
          <Input
            id="salary"
            type="number"
            placeholder="50000"
            value={salary}
            onChange={(e) => setSalary(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="benefits">Annual Benefits Cost ($)</Label>
          <Input
            id="benefits"
            type="number"
            placeholder="10000"
            value={benefits}
            onChange={(e) => setBenefits(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="taxes">Annual Payroll Taxes ($)</Label>
          <Input
            id="taxes"
            type="number"
            placeholder="5000"
            value={taxes}
            onChange={(e) => setTaxes(e.target.value)}
          />
        </div>

        <Button className="w-full" onClick={calculateTotalCost}>
          Calculate Total Cost
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg">
            <p className="text-center text-lg font-semibold">
              Total Annual Employee Cost:
              <span className="block text-2xl text-primary">
                ${result.toLocaleString()}
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};