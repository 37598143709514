import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";

export const AdSpendRoiCalculator = () => {
  const [adSpend, setAdSpend] = useState("");
  const [revenue, setRevenue] = useState("");
  const [result, setResult] = useState<number | null>(null);
  const { toast } = useToast();

  const calculateROI = () => {
    const spend = parseFloat(adSpend);
    const rev = parseFloat(revenue);

    if (isNaN(spend) || isNaN(rev)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    const roi = ((rev - spend) / spend) * 100;
    setResult(roi);
  };

  return (
    <Card className="p-6">
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">Ad Spend ROI Calculator</h3>
        <p className="text-gray-600">
          Calculate the return on investment for your advertising campaigns.
        </p>
      </div>

      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="ad-spend">Total Ad Spend ($)</Label>
          <Input
            id="ad-spend"
            type="number"
            placeholder="1000"
            value={adSpend}
            onChange={(e) => setAdSpend(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="revenue">Revenue Generated ($)</Label>
          <Input
            id="revenue"
            type="number"
            placeholder="2000"
            value={revenue}
            onChange={(e) => setRevenue(e.target.value)}
          />
        </div>

        <Button className="w-full" onClick={calculateROI}>
          Calculate ROI
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg">
            <p className="text-center text-lg font-semibold">
              Ad Spend ROI:
              <span className="block text-2xl text-primary">
                {result.toFixed(2)}%
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};