import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { DollarSign } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";

export const RetailPricingCalculator = () => {
  const [productCost, setProductCost] = useState("");
  const [desiredMargin, setDesiredMargin] = useState("");
  const [overheadCost, setOverheadCost] = useState("");
  const [result, setResult] = useState<{
    retailPrice: number;
    profit: number;
    marginPercentage: number;
  } | null>(null);
  const { toast } = useToast();

  const calculatePrice = () => {
    const cost = parseFloat(productCost);
    const margin = parseFloat(desiredMargin) / 100;
    const overhead = parseFloat(overheadCost);

    if (isNaN(cost) || isNaN(margin) || isNaN(overhead)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    const totalCost = cost + overhead;
    const retailPrice = totalCost / (1 - margin);
    const profit = retailPrice - totalCost;
    const marginPercentage = (profit / retailPrice) * 100;

    setResult({ retailPrice, profit, marginPercentage });
    console.log("Calculated retail price:", { retailPrice, profit, marginPercentage });
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="cost">Product Cost</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="cost"
              type="number"
              placeholder="10"
              className="pl-10"
              value={productCost}
              onChange={(e) => setProductCost(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="margin">Desired Profit Margin (%)</Label>
          <Input
            id="margin"
            type="number"
            placeholder="50"
            value={desiredMargin}
            onChange={(e) => setDesiredMargin(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="overhead">Overhead Cost Per Unit</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="overhead"
              type="number"
              placeholder="2"
              className="pl-10"
              value={overheadCost}
              onChange={(e) => setOverheadCost(e.target.value)}
            />
          </div>
        </div>

        <Button 
          className="w-full"
          onClick={calculatePrice}
        >
          Calculate Retail Price
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg space-y-2">
            <p className="text-center">
              <span className="font-semibold">Suggested Retail Price:</span>
              <span className="block text-xl text-primary">
                ${result.retailPrice.toFixed(2)}
              </span>
            </p>
            <p className="text-center">
              <span className="font-semibold">Profit Per Unit:</span>
              <span className="block text-xl text-primary">
                ${result.profit.toFixed(2)}
              </span>
            </p>
            <p className="text-center">
              <span className="font-semibold">Actual Margin:</span>
              <span className="block text-xl text-primary">
                {result.marginPercentage.toFixed(1)}%
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};