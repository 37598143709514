import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { CalculatorProps } from "./types";

export const PositionSizingCalculator = ({ onCalculate }: CalculatorProps) => {
  const [accountSize, setAccountSize] = useState("");
  const [riskPercentage, setRiskPercentage] = useState("");
  const [entryPrice, setEntryPrice] = useState("");
  const [stopLoss, setStopLoss] = useState("");
  const [positionSize, setPositionSize] = useState<number | null>(null);

  const calculatePositionSize = () => {
    const account = parseFloat(accountSize);
    const risk = parseFloat(riskPercentage) / 100;
    const entry = parseFloat(entryPrice);
    const stop = parseFloat(stopLoss);

    if (account && risk && entry && stop) {
      const riskAmount = account * risk;
      const riskPerShare = Math.abs(entry - stop);
      const shares = riskAmount / riskPerShare;
      const position = shares * entry;
      setPositionSize(position);
      onCalculate?.(position);
    }
  };

  return (
    <div className="space-y-4">
      <div className="grid gap-4">
        <div>
          <Label htmlFor="accountSize">Account Size ($)</Label>
          <Input
            id="accountSize"
            type="number"
            value={accountSize}
            onChange={(e) => setAccountSize(e.target.value)}
            placeholder="Enter account size"
          />
        </div>
        <div>
          <Label htmlFor="riskPercentage">Risk Percentage (%)</Label>
          <Input
            id="riskPercentage"
            type="number"
            value={riskPercentage}
            onChange={(e) => setRiskPercentage(e.target.value)}
            placeholder="Enter risk percentage"
          />
        </div>
        <div>
          <Label htmlFor="entryPrice">Entry Price ($)</Label>
          <Input
            id="entryPrice"
            type="number"
            value={entryPrice}
            onChange={(e) => setEntryPrice(e.target.value)}
            placeholder="Enter entry price"
          />
        </div>
        <div>
          <Label htmlFor="stopLoss">Stop Loss ($)</Label>
          <Input
            id="stopLoss"
            type="number"
            value={stopLoss}
            onChange={(e) => setStopLoss(e.target.value)}
            placeholder="Enter stop loss"
          />
        </div>
      </div>
      <Button onClick={calculatePositionSize} className="w-full">
        Calculate Position Size
      </Button>
      {positionSize !== null && (
        <div className="mt-4 p-4 bg-accent/10 rounded-lg">
          <p className="text-lg font-semibold">
            Recommended Position Size: ${positionSize.toFixed(2)}
          </p>
        </div>
      )}
    </div>
  );
};