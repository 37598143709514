import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Card } from "@/components/ui/card";
import { 
  Lightbulb, Target, DollarSign, Users, 
  Building, ShoppingCart, Briefcase, PenTool
} from "lucide-react";

export const BusinessGuideSection = () => {
  return (
    <section>
      <h2 className="text-2xl font-semibold mb-6">Business Startup Guide</h2>
      <Tabs defaultValue="ideas" className="w-full">
        <TabsList className="grid w-full grid-cols-2 lg:grid-cols-4">
          <TabsTrigger value="ideas" className="flex items-center gap-2">
            <Lightbulb className="w-4 h-4" />
            Business Ideas
          </TabsTrigger>
          <TabsTrigger value="planning" className="flex items-center gap-2">
            <Target className="w-4 h-4" />
            Planning
          </TabsTrigger>
          <TabsTrigger value="finance" className="flex items-center gap-2">
            <DollarSign className="w-4 h-4" />
            Finance
          </TabsTrigger>
          <TabsTrigger value="marketing" className="flex items-center gap-2">
            <Users className="w-4 h-4" />
            Marketing
          </TabsTrigger>
        </TabsList>

        <TabsContent value="ideas" className="mt-6">
          <Card className="p-6">
            <h3 className="text-xl font-semibold mb-4">Popular Business Ideas for Beginners</h3>
            <div className="grid gap-6 md:grid-cols-2">
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <ShoppingCart className="w-5 h-5 text-primary" />
                  <h4 className="font-medium">E-commerce Store</h4>
                </div>
                <p className="text-gray-600 text-sm">
                  Start an online store selling niche products or dropshipping popular items.
                </p>
              </div>
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <PenTool className="w-5 h-5 text-primary" />
                  <h4 className="font-medium">Freelancing</h4>
                </div>
                <p className="text-gray-600 text-sm">
                  Offer your skills in writing, design, development, or consulting.
                </p>
              </div>
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <Building className="w-5 h-5 text-primary" />
                  <h4 className="font-medium">Local Services</h4>
                </div>
                <p className="text-gray-600 text-sm">
                  Provide services like cleaning, landscaping, or home maintenance.
                </p>
              </div>
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <Briefcase className="w-5 h-5 text-primary" />
                  <h4 className="font-medium">Consulting</h4>
                </div>
                <p className="text-gray-600 text-sm">
                  Share your expertise in business, marketing, or technology.
                </p>
              </div>
            </div>
          </Card>
        </TabsContent>

        <TabsContent value="planning" className="mt-6">
          <Card className="p-6">
            <h3 className="text-xl font-semibold mb-4">Business Planning Essentials</h3>
            <div className="space-y-4">
              <div>
                <h4 className="font-medium text-lg mb-2">1. Market Research</h4>
                <p className="text-gray-600">
                  Understand your target market, competitors, and industry trends.
                </p>
              </div>
              <div>
                <h4 className="font-medium text-lg mb-2">2. Business Plan</h4>
                <p className="text-gray-600">
                  Create a detailed plan covering your business model, strategy, and financials.
                </p>
              </div>
              <div>
                <h4 className="font-medium text-lg mb-2">3. Legal Requirements</h4>
                <p className="text-gray-600">
                  Register your business, obtain licenses, and understand regulations.
                </p>
              </div>
            </div>
          </Card>
        </TabsContent>

        <TabsContent value="finance" className="mt-6">
          <Card className="p-6">
            <h3 className="text-xl font-semibold mb-4">Financial Management</h3>
            <div className="space-y-4">
              <div>
                <h4 className="font-medium text-lg mb-2">1. Startup Costs</h4>
                <p className="text-gray-600">
                  Calculate initial expenses including equipment, inventory, and marketing.
                </p>
              </div>
              <div>
                <h4 className="font-medium text-lg mb-2">2. Funding Options</h4>
                <p className="text-gray-600">
                  Explore financing through savings, loans, investors, or crowdfunding.
                </p>
              </div>
              <div>
                <h4 className="font-medium text-lg mb-2">3. Financial Planning</h4>
                <p className="text-gray-600">
                  Set up accounting systems and create financial projections.
                </p>
              </div>
            </div>
          </Card>
        </TabsContent>

        <TabsContent value="marketing" className="mt-6">
          <Card className="p-6">
            <h3 className="text-xl font-semibold mb-4">Marketing Strategies</h3>
            <div className="space-y-4">
              <div>
                <h4 className="font-medium text-lg mb-2">1. Online Presence</h4>
                <p className="text-gray-600">
                  Build a website and establish social media profiles.
                </p>
              </div>
              <div>
                <h4 className="font-medium text-lg mb-2">2. Content Marketing</h4>
                <p className="text-gray-600">
                  Create valuable content to attract and engage customers.
                </p>
              </div>
              <div>
                <h4 className="font-medium text-lg mb-2">3. Customer Acquisition</h4>
                <p className="text-gray-600">
                  Develop strategies to attract and retain customers.
                </p>
              </div>
            </div>
          </Card>
        </TabsContent>
      </Tabs>
    </section>
  );
};