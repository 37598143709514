import ContentSection from "@/components/ContentSection";

export const CryptocurrencySection = () => {
  return (
    <ContentSection title="Cryptocurrency Investment Guide">
      <div className="space-y-8">
        <div>
          <h3 className="text-xl font-semibold mb-4">What is Cryptocurrency?</h3>
          <p className="mb-4">
            Cryptocurrency is a digital or virtual form of currency that uses cryptography for security, 
            operating independently of a central bank. Bitcoin, created in 2009, was the first cryptocurrency, 
            but thousands of alternatives (called altcoins) now exist.
          </p>
        </div>

        <div>
          <h3 className="text-xl font-semibold mb-4">Understanding Blockchain Technology</h3>
          <p className="mb-4">
            Blockchain is the underlying technology that powers cryptocurrencies. It's a decentralized, 
            distributed ledger that records all transactions across a network of computers. Key features include:
          </p>
          <ul className="list-disc pl-5 space-y-2 mb-4">
            <li>Decentralization: No single entity controls the network</li>
            <li>Transparency: All transactions are public and traceable</li>
            <li>Immutability: Once recorded, transactions cannot be altered</li>
            <li>Security: Cryptographic principles ensure transaction safety</li>
          </ul>
        </div>

        <div>
          <h3 className="text-xl font-semibold mb-4">Pros and Cons of Cryptocurrency Investment</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <h4 className="font-semibold text-green-600 mb-2">Advantages</h4>
              <ul className="list-disc pl-5 space-y-2">
                <li>High potential returns</li>
                <li>24/7 trading market</li>
                <li>Portfolio diversification</li>
                <li>Protection against inflation</li>
                <li>Growing institutional adoption</li>
              </ul>
            </div>
            <div>
              <h4 className="font-semibold text-red-600 mb-2">Disadvantages</h4>
              <ul className="list-disc pl-5 space-y-2">
                <li>High volatility</li>
                <li>Regulatory uncertainty</li>
                <li>Security risks (hacking, scams)</li>
                <li>Environmental concerns (mining)</li>
                <li>Limited real-world use cases</li>
              </ul>
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-xl font-semibold mb-4">Getting Started with Cryptocurrency</h3>
          <ol className="list-decimal pl-5 space-y-4">
            <li>
              <strong>Choose a Cryptocurrency Exchange</strong>
              <p className="mt-2">
                Select a reputable exchange like Coinbase, Binance, or Kraken. Consider factors like:
                security, fees, available cryptocurrencies, and user interface.
              </p>
            </li>
            <li>
              <strong>Set Up Your Account</strong>
              <p className="mt-2">
                Complete the verification process (KYC) and set up strong security measures 
                (2FA, strong password).
              </p>
            </li>
            <li>
              <strong>Choose a Wallet</strong>
              <p className="mt-2">
                Consider both hot wallets (online) and cold wallets (hardware) for storing your 
                cryptocurrency securely.
              </p>
            </li>
            <li>
              <strong>Start Small</strong>
              <p className="mt-2">
                Begin with a small investment in established cryptocurrencies like Bitcoin or 
                Ethereum. Learn the market before expanding to other coins.
              </p>
            </li>
            <li>
              <strong>Educate Yourself</strong>
              <p className="mt-2">
                Stay informed about blockchain technology, market trends, and security best 
                practices. Follow reputable news sources and join community discussions.
              </p>
            </li>
          </ol>
        </div>

        <div className="bg-blue-50 p-4 rounded-lg mt-6">
          <h4 className="font-semibold text-blue-800 mb-2">Important Safety Tips</h4>
          <ul className="list-disc pl-5 space-y-2 text-blue-900">
            <li>Never invest more than you can afford to lose</li>
            <li>Use strong passwords and enable two-factor authentication</li>
            <li>Research thoroughly before investing in any cryptocurrency</li>
            <li>Be wary of scams and fraudulent investment schemes</li>
            <li>Keep your private keys and recovery phrases secure</li>
          </ul>
        </div>
      </div>
    </ContentSection>
  );
};