import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { DollarSign } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";

export const DividendYieldCalculator = () => {
  const [stockPrice, setStockPrice] = useState("");
  const [annualDividend, setAnnualDividend] = useState("");
  const [result, setResult] = useState<number | null>(null);
  const { toast } = useToast();

  const calculateDividendYield = () => {
    const price = parseFloat(stockPrice);
    const dividend = parseFloat(annualDividend);

    if (isNaN(price) || isNaN(dividend)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    if (price <= 0) {
      toast({
        title: "Invalid Stock Price",
        description: "Stock price must be greater than zero",
        variant: "destructive",
      });
      return;
    }

    const dividendYield = (dividend / price) * 100;
    setResult(dividendYield);
    console.log("Calculated dividend yield:", dividendYield);
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="stock-price">Current Stock Price</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="stock-price"
              type="number"
              placeholder="100"
              className="pl-10"
              value={stockPrice}
              onChange={(e) => setStockPrice(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="annual-dividend">Annual Dividend Per Share</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="annual-dividend"
              type="number"
              placeholder="2.50"
              className="pl-10"
              value={annualDividend}
              onChange={(e) => setAnnualDividend(e.target.value)}
            />
          </div>
        </div>

        <Button 
          className="w-full"
          onClick={calculateDividendYield}
        >
          Calculate Dividend Yield
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg">
            <p className="text-center text-lg font-semibold">
              Dividend Yield:
              <span className="block text-2xl text-primary">
                {result.toFixed(2)}%
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};