import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { DollarSign } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";

export const StockSplitCalculator = () => {
  const [currentShares, setCurrentShares] = useState("");
  const [currentPrice, setCurrentPrice] = useState("");
  const [splitRatio, setSplitRatio] = useState("");
  const [result, setResult] = useState<{ shares: number; price: number } | null>(null);
  const { toast } = useToast();

  const calculateStockSplit = () => {
    const shares = parseFloat(currentShares);
    const price = parseFloat(currentPrice);
    const ratio = parseFloat(splitRatio);

    if (isNaN(shares) || isNaN(price) || isNaN(ratio)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    const newShares = shares * ratio;
    const newPrice = price / ratio;

    setResult({ shares: newShares, price: newPrice });
    console.log("Calculated stock split result:", { shares: newShares, price: newPrice });
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="current-shares">Current Number of Shares</Label>
          <Input
            id="current-shares"
            type="number"
            placeholder="100"
            value={currentShares}
            onChange={(e) => setCurrentShares(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="current-price">Current Stock Price</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="current-price"
              type="number"
              placeholder="50"
              className="pl-10"
              value={currentPrice}
              onChange={(e) => setCurrentPrice(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="split-ratio">Split Ratio (e.g., 2 for 2:1 split)</Label>
          <Input
            id="split-ratio"
            type="number"
            placeholder="2"
            value={splitRatio}
            onChange={(e) => setSplitRatio(e.target.value)}
          />
        </div>

        <Button 
          className="w-full"
          onClick={calculateStockSplit}
        >
          Calculate Split Result
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg">
            <p className="text-center text-lg font-semibold">
              Post-Split Results:
              <span className="block text-xl text-primary mt-2">
                New Shares: {result.shares.toLocaleString()}
              </span>
              <span className="block text-xl text-primary">
                New Price: ${result.price.toFixed(2)}
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};