import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { DollarSign, Target } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";

export const SavingsGoalCalculator = () => {
  const [currentSavings, setCurrentSavings] = useState("");
  const [monthlyContribution, setMonthlyContribution] = useState("");
  const [goalAmount, setGoalAmount] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [result, setResult] = useState<{ months: number; finalAmount: number } | null>(null);
  const { toast } = useToast();

  const calculateTimeToGoal = () => {
    const principal = parseFloat(currentSavings);
    const monthly = parseFloat(monthlyContribution);
    const goal = parseFloat(goalAmount);
    const rate = parseFloat(interestRate) / 100 / 12; // Monthly rate

    if (isNaN(principal) || isNaN(monthly) || isNaN(goal) || isNaN(rate)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    let balance = principal;
    let months = 0;

    while (balance < goal && months < 1200) { // Cap at 100 years
      balance = (balance + monthly) * (1 + rate);
      months++;
    }

    setResult({ months, finalAmount: balance });
    console.log("Calculated time to savings goal:", { months, finalAmount: balance });
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="current-savings">Current Savings</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="current-savings"
              type="number"
              placeholder="10000"
              className="pl-10"
              value={currentSavings}
              onChange={(e) => setCurrentSavings(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="monthly-contribution">Monthly Contribution</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="monthly-contribution"
              type="number"
              placeholder="1000"
              className="pl-10"
              value={monthlyContribution}
              onChange={(e) => setMonthlyContribution(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="goal-amount">Target Savings Goal</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="goal-amount"
              type="number"
              placeholder="100000"
              className="pl-10"
              value={goalAmount}
              onChange={(e) => setGoalAmount(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="interest-rate">Expected Annual Return (%)</Label>
          <Input
            id="interest-rate"
            type="number"
            placeholder="7"
            value={interestRate}
            onChange={(e) => setInterestRate(e.target.value)}
          />
        </div>

        <Button 
          className="w-full"
          onClick={calculateTimeToGoal}
        >
          Calculate Time to Goal
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg">
            <p className="text-center text-lg font-semibold">
              Time to Reach Goal:
              <span className="block text-2xl text-primary">
                {Math.floor(result.months / 12)} years and {result.months % 12} months
              </span>
            </p>
            <p className="text-center text-sm text-gray-600 mt-2">
              Final Amount: ${result.finalAmount.toLocaleString(undefined, { maximumFractionDigits: 2 })}
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};