import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";

export const StartupCostsCalculator = () => {
  const [equipment, setEquipment] = useState("");
  const [marketing, setMarketing] = useState("");
  const [operational, setOperational] = useState("");
  const [result, setResult] = useState<number | null>(null);
  const { toast } = useToast();

  const calculateStartupCosts = () => {
    const equipmentCost = parseFloat(equipment);
    const marketingCost = parseFloat(marketing);
    const operationalCost = parseFloat(operational);

    if (isNaN(equipmentCost) || isNaN(marketingCost) || isNaN(operationalCost)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    const totalCosts = equipmentCost + marketingCost + operationalCost;
    setResult(totalCosts);
    console.log("Calculated startup costs:", totalCosts);
  };

  return (
    <Card className="p-6">
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">Startup Costs Calculator</h3>
        <p className="text-gray-600">
          Estimate the total cost of starting your business by calculating equipment,
          marketing, and operational expenses.
        </p>
      </div>

      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="equipment">Equipment Costs ($)</Label>
          <Input
            id="equipment"
            type="number"
            placeholder="5000"
            value={equipment}
            onChange={(e) => setEquipment(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="marketing">Marketing Costs ($)</Label>
          <Input
            id="marketing"
            type="number"
            placeholder="2000"
            value={marketing}
            onChange={(e) => setMarketing(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="operational">Operational Costs ($)</Label>
          <Input
            id="operational"
            type="number"
            placeholder="3000"
            value={operational}
            onChange={(e) => setOperational(e.target.value)}
          />
        </div>

        <Button 
          className="w-full"
          onClick={calculateStartupCosts}
        >
          Calculate Total Startup Costs
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg">
            <p className="text-center text-lg font-semibold">
              Total Startup Costs:
              <span className="block text-2xl text-primary">
                ${result.toLocaleString()}
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};