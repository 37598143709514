import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { DollarSign } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";

export const EpsCalculator = () => {
  const [netIncome, setNetIncome] = useState("");
  const [outstandingShares, setOutstandingShares] = useState("");
  const [result, setResult] = useState<number | null>(null);
  const { toast } = useToast();

  const calculateEPS = () => {
    const income = parseFloat(netIncome);
    const shares = parseFloat(outstandingShares);

    if (isNaN(income) || isNaN(shares)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    if (shares <= 0) {
      toast({
        title: "Invalid Shares",
        description: "Number of shares must be greater than zero",
        variant: "destructive",
      });
      return;
    }

    const eps = income / shares;
    setResult(eps);
    console.log("Calculated EPS:", eps);
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="net-income">Net Income</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="net-income"
              type="number"
              placeholder="1000000"
              className="pl-10"
              value={netIncome}
              onChange={(e) => setNetIncome(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="outstanding-shares">Outstanding Shares</Label>
          <Input
            id="outstanding-shares"
            type="number"
            placeholder="1000000"
            value={outstandingShares}
            onChange={(e) => setOutstandingShares(e.target.value)}
          />
        </div>

        <Button 
          className="w-full"
          onClick={calculateEPS}
        >
          Calculate EPS
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg">
            <p className="text-center text-lg font-semibold">
              Earnings Per Share:
              <span className="block text-2xl text-primary">
                ${result.toFixed(2)}
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};