import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import Navigation from "@/components/Navigation";
import RecommendedBooks from "@/components/RecommendedBooks";
import PageHeader from "@/components/PageHeader";
import { getRecommendedBooks } from "@/utils/bookRecommendations";
import { useScrollToTop } from "@/hooks/use-scroll-to-top";

interface PageLayoutProps {
  title: string;
  description: string;
  children: React.ReactNode;
}

const PageLayout = ({ title, description, children }: PageLayoutProps) => {
  useScrollToTop();
  
  const path = window.location.pathname;
  const recommendedBooks = getRecommendedBooks(path);

  return (
    <div className="min-h-screen bg-gradient-to-br from-white to-gray-50">
      <Navigation />
      
      <main className="container mx-auto px-4 py-12">
        <div className="max-w-4xl mx-auto">
          <PageHeader title={title} description={description} />

          <div className="prose max-w-none mb-12">
            {children}
          </div>

          <RecommendedBooks books={recommendedBooks} />

          <div className="mt-12 text-center">
            <Link to="/">
              <Button variant="outline">Back to Home</Button>
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
};

export default PageLayout;