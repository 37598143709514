import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { DollarSign } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";

export const MortgageCalculator = () => {
  const [homePrice, setHomePrice] = useState("");
  const [downPayment, setDownPayment] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [loanTerm, setLoanTerm] = useState("");
  const [monthlyPayment, setMonthlyPayment] = useState<number | null>(null);
  const { toast } = useToast();

  const calculateMortgage = () => {
    const price = parseFloat(homePrice);
    const down = parseFloat(downPayment);
    const rate = parseFloat(interestRate) / 100 / 12; // Monthly interest rate
    const term = parseFloat(loanTerm) * 12; // Months

    if (isNaN(price) || isNaN(down) || isNaN(rate) || isNaN(term)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    const principal = price - down;
    const payment = principal * (rate * Math.pow(1 + rate, term)) / (Math.pow(1 + rate, term) - 1);
    
    setMonthlyPayment(payment);
    console.log("Calculated monthly mortgage payment:", payment);
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="home-price">Home Price</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="home-price"
              type="number"
              placeholder="300000"
              className="pl-10"
              value={homePrice}
              onChange={(e) => setHomePrice(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="down-payment">Down Payment</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="down-payment"
              type="number"
              placeholder="60000"
              className="pl-10"
              value={downPayment}
              onChange={(e) => setDownPayment(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="interest-rate">Interest Rate (%)</Label>
          <Input
            id="interest-rate"
            type="number"
            placeholder="4.5"
            value={interestRate}
            onChange={(e) => setInterestRate(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="loan-term">Loan Term (Years)</Label>
          <Input
            id="loan-term"
            type="number"
            placeholder="30"
            value={loanTerm}
            onChange={(e) => setLoanTerm(e.target.value)}
          />
        </div>

        <Button 
          className="w-full"
          onClick={calculateMortgage}
        >
          Calculate Monthly Payment
        </Button>

        {monthlyPayment !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg">
            <p className="text-center text-lg font-semibold">
              Monthly Payment:
              <span className="block text-2xl text-primary">
                ${monthlyPayment.toLocaleString(undefined, { maximumFractionDigits: 2 })}
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};