import { format } from 'date-fns';
import { BookOpen, Calendar, Clock, Tag } from 'lucide-react';
import { Card } from "@/components/ui/card";
import { BlogPost } from '@/types/blog';
import { Link } from 'react-router-dom';

interface BlogCardProps {
  post: BlogPost;
}

const BlogCard = ({ post }: BlogCardProps) => {
  console.log('BlogCard rendering for post:', post.headline);
  console.log('Post slug:', post.slug); // Added log to debug slug

  if (!post.slug) {
    console.error('Post is missing slug:', post);
    return null;
  }

  return (
    <Link to={`/blog/${post.slug}`} className="block">
      <Card className="overflow-hidden hover:shadow-lg transition-shadow duration-300">
        {post.image && (
          <div className="relative h-48 overflow-hidden">
            <img
              src={post.image}
              alt={post.headline}
              className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
            />
          </div>
        )}
        <div className="p-6">
          <div className="flex items-center gap-2 text-sm text-gray-500 mb-3">
            {post.category && (
              <span className="bg-primary/10 text-primary px-2 py-1 rounded-full text-xs">
                {post.category.title}
              </span>
            )}
            {post.readingTime && (
              <span className="flex items-center gap-1">
                <Clock className="w-4 h-4" />
                {post.readingTime} min read
              </span>
            )}
          </div>
          <h2 className="text-xl font-semibold mb-3 line-clamp-2 hover:text-primary transition-colors">
            {post.headline}
          </h2>
          <p className="text-gray-600 mb-4 line-clamp-3">
            {post.metaDescription}
          </p>
          <div className="flex items-center justify-between text-sm text-gray-500">
            <div className="flex items-center gap-2">
              <Calendar className="w-4 h-4" />
              {format(new Date(post.publishedAt), 'MMM d, yyyy')}
            </div>
            <div className="flex items-center gap-2">
              <BookOpen className="w-4 h-4" />
              Read More
            </div>
          </div>
          {post.tags && post.tags.length > 0 && (
            <div className="mt-4 flex flex-wrap gap-2">
              {post.tags.map((tag) => (
                <span
                  key={tag.id}
                  className="flex items-center gap-1 text-xs text-gray-500 bg-gray-100 px-2 py-1 rounded-full"
                >
                  <Tag className="w-3 h-3" />
                  {tag.title}
                </span>
              ))}
            </div>
          )}
        </div>
      </Card>
    </Link>
  );
};

export default BlogCard;