import { useState } from "react";
import { useIsMobile } from "@/hooks/use-mobile";
import { Card } from "@/components/ui/card";
import { CalculatorContent } from "./calculators/CalculatorContent";
import { calculatorConfigs } from "./calculators/calculator-config";

export const StockMarketCalculators = () => {
  const [activeCalculator, setActiveCalculator] = useState(calculatorConfigs[0].id);
  const isMobile = useIsMobile();

  return (
    <section className="mt-8 bg-gray-50/50 p-4 md:p-6 rounded-lg">
      <Card className="p-4 md:p-6">
        <div className="flex flex-col space-y-8">
          <div className="w-full overflow-y-auto rounded-lg border bg-background p-4">
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-2">
              {calculatorConfigs.map((calc) => {
                const Icon = calc.icon;
                return (
                  <button
                    key={calc.id}
                    onClick={() => setActiveCalculator(calc.id)}
                    className={`flex items-center gap-2 p-2 rounded-md transition-all text-sm
                      ${
                        activeCalculator === calc.id
                          ? "bg-primary text-primary-foreground"
                          : "hover:bg-accent hover:text-accent-foreground"
                      }
                    `}
                  >
                    <Icon className="h-4 w-4" />
                    <span className="truncate">{calc.label}</span>
                  </button>
                );
              })}
            </div>
          </div>
          <div className="w-full pt-6 border-t">
            <CalculatorContent
              calculators={calculatorConfigs}
              activeCalculator={activeCalculator}
              isMobile={isMobile}
            />
          </div>
        </div>
      </Card>
    </section>
  );
};