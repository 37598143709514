import ContentSection from "@/components/ContentSection";

export const MarketAnalysisSection = () => {
  return (
    <ContentSection title="Market Analysis">
      <h3 className="text-xl font-semibold mb-4">Analysis Methods</h3>
      <div className="space-y-6">
        <div>
          <h4 className="text-lg font-semibold mb-2">Fundamental Analysis</h4>
          <p className="mb-3">
            Evaluate a company's intrinsic value through:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Financial statement analysis (Balance Sheet, Income Statement, Cash Flow)</li>
            <li>Industry analysis and competitive position</li>
            <li>Management quality and corporate governance</li>
            <li>Economic conditions and market trends</li>
          </ul>
        </div>

        <div>
          <h4 className="text-lg font-semibold mb-2">Technical Analysis</h4>
          <p className="mb-3">
            Study price patterns and market trends using:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Chart patterns and price trends</li>
            <li>Volume indicators and momentum</li>
            <li>Moving averages and technical indicators</li>
            <li>Support and resistance levels</li>
          </ul>
        </div>

        <div>
          <h4 className="text-lg font-semibold mb-2">Risk Management</h4>
          <p className="mb-3">
            Protect your portfolio through:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Diversification across sectors and asset classes</li>
            <li>Position sizing and portfolio rebalancing</li>
            <li>Stop-loss orders and risk limits</li>
            <li>Regular portfolio review and adjustment</li>
          </ul>
        </div>
      </div>
    </ContentSection>
  );
};