import PageLayout from "@/components/PageLayout";
import { MortgageCalculator } from "@/components/MortgageCalculator";
import { RentalCalculator } from "@/components/RentalCalculator";
import { PropertyRoiCalculator } from "@/components/PropertyRoiCalculator";
import ContentSection from "@/components/ContentSection";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

const Housing = () => {
  return (
    <PageLayout
      title="Housing & Real Estate Investment Guide"
      description="Master real estate investing with our comprehensive guide. Learn about property investment strategies, financing options, and market analysis."
    >
      <div className="space-y-12">
        <div className="prose max-w-none mb-8">
          <p className="text-lg text-gray-700 leading-relaxed">
            Real estate remains one of the most reliable paths to building long-term wealth. Whether you're 
            buying your first home or building an investment portfolio, understanding the real estate market 
            and making informed decisions is crucial. This guide covers everything from home ownership basics 
            to advanced investment strategies, helping you navigate the property market with confidence.
          </p>
        </div>

        <Tabs defaultValue="basics" className="w-full">
          <TabsList className="grid w-full grid-cols-2 lg:grid-cols-4">
            <TabsTrigger value="basics">Homebuying Basics</TabsTrigger>
            <TabsTrigger value="investment">Investment Strategy</TabsTrigger>
            <TabsTrigger value="financing">Financing Options</TabsTrigger>
            <TabsTrigger value="management">Property Management</TabsTrigger>
          </TabsList>

          <TabsContent value="basics" className="mt-6">
            <ContentSection title="Essential Home Buying Guide">
              <h3 className="text-xl font-semibold mb-4">Getting Started with Home Ownership</h3>
              <div className="space-y-6">
                <div>
                  <h4 className="text-lg font-semibold mb-2">1. Prepare Your Finances</h4>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>Check and improve your credit score (aim for 700+)</li>
                    <li>Save for a down payment (typically 20% for best terms)</li>
                    <li>Calculate your debt-to-income ratio</li>
                    <li>Get pre-approved for a mortgage</li>
                  </ul>
                </div>

                <div>
                  <h4 className="text-lg font-semibold mb-2">2. Research the Market</h4>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>Study neighborhood trends and property values</li>
                    <li>Consider school districts and amenities</li>
                    <li>Research future development plans</li>
                    <li>Analyze price trends and market conditions</li>
                  </ul>
                </div>

                <div>
                  <h4 className="text-lg font-semibold mb-2">3. Property Inspection</h4>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>Hire a qualified home inspector</li>
                    <li>Check for structural issues and repairs needed</li>
                    <li>Verify property boundaries and restrictions</li>
                    <li>Review home inspection report thoroughly</li>
                  </ul>
                </div>
              </div>
            </ContentSection>
          </TabsContent>

          <TabsContent value="investment" className="mt-6">
            <ContentSection title="Real Estate Investment Strategies">
              <h3 className="text-xl font-semibold mb-4">Building Your Property Portfolio</h3>
              <div className="space-y-6">
                <div>
                  <h4 className="text-lg font-semibold mb-2">1. Rental Properties</h4>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>Analyze potential rental income and expenses</li>
                    <li>Consider property management options</li>
                    <li>Understand landlord responsibilities</li>
                    <li>Calculate expected ROI and cash flow</li>
                  </ul>
                </div>

                <div>
                  <h4 className="text-lg font-semibold mb-2">2. Fix and Flip</h4>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>Identify undervalued properties</li>
                    <li>Estimate renovation costs accurately</li>
                    <li>Build a reliable contractor network</li>
                    <li>Understand local market demand</li>
                  </ul>
                </div>

                <div>
                  <h4 className="text-lg font-semibold mb-2">3. REITs and Crowdfunding</h4>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>Invest in real estate investment trusts</li>
                    <li>Explore real estate crowdfunding platforms</li>
                    <li>Diversify across property types</li>
                    <li>Understand tax implications</li>
                  </ul>
                </div>
              </div>
            </ContentSection>
          </TabsContent>

          <TabsContent value="financing" className="mt-6">
            <ContentSection title="Financing Options">
              <h3 className="text-xl font-semibold mb-4">Understanding Mortgage Types</h3>
              <div className="space-y-6">
                <div>
                  <h4 className="text-lg font-semibold mb-2">1. Conventional Loans</h4>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>Fixed-rate vs. adjustable-rate mortgages</li>
                    <li>Conforming vs. jumbo loans</li>
                    <li>Private mortgage insurance requirements</li>
                    <li>Down payment options and requirements</li>
                  </ul>
                </div>

                <div>
                  <h4 className="text-lg font-semibold mb-2">2. Government-Backed Loans</h4>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>FHA loans for first-time buyers</li>
                    <li>VA loans for veterans</li>
                    <li>USDA loans for rural properties</li>
                    <li>Special programs and requirements</li>
                  </ul>
                </div>
              </div>
            </ContentSection>
          </TabsContent>

          <TabsContent value="management" className="mt-6">
            <ContentSection title="Property Management">
              <h3 className="text-xl font-semibold mb-4">Effective Property Management</h3>
              <div className="space-y-6">
                <div>
                  <h4 className="text-lg font-semibold mb-2">1. Maintenance and Repairs</h4>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>Create a maintenance schedule</li>
                    <li>Build an emergency repair fund</li>
                    <li>Develop contractor relationships</li>
                    <li>Implement preventive maintenance</li>
                  </ul>
                </div>

                <div>
                  <h4 className="text-lg font-semibold mb-2">2. Tenant Management</h4>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>Screen tenants effectively</li>
                    <li>Handle lease agreements properly</li>
                    <li>Address maintenance requests promptly</li>
                    <li>Maintain professional relationships</li>
                  </ul>
                </div>
              </div>
            </ContentSection>
          </TabsContent>
        </Tabs>

        <section>
          <h2 className="text-2xl font-semibold mb-6">Real Estate Calculators</h2>
          <Tabs defaultValue="mortgage" className="w-full">
            <TabsList className="grid w-full grid-cols-2 lg:grid-cols-3">
              <TabsTrigger value="mortgage">Mortgage</TabsTrigger>
              <TabsTrigger value="rental">Rental Property</TabsTrigger>
              <TabsTrigger value="roi">Property ROI</TabsTrigger>
            </TabsList>
            
            <TabsContent value="mortgage" className="mt-6">
              <div>
                <h3 className="text-xl font-semibold mb-3">Mortgage Calculator</h3>
                <p className="text-gray-600 mb-4">
                  Calculate your monthly mortgage payments and see how different down payments and interest rates affect your costs.
                </p>
                <MortgageCalculator />
              </div>
            </TabsContent>

            <TabsContent value="rental" className="mt-6">
              <div>
                <h3 className="text-xl font-semibold mb-3">Rental Property Calculator</h3>
                <p className="text-gray-600 mb-4">
                  Analyze potential rental properties by calculating monthly cash flow and return on investment.
                </p>
                <RentalCalculator />
              </div>
            </TabsContent>

            <TabsContent value="roi" className="mt-6">
              <div>
                <h3 className="text-xl font-semibold mb-3">Property ROI Calculator</h3>
                <p className="text-gray-600 mb-4">
                  Calculate the potential return on investment for your real estate investments.
                </p>
                <PropertyRoiCalculator />
              </div>
            </TabsContent>
          </Tabs>
        </section>
      </div>
    </PageLayout>
  );
};

export default Housing;