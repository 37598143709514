import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { DollarSign } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";

export const PropertyRoiCalculator = () => {
  const [purchasePrice, setPurchasePrice] = useState("");
  const [downPayment, setDownPayment] = useState("");
  const [monthlyRent, setMonthlyRent] = useState("");
  const [monthlyExpenses, setMonthlyExpenses] = useState("");
  const [result, setResult] = useState<{
    cashOnCashReturn: number;
    monthlyProfit: number;
    annualProfit: number;
  } | null>(null);
  const { toast } = useToast();

  const calculateRoi = () => {
    const price = parseFloat(purchasePrice);
    const down = parseFloat(downPayment);
    const rent = parseFloat(monthlyRent);
    const expenses = parseFloat(monthlyExpenses);

    if (isNaN(price) || isNaN(down) || isNaN(rent) || isNaN(expenses)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    const monthlyProfit = rent - expenses;
    const annualProfit = monthlyProfit * 12;
    const cashOnCashReturn = (annualProfit / down) * 100;

    setResult({
      cashOnCashReturn,
      monthlyProfit,
      annualProfit
    });

    console.log("Calculated property ROI:", { cashOnCashReturn, monthlyProfit, annualProfit });
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="purchase-price">Purchase Price</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="purchase-price"
              type="number"
              placeholder="300000"
              className="pl-10"
              value={purchasePrice}
              onChange={(e) => setPurchasePrice(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="down-payment">Down Payment</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="down-payment"
              type="number"
              placeholder="60000"
              className="pl-10"
              value={downPayment}
              onChange={(e) => setDownPayment(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="monthly-rent">Monthly Rental Income</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="monthly-rent"
              type="number"
              placeholder="2500"
              className="pl-10"
              value={monthlyRent}
              onChange={(e) => setMonthlyRent(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="monthly-expenses">Monthly Expenses</Label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
            <Input
              id="monthly-expenses"
              type="number"
              placeholder="1500"
              className="pl-10"
              value={monthlyExpenses}
              onChange={(e) => setMonthlyExpenses(e.target.value)}
            />
          </div>
        </div>

        <Button 
          className="w-full"
          onClick={calculateRoi}
        >
          Calculate ROI
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg space-y-2">
            <p className="text-center">
              <span className="block text-lg font-semibold">Cash on Cash Return:</span>
              <span className="block text-2xl text-primary">
                {result.cashOnCashReturn.toFixed(2)}%
              </span>
            </p>
            <p className="text-center">
              <span className="block text-lg font-semibold">Monthly Profit:</span>
              <span className="block text-2xl text-primary">
                ${result.monthlyProfit.toLocaleString()}
              </span>
            </p>
            <p className="text-center">
              <span className="block text-lg font-semibold">Annual Profit:</span>
              <span className="block text-2xl text-primary">
                ${result.annualProfit.toLocaleString()}
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};