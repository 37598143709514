import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";

export const PaybackPeriodCalculator = () => {
  const [initialInvestment, setInitialInvestment] = useState("");
  const [annualCashFlow, setAnnualCashFlow] = useState("");
  const [result, setResult] = useState<number | null>(null);

  const calculatePaybackPeriod = () => {
    const investment = parseFloat(initialInvestment);
    const cashFlow = parseFloat(annualCashFlow);
    
    if (cashFlow <= 0) {
      console.error("Annual cash flow must be positive");
      return;
    }

    const paybackPeriod = investment / cashFlow;
    setResult(paybackPeriod);
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium mb-1">
            Initial Investment ($)
          </label>
          <Input
            type="number"
            value={initialInvestment}
            onChange={(e) => setInitialInvestment(e.target.value)}
            placeholder="Enter initial investment"
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">
            Annual Cash Flow ($)
          </label>
          <Input
            type="number"
            value={annualCashFlow}
            onChange={(e) => setAnnualCashFlow(e.target.value)}
            placeholder="Enter annual cash flow"
          />
        </div>
        <Button onClick={calculatePaybackPeriod} className="w-full">
          Calculate Payback Period
        </Button>
        {result !== null && (
          <div className="mt-4 p-4 bg-muted rounded-lg">
            <p className="text-sm font-medium">Payback Period:</p>
            <p className="text-2xl font-bold">{result.toFixed(2)} years</p>
          </div>
        )}
      </div>
    </Card>
  );
};