import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";

export const CashFlowCalculator = () => {
  const [revenue, setRevenue] = useState("");
  const [expenses, setExpenses] = useState("");
  const [investments, setInvestments] = useState("");
  const [result, setResult] = useState<number | null>(null);
  const { toast } = useToast();

  const calculateCashFlow = () => {
    const totalRevenue = parseFloat(revenue);
    const totalExpenses = parseFloat(expenses);
    const totalInvestments = parseFloat(investments);

    if (isNaN(totalRevenue) || isNaN(totalExpenses) || isNaN(totalInvestments)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    const netCashFlow = totalRevenue - totalExpenses + totalInvestments;
    setResult(netCashFlow);
    console.log("Calculated cash flow:", netCashFlow);
  };

  return (
    <Card className="p-6">
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">Cash Flow Calculator</h3>
        <p className="text-gray-600">
          Project your cash inflows and outflows to ensure your business maintains healthy liquidity.
        </p>
      </div>

      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="revenue">Monthly Revenue ($)</Label>
          <Input
            id="revenue"
            type="number"
            placeholder="10000"
            value={revenue}
            onChange={(e) => setRevenue(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="expenses">Monthly Expenses ($)</Label>
          <Input
            id="expenses"
            type="number"
            placeholder="7000"
            value={expenses}
            onChange={(e) => setExpenses(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="investments">Investments/Other Income ($)</Label>
          <Input
            id="investments"
            type="number"
            placeholder="1000"
            value={investments}
            onChange={(e) => setInvestments(e.target.value)}
          />
        </div>

        <Button 
          className="w-full"
          onClick={calculateCashFlow}
        >
          Calculate Net Cash Flow
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg">
            <p className="text-center text-lg font-semibold">
              Net Monthly Cash Flow:
              <span className={`block text-2xl ${result >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                ${result.toLocaleString()}
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};