import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { CalculatorProps } from "./types";

export const StockProfitCalculator = ({ onCalculate }: CalculatorProps) => {
  const [purchasePrice, setPurchasePrice] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [shares, setShares] = useState("");
  const [result, setResult] = useState<number | null>(null);

  const calculateProfit = () => {
    const purchase = parseFloat(purchasePrice);
    const sale = parseFloat(salePrice);
    const quantity = parseFloat(shares);

    if (!isNaN(purchase) && !isNaN(sale) && !isNaN(quantity)) {
      const profit = (sale - purchase) * quantity;
      setResult(profit);
      if (onCalculate) onCalculate(profit);
    }
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="purchasePrice">Purchase Price Per Share ($)</Label>
          <Input
            id="purchasePrice"
            type="number"
            min="0"
            step="0.01"
            value={purchasePrice}
            onChange={(e) => setPurchasePrice(e.target.value)}
            placeholder="Enter purchase price"
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="salePrice">Sale Price Per Share ($)</Label>
          <Input
            id="salePrice"
            type="number"
            min="0"
            step="0.01"
            value={salePrice}
            onChange={(e) => setSalePrice(e.target.value)}
            placeholder="Enter sale price"
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="shares">Number of Shares</Label>
          <Input
            id="shares"
            type="number"
            min="0"
            value={shares}
            onChange={(e) => setShares(e.target.value)}
            placeholder="Enter number of shares"
          />
        </div>

        <Button onClick={calculateProfit} className="w-full">
          Calculate Profit/Loss
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-muted rounded-lg">
            <p className="text-center font-semibold">
              {result >= 0 ? "Profit" : "Loss"}: ${Math.abs(result).toFixed(2)}
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};