
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { ArrowRight } from "lucide-react";

const WealthPsychologyTab = () => {
  return (
    <Card className="p-6">
      <h3 className="text-xl font-bold mb-4">Psychology of Wealth</h3>
      <div className="space-y-6">
        <div>
          <h4 className="text-lg font-semibold mb-2">Wealth Building Mindset</h4>
          <p className="text-gray-700 mb-3">
            Understand the psychology of wealth creation:
          </p>
          <ul className="list-disc pl-6 space-y-2 text-gray-700">
            <li>Long-term thinking and delayed gratification</li>
            <li>Risk tolerance and decision-making</li>
            <li>Building wealth through contribution</li>
            <li>Creating multiple income streams</li>
          </ul>
        </div>

        <div>
          <h4 className="text-lg font-semibold mb-2">Wealth Habits</h4>
          <p className="text-gray-700 mb-3">
            Develop habits that build lasting wealth:
          </p>
          <ul className="list-disc pl-6 space-y-2 text-gray-700">
            <li>Regular investment practices</li>
            <li>Continuous financial education</li>
            <li>Strategic networking</li>
            <li>Asset diversification</li>
          </ul>
        </div>

        <div className="bg-yellow-50 p-4 rounded-lg">
          <h4 className="text-lg font-semibold mb-2">Explore Further</h4>
          <p className="text-gray-700 mb-3">
            Deepen your understanding of wealth building:
          </p>
          <div className="grid gap-4">
            <Link to="/business">
              <Button className="w-full">
                Explore Business Opportunities
                <ArrowRight className="w-4 h-4 ml-2" />
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default WealthPsychologyTab;
