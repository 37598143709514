import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";

export const WaccCalculator = () => {
  const [equityCost, setEquityCost] = useState("");
  const [debtCost, setDebtCost] = useState("");
  const [equityWeight, setEquityWeight] = useState("");
  const [debtWeight, setDebtWeight] = useState("");
  const [taxRate, setTaxRate] = useState("");
  const [result, setResult] = useState<number | null>(null);

  const calculateWACC = () => {
    const ke = parseFloat(equityCost) / 100;
    const kd = parseFloat(debtCost) / 100;
    const we = parseFloat(equityWeight) / 100;
    const wd = parseFloat(debtWeight) / 100;
    const t = parseFloat(taxRate) / 100;

    const wacc = (ke * we) + (kd * (1 - t) * wd);
    setResult(wacc * 100);
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium mb-1">
            Cost of Equity (%)
          </label>
          <Input
            type="number"
            value={equityCost}
            onChange={(e) => setEquityCost(e.target.value)}
            placeholder="Enter cost of equity"
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">
            Cost of Debt (%)
          </label>
          <Input
            type="number"
            value={debtCost}
            onChange={(e) => setDebtCost(e.target.value)}
            placeholder="Enter cost of debt"
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">
            Equity Weight (%)
          </label>
          <Input
            type="number"
            value={equityWeight}
            onChange={(e) => setEquityWeight(e.target.value)}
            placeholder="Enter equity weight"
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">
            Debt Weight (%)
          </label>
          <Input
            type="number"
            value={debtWeight}
            onChange={(e) => setDebtWeight(e.target.value)}
            placeholder="Enter debt weight"
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">
            Tax Rate (%)
          </label>
          <Input
            type="number"
            value={taxRate}
            onChange={(e) => setTaxRate(e.target.value)}
            placeholder="Enter tax rate"
          />
        </div>
        <Button onClick={calculateWACC} className="w-full">
          Calculate WACC
        </Button>
        {result !== null && (
          <div className="mt-4 p-4 bg-muted rounded-lg">
            <p className="text-sm font-medium">WACC:</p>
            <p className="text-2xl font-bold">{result.toFixed(2)}%</p>
          </div>
        )}
      </div>
    </Card>
  );
};