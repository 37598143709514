import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";

export const CustomerLifetimeValueCalculator = () => {
  const [averagePurchase, setAveragePurchase] = useState("");
  const [purchaseFrequency, setPurchaseFrequency] = useState("");
  const [customerLifespan, setCustomerLifespan] = useState("");
  const [result, setResult] = useState<{ annual: number; lifetime: number } | null>(null);
  const { toast } = useToast();

  const calculateCLV = () => {
    const avgPurchase = parseFloat(averagePurchase);
    const frequency = parseFloat(purchaseFrequency);
    const lifespan = parseFloat(customerLifespan);

    if (isNaN(avgPurchase) || isNaN(frequency) || isNaN(lifespan)) {
      toast({
        title: "Invalid Input",
        description: "Please enter valid numbers",
        variant: "destructive",
      });
      return;
    }

    const annualValue = avgPurchase * frequency;
    const lifetimeValue = annualValue * lifespan;

    setResult({
      annual: annualValue,
      lifetime: lifetimeValue
    });
  };

  return (
    <Card className="p-6">
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">Customer Lifetime Value Calculator</h3>
        <p className="text-gray-600">
          Calculate the total value a customer brings to your business over their entire
          relationship with your company.
        </p>
      </div>

      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="average-purchase">Average Purchase Value ($)</Label>
          <Input
            id="average-purchase"
            type="number"
            placeholder="100"
            value={averagePurchase}
            onChange={(e) => setAveragePurchase(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="purchase-frequency">Annual Purchase Frequency</Label>
          <Input
            id="purchase-frequency"
            type="number"
            placeholder="4"
            value={purchaseFrequency}
            onChange={(e) => setPurchaseFrequency(e.target.value)}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="customer-lifespan">Customer Lifespan (Years)</Label>
          <Input
            id="customer-lifespan"
            type="number"
            placeholder="3"
            value={customerLifespan}
            onChange={(e) => setCustomerLifespan(e.target.value)}
          />
        </div>

        <Button 
          className="w-full"
          onClick={calculateCLV}
        >
          Calculate Customer Value
        </Button>

        {result !== null && (
          <div className="mt-4 p-4 bg-accent/10 rounded-lg space-y-2">
            <p className="text-center font-semibold">
              Annual Customer Value:
              <span className="block text-2xl text-primary">
                ${result.annual.toFixed(2)}
              </span>
            </p>
            <p className="text-center font-semibold">
              Customer Lifetime Value:
              <span className="block text-2xl text-primary">
                ${result.lifetime.toFixed(2)}
              </span>
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};