import ContentSection from "@/components/ContentSection";

export const EmergencyFundSection = () => {
  return (
    <ContentSection title="Emergency Fund Strategy">
      <p className="mb-4">
        Build financial security with a robust emergency fund:
      </p>
      <ul className="list-disc pl-6 mb-6">
        <li className="mb-2">Aim for 3-6 months of living expenses</li>
        <li className="mb-2">Keep funds easily accessible in a high-yield savings account</li>
        <li>Regularly contribute to build and maintain your safety net</li>
      </ul>
    </ContentSection>
  );
};